@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.virtual-tour-modal
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(0,0,0,0.5)
  z-index: 1000000
  text-align: center
  padding: 30px

  &__iframe
    @include square(100%)
    border: 10px solid white
    border-radius: 6px
    background-color: grey

    iframe
      @include square(100%)
