@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.top-bar
  .row
    display: flex
    justify-content: space-between
  &__logo-wrapper
    @media only screen and (max-width: $screen-lg-md)
      flex-grow: 2

  &__menu-scroll-wrapper
    display: flex
    flex-grow: 2
    @media only screen and (max-width: $screen-lg-md)
      @include boxSize(0, calc(100vh - 50px))
      position: fixed
      top: 50px
      left: 0
      right: 0
      overflow-y: auto
      transform: translateX(100%)
      transition: transform .2s linear, width 0s linear .5s
      min-width: 0
    &--mobile-opened
      @media only screen and (max-width: $screen-lg-md)
        transition: width 0s linear, transform .2s linear
        transform: translateX(0)
        width: 100%
        min-width: 200px
        & a
          svg, i
            margin-right: 15px

  &__menu-wrapper
    display: flex
    flex-grow: 2
    @media only screen and (max-width: $screen-lg-md)
      width: 100%
      flex-direction: column
      z-index: 5
      border-top: 1px solid $border-form-input
      border-bottom: 1px solid $border-form-input
      &> div
        flex-direction: column
    &--mobile-opened
      @media only screen and (max-width: $screen-lg-md)
        transform: translateX(0)
        & a
          svg, i
            margin-right: 15px

  &__logo
    @include boxSize(170px, 50px)
    margin-right: 26px
    padding: 2px 0

  &__link
    @include displayFlex(center)
    @include font(13px, $default, 13px)
    cursor: pointer
    padding: 0 8px
    text-transform: uppercase
    &:hover
      text-decoration: none
      color: $bg-charcoal
    svg
      fill: $inactive
    span
      font-size: 13px
    i
      color: $inactive
    @media only screen and (max-width: $screen-lg-md)
      min-height: 50px
      padding: 0 15px
      border-top: 1px solid $border-form-input
      background-color: $white
    &.active
      @media only screen and (max-width: $screen-lg-md)
        background-color: $green
        color: $white
        font-weight: bold
        &:hover
          color: $white
        svg
          fill: $white
        i
          color: $white
      @media only screen and (min-width: $screen-lg-md)
        font-weight: bold
    &.collapsing
      & > span
        font-size: 0
        transition: 0.1s font-size linear
        @media only screen and (max-width: $screen-lg-md)
          font-size: 13px
      &> svg
        fill: $green
      &> i
        color: $green
    &.account
      &> svg
        fill: $green
      &> i
        color: $green

  &__menu
    &--left
      display: block
      @media only screen and (min-width: $screen-lg-md)
        display: flex
        flex-grow: 2
      a svg, a i
        @media only screen and (min-width: $screen-lg-md)
          display: none
      @media only screen and (max-width: $screen-lg-md)
        a:hover
          background-color: $green
          color: $white
          span
            font-size: 13px
          svg
            fill: $white
          i
            color: $white

    &--right
      display: flex
      a:hover
        background-color: $green
        color: $white
        span
          font-size: 13px
        svg
          fill: $white
        i
          color: $white
      &> a, &> span
        border-left: 1px solid $border-form-input
        transition: 0.1s all linear
        padding: 15px
        font-size: 13px
        svg
          fill: $green
        i
          color: $green
        @media only screen and (max-width: $screen-lg-md)
          border-left: none
          svg
            fill: $border-form-input
          i
            color: $border-form-input
        &:last-child
          border-right: 1px solid $border-form-input
          @media only screen and (max-width: $screen-lg-md)
            border-right: none
        &> span
          margin-left: 5px
          @media only screen and (max-width: $screen-lg-md)
            margin-left: 0
        @media only screen and (max-width: $screen-s-xs)
          &:hover
            background-color: $green
            color: $white
            span
              font-size: 13px
            svg
              fill: $white
            i
              color: $white
      @media only screen and (max-width: $screen-s-xs)
        & a span
          display: inline

  &__icon-btn
    cursor: pointer
    padding: 14px
    position: static
    @media only screen and (max-width: $screen-lg-md)
      border-left: 1px solid $border-form-input
    @media only screen and (min-width: $screen-lg-md)
      border-right: 1px solid $border-form-input
    svg, i
      vertical-align: bottom
    &:after
      @include positionAbsolute(false, 0, -1px, 0)
      content: ''
      background-color: $white
      width: 100%
      z-index: 1031
    &--opened
      position: relative
      &:after
        content: ' '
        height: 10px

  &__menu-btn
    cursor: pointer
    padding: 14px
    border-left: 1px solid $border-form-input
    border-right: 1px solid $border-form-input
    &> svg, i
      vertical-align: bottom
    @media only screen and (min-width: $screen-lg-md)
      display: none


