// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.favorites-empty-page
  position: relative
  padding-bottom: 64px

  &__text-content
    p
      margin-bottom: 32px
      margin-top: 8px
    @media (min-width: $tablet)
      margin-bottom: 64px

  &__image-wrapper
    margin-bottom: 64px
    img
      position: relative
      width: 100%
      @media (min-width: $desktop)
        max-width: 238px
        float: right
    @media (min-width: $desktop-md)
      position: absolute !important
      top: 0
      right: 0
