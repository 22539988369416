// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.advertisement
  height: 100%
  border-radius: 4px
  overflow: hidden

  .grid-item__wrapper
    position: relative

  .grid-item-image
    @include fullWidthSize(207px)
    @include displayFlex(false, center, column)
    color: $white
    background: url('../../../../assets/images/design/add-icon.svg') no-repeat bottom left $green
    background-size: 140px 140px
    text-align: center
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    @media (min-width: $tablet)
      height: 200px
    @media (min-width: $desktop-md)
      height: 189px
    @media (min-width: $desktop-md)
      height: 233px
    .adver-title
      font-size: 24px
      line-height: 32px
      margin-bottom: 0
      margin-top: 0
    .bottom-adver
      font-weight: bold

  .adver-content-title
    position: relative
    padding: 16px 0 8px
    font-weight: bold
    margin: 0
    color: $black

  .grid-item__description-wrapper
    @include displayFlex(false, space-between, column)
    height: 251px
    padding: 0 16px 18px
    @media (min-width: $tablet)
      height: 306px
    span
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif

  .adver-description
    @media (min-width: $desktop-md) and (max-width: $screen-md-max)
      display: none

  .adver-description-md
    display: none
    @media (min-width: $desktop-md) and (max-width: $screen-md-max)
      display: block

  .grid-item__info-button
    @include fontSLH(14px, 21px)
    background: $green
    position: relative
    text-transform: none
    padding: 15px 50px
    bottom: -3px
    min-height: 36px
    &:hover
      background: $green-hover
    &:after
      display: none
    svg
      fill: $white
      position: absolute
      right: 32px
      margin-right: 0
