@import '../../../src/stylesheets/variables'


.atom-loader
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

  &__wrapper
    width: 64px
    height: 64px
    border-radius: 50%
    perspective: 800px

  &__inner
    position: absolute
    box-sizing: border-box
    width: 100%
    height: 100%
    border-radius: 50%
    &.one
      left: 0%
      top: 0%
      animation: rotate-one 1s linear infinite
      border-bottom: 3px solid $green
    &.two
      right: 0%
      top: 0%
      animation: rotate-two 1s linear infinite
      border-right: 3px solid $green
    &.three
      right: 0%
      bottom: 0%
      animation: rotate-three 1s linear infinite
      border-top: 3px solid $green

  @keyframes rotate-one
    0%
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg)
    100%
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg)

  @keyframes rotate-two
    0%
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg)
    100%
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg)

  @keyframes rotate-three
    0%
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg)
    100%
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)
