// MOBILE-FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.main-map
  @include square(100%)
  position: relative
  z-index: 2000
  top: 0
  left: 0
  @media (min-width: $desktop-md)
    position: sticky !important
    height: calc(100vh - 72px) !important
    top: 74px
    z-index: 1
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px

  *
    outline: none !important

  .spinner-wrapper
    position: absolute
    top: 20px
    left: 50%

  &__close-button
    @include positionAbsolute(10px, $left: 5px)
    @include font(16px, $white, false)
    padding: 8px 10px 8px 38px
    background: $black
    border: 0
    border-radius: 8px
    cursor: pointer
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    @media (min-width: $desktop-md)
      display: none

    svg
      @include positionAbsolute(8px, $left: 7px)
      fill: $white
      display: block
      line-height: 38px

    &:focus
      outline: none

  &__close-button-cross
    @include font(16px, $black, false)
    position: absolute
    display: block
    right: 60px
    height: 40px
    width: 40px
    background: #000
    top: 10px
    padding: 8px
    border: 0
    border-radius: 2px
    cursor: pointer
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    @media (min-width: $desktop-md)
      display: none

    svg
      @include positionAbsolute(8px, $left: 7px)
      fill: $white
      display: block
      line-height: 38px

    &:focus
      outline: none

  &__search-display-button
    position: absolute
    background: #fff
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: 6px
    height: 39px
    padding: 9px 12px
    cursor: pointer
    top: 10px
    left: 130px
    svg
      @include positionAbsolute(11px, $left: 12px)
      fill: $white
      position: absolute
    &:before
      @include square(16px)
      content: ""
      border: 1px solid $borders
      border-radius: 2px
      position: relative
      display: inline-block
      margin-right: 8px
      background: $white
    &--selected:before
      border: 0
      background: $green
    &:hover
      p
        color: #539533
        text-decoration: underline
    @media (min-width: $desktop-md)
      left: 16px
      top: 16px
    @media (max-width: $mobile)
      left: 5px
      top: 60px
    p
      margin-bottom: 0
      font-size: 16px
      line-height: 24px
      font-family: MuseoSans, Helvetica, Arial, sans-serif

  .gm-style .gm-style-iw
    overflow: visible

  .gm-style-iw.gm-style-iw-c
    box-shadow: none
    background: transparent
    max-width: unset !important
    margin: 0
    padding: 0 !important
    border-radius: 4px
    button
      display: none !important

  .gm-style-iw-d
    overflow: unset !important

  .gm-style-iw a>.marker-info-window__location-kind
    overflow: hidden !important

  .gm-style .gm-style-iw-t::after,
  .gm-style-iw-t::before
    display: none

  .gm-style .gm-style-iw-tc::after
    display: none

  .cluster
    img
      display: none
    div
      @include font(18px !important, $white !important, 20px !important, 600 !important)
      @include displayFlex(center, center)
      text-shadow: 0px 7px 2px rgba(0, 0, 0, 0.00), 0px 4px 2px rgba(0, 0, 0, 0.01), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.06)
      position: relative !important
      font-family: inherit !important
      background-color: $orange
      border-radius: 100%
      height: 100%
      border: 4px solid white
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.55)
      &:before
        @include positionAbsolute(-4px, -4px)
        @include square(calc(100% + 8px))
        content: ''
        //filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0px 11px 5px rgba(0, 0, 0, 0.02)) drop-shadow(0px 18px 5px rgba(0, 0, 0, 0.00))
        border: 1px solid rgba(0, 0, 0, 0.20)
        border-radius: 100px
    &.active, &:hover
      div
        background-color: #8f4f04
        color: white !important
