@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.global-footer
  color: $bg-charcoal

  &__links
    margin: 0
    background: $white
    padding-top: 30px
    padding-bottom: 50px

  &__links-row
    margin-bottom: 4px
    font-weight: 100
    h3
      margin: 1em 0 1.5em
      font-size: 21px
    ul
      list-style: none
      margin: 0 0 10px 0
      background-image: linear-gradient(to right, $green 33%, rgba(255,255,255,0) 0%)
      background-position: top
      background-size: 3px 1px
      background-repeat: repeat-x
      padding: 0
    li a
      @include font(false, $bg-charcoal, 22px, 500)
      display: block
      height: auto
      background-image: linear-gradient(to right, $green 33%, rgba(255,255,255,0) 0%)
      background-position: bottom
      background-size: 3px 1px
      background-repeat: repeat-x
      position: relative
      padding: 16px 25px 15px 0
      &:after
        @include square(25px)
        line-height: 26px
        margin-top: -12px
        text-align: center
        background: $green
        border-radius: 100%

  &__bottom
    margin: -30px auto 30px
    padding: 0 15px
    @media (min-width: $screen-sm)
      width: 750px
    @media (min-width: 992px)
      width: 970px
    @media (min-width: $screen-lg)
      width: 1170px
    a
      color: $link-color
      &:hover
        color: $green

  &__bottom-wrapper
    width: 100%

  &__bottom-logo
    img[data-sizes="auto"]
      max-width: 150px

  &__contacts-wrapper
    width: 100%
    float: left
    position: relative
    min-height: 1px
    padding-right: 0
    padding-left: 30px
    @media (min-width: $screen-sm)
      width: 25%
    @media (min-width: 992px)
      width: 16.6666666667%

  &__contacts
    margin-bottom: 15px
    line-height: 20px
    font-style: initial
    @media (max-width: $screen-xs-max)
      margin-left: -15px
    p
      padding: 0
      margin: 0
    a
      color: $bg-charcoal
      &:hover
        text-decoration: underline
        color: $bg-charcoal

  &__bottom-sites
    width: 100%
    float: left
    position: relative
    padding: 0
    -webkit-column-count: 5
    -moz-column-count: 5
    column-count: 5
    -moz-column-fill: balance
    column-fill: balance
    list-style-type: none
    @media (max-width: $screen-sm-max)
      -webkit-column-count: 4
      -moz-column-count: 4
      column-count: 4
      padding-left: 15px
      padding-right: 15px
    @media (max-width: $screen-sm-max)
      -webkit-column-count: 3
      -moz-column-count: 3
      column-count: 3
    @media (min-width: $screen-xs)
      -webkit-column-count: 4
      -moz-column-count: 4
      column-count: 4
    @media (min-width: $screen-sm)
      width: 75%
    @media (min-width: $screen-md)
      width: 50%

  &__social-links
    width: 100%
    float: right
    padding: 15px 15px 0 0
    @media (min-width: $screen-xs)
      padding: 0 15px 0 0
    @media (min-width: $screen-sm)
      width: 40%
    @media (min-width: $screen-md)
      width: 16.6666666667%

  &__ssl-wrapper
    float: left
    padding-left: 15px
    @media (min-width: $screen-sm)
      padding-left: 10px
    @media (min-width: $screen-md)
      padding-left: 0
      float: right
    a
      display: block
      @media (max-width: $screen-sm-max)
        text-align: right
      img[data-sizes="auto"]
        padding-bottom: 15px
        max-width: 130px

  &__social-buttons-wrapper
    float: right
    padding-top: 10px
    @media (min-width: $screen-xs)
      margin: 12px 0 12px 10px
      padding-top: 0
    @media (min-width: $screen-md)
      margin: 0

  &__social-buttons-list
    list-style-type: none
    overflow: auto
    padding-left: 0 !important
    box-sizing: border-box
    margin-top: 0
    margin-bottom: 10px

  &__social-link
    display: block
    float: left
    margin-right: 5px
    a
      height: 30px
      min-width: 30px
      display: block
    &--facebook
      background: url("../../src/images/icons/facebook.svg")

    &--twitter
      background: url("../../src/images/icons/twitter.svg")

    &--linkedin
      background: url("../../src/images/icons/linkedin.svg")

    &:last-child
      margin-right: 0

  &__copyright-block
    @include fullWidthSize(54px)
    @include displayFlex(flex-end)
    float: right
    padding-left: 15px
    @media (min-width: $screen-xs)
      padding-left: 0
    @media (min-width: $screen-sm)
      @include boxSize(30%, 68px)
    @media (min-width: $screen-md)
      @include boxSize(16.6666666667%, 120px)
    @media (min-width: $screen-lg)
      padding-left: 30px

  &__copyright-wrapper
    padding: 0 15px 30px 0
    justify-content: flex-end
    width: 100%
    display: flex
    a:hover
      color: $link-color-hover
    @media (min-width: $screen-md)
      padding: 0 0 30px 0

  &__copyright--help
    padding-right: 10px
    border-right: 1px solid $link-color

  &__copyright--copyright
    padding-left: 10px
