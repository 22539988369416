@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.unsupported-browser
  @include displayFlex(center, space-between)
  background-color: $bg-location-search
  font-size: 12px
  font-family: inherit
  padding: 8px 0
  @media screen and (max-width: $screen-xs)
    padding: 8px 15px
  &__content
    @include displayFlex(center, space-between)
    position: relative
    @media screen and (max-width: $screen-xs)
      align-items: flex-start
    p
      margin: 0
      line-height: 17px
  &__message
    width: 90%
  &__title
    font-weight: bold
  &__close-btn
    @include positionAbsolute(0, 0)
    cursor: pointer
    margin-left: 10px
    &:after
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='26px' viewBox='0 0 24 24' width='26px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z'/%3E%3C/svg%3E")
