@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.filter-toggle
  height: 100%
  padding: 0 16px
  cursor: pointer
  &:hover
    .dropdown-trigger
      color: $pale-green
      svg
        fill: $pale-green

  &__select-indicator
    margin-left: 5px

  &__icon-wrapper, &__select-indicator
    display: none
    @media (min-width: $desktop-md)
      display: block

  .dropdown-selector__placeholder
    justify-content: flex-start
