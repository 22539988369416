// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.testimonial
  height: 100%
  @media (min-width: $desktop-md)
    height: unset

  &__wrapper
    padding: 16px
    height: 100%
    border: 1px solid #E0E2E2
    box-shadow: 0px 0px 14px 0px rgba(222, 231, 231, 0.20)
    border-radius: 6px
    @media (min-width: $tablet)
      padding: 32px


  &__author-info
    @include displayFlex(center)
    margin-bottom: 20px
    font-size: 14px

  p
    margin-bottom: 0

  &__author-name
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 8px

  &__img
    border: 1px solid $bg-badge-rented-out
    border-radius: 50%
    margin-right: 16px
    object-fit: cover

  &__logo
    margin-bottom: 20px
    height: 32px
    width: auto
