// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'
@import '../../../src/stylesheets/new_container'


.contacts
  margin-top: 64px
  border-top: 1px solid $border-light-grey
  margin-bottom: 64px
  @media (max-width: $screen-lg-md-max)
    &.container
      width: 100% !important
      padding: 0
  @media (min-width: $tablet)
    border-top: none
    margin-bottom: 32px

  &__wrapper
    display: flex
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row-reverse

  &__CEO-block
    padding: 32px 16px
    border-top: 1px solid $border-light-grey
    border-bottom: 1px solid $border-light-grey
    @media (min-width: $tablet)
      width: 50%
      padding: 32px 24px
    @media (min-width: $from-tablet)
      padding: 32px calc((100vw - 736px) / 2) 24px 32px
    @media (min-width: $desktop-md)
      padding: 56px 32px
      border-radius: 0 4px 4px 0
      border-right: 1px solid $border-light-grey

  &__CEO-block-top
    @include displayFlex(flex-start, flex-start)
    margin-bottom: 24px

  &__CEO-img-wrapper
    @include square(72px)
    border-radius: 50%
    overflow: hidden
    display: inline-block
    img
      width: 244%
      margin-left: -45px
      margin-top: -10px

  &__CEO-headline
    margin-left: 15px

  &__CEO-title
    @include fontSLH(18px, 26px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 8px

  &__CEO-subtitle
    p
      margin-bottom: 0
    a
      @include font(16px, $green-hover, 24px)
      font-family: MuseoSans, sans-serif
      text-decoration: underline
      &:hover
        color: $green-hover
        text-decoration: underline

  &__CEO-body
    p
      margin-bottom: 16px

  &__info-block
    padding: 32px 16px
    background-color: $border-light-grey
    @media (min-width: $tablet)
      width: 50%
      padding: 32px 24px
    @media (min-width: $from-tablet)
      padding: 32px 24px 32px calc((100vw - 736px) / 2)
    @media (min-width: $desktop-md)
      padding: 56px 32px
      border-radius: 4px 0 0 4px

  &__info-title
    @include fontSLH(16px, 26px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 32px
    @media (min-width: $desktop-md)
      font-size: 18px

  &__info-title-hours
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 8px

  &__info-links
    margin-bottom: 32px
    @media (min-width: $tablet)
      margin-bottom: 40px

  &__info-phone, &__info-email
    @include displayFlex(center)
    img
      margin-right: 13px
    a
      @include font(21px, $default, 32px)
      font-family: MuseoSans, sans-serif
      text-decoration: none
      &:hover
        color: $green-hover
        text-decoration: underline
    @media (min-width: $desktop-md)
      a
        font-size: 24px

  &__info-email
    a
      text-decoration: underline
    @media (min-width: $desktop-md)
      img
        @include boxSize(24px, 20px)

  &__info-phone
    margin-bottom: 16px
    @media (min-width: $desktop-md)
      img
        @include square(24px)

  &__working-hours
    margin-bottom: 0

