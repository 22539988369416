@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'

.recovery-password
  padding: 32px 0 64px
  background: #F4F5F5
  @media (min-width: 768px)
    padding-top: 56px

  h1
    @include font(false, 24px, 30px, bold)
    margin-bottom: 24px
    margin-top: 0
    @media (min-width: 992px)
      font-size: 30px
      line-height: 24px
  p
    margin-bottom: 16px

  .recovery-password-form
    .form-group
      margin-bottom: 16px
      &--submit
        margin-bottom: 0

    &__label
      width: 100%

    .floating-label
      @include font(14px, $default, 24px)
      @include positionAbsolute(14px, $left: 16px)
      font-family: MuseoSans, sans-serif
      position: absolute
      transition: all ease-in-out 0.3s
      font-weight: 500
      &.active
        font-size: 12px
        transform: translate(0, -12px)
        font-weight: 300

    &__input-tooltip-error
      @include font(12px, $red, 19px)
      display: none
      margin-top: 8px

    &__input
      @include fullWidthSize(52px)
      font-family: MuseoSans, sans-serif
      border: 1px solid #E0E2E2
      border-radius: 4px
      background-color: #FFFFFF
      box-shadow: none
      font-size: 14px
      padding: 18px 0 6px 16px
      color: black
      &:focus, &:focus-visible, &:-webkit-autofill
        border-color: #E0E2E2
        box-shadow: none
        outline: none
        & ~ .floating-label
          font-size: 12px
          transform: translate(0, -12px)
          font-weight: 300
      &::-webkit-credentials-auto-fill-button
        visibility: hidden
        pointer-events: none
        position: absolute
        right: 0
      &::-webkit-direct-focus
        outline: none
        outline-color: transparent
        outline-width: 0

    &__input-tooltip-success
      @include font(12px, $green-hover, 19px)
      margin-bottom: 8px

    &__form-group-error
      .recovery-password-form__input
        border-color: $red !important
      .recovery-password-form__input-tooltip-error
        display: block !important
      &:before
        color: $red

    .b-button
      @include font(16px, $white, 24px, bold)
      @include boxSize(100%, 48px)
      font-family: MuseoSans, sans-serif
      background-color: #FFA300
      text-transform: none
      padding: 0
      border: none
      @media (min-width: 768px)
        width: 229px
      @media (min-width: 992px)
        width: 227px
      @media (min-width: 1200px)
        width: 184px
      &:hover
        background-color: #DB8C00
        border: none

  .password-visibility
    position: absolute
    top: 16px
    right: 22px
    font-size: 18px
    fill: #A2A7A8
    cursor: pointer