// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.description-tabs
  margin-left: 100px

.localized-desc
  &__active-locale
    display: flex
    align-items: center
    cursor: pointer
    color: $default
    span
      display: inline-block !important
      margin-right: 5px
      margin-left: 5px
      @media (min-width: $desktop)
        font-size: 16px
    svg
      pointer-events: none
    &:hover svg
      fill: $green-hover

  &__active-locale-text
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif

  &__description-text p
    margin: 0

  &__badges
    min-height: 21px
    display: flex
    margin-bottom: 10px
    flex-direction: column
    @media (max-width: $tablet)
      flex-direction: row
    &.no-margin
      margin-bottom: 20px
    &--global
      margin-bottom: 0
      flex-direction: column
      @media (min-width: $desktop-md)
        flex-direction: row
      &.extra-indentation .description-badge--detail-view.disposal
        @media (max-width: $tablet)
          margin-bottom: 5px

  &__switcher
    top: 70px
    @media (max-width: $mobile)
      position: static

  &__top-menu-left
    display: flex
    background: #f6f6f6
    border-radius: 4px
    align-items: center
    padding: 7px 16px
    position: relative

  &__top-menu
    @include displayFlex(flex-start)
    width: 100%
    margin-bottom: 24px
    flex-direction: column
    @media (min-width: $desktop)
      margin-bottom: 32px

  &__title
    @include font(18px, $default, 26px, bold)
    .contact-title
      margin-top: 15px

  &__translated-by-google-icon
    img
      @include square(16px)

  &__locales_wrapper
    width: 328px
    margin-top: 10px
    background: $white
    overflow: hidden
    position: absolute
    z-index: 997
    left: 0
    box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
    border-radius: 4px
    column-count: 2
  .select-option-group
    position: relative
    padding-left: 25px
    .select-option
      @include font(16px, $black, 24px)
      font-family: MuseoSans, san-serif
      cursor: pointer
      margin-bottom: 0
    .option-checkmark
      @include positionAbsolute(2px, $left: 0)
      @include square(20px)
      background-color: $white
      border-radius: 50%
      border: 2px solid $gull-grey-text
      &:after
        @include beforeEmptySquare(12px, $bg-badge-new, 2px, $left: 2px)
        border-radius: 50%
        display: none
    input
      opacity: 0
      cursor: pointer
      display: none
      &:checked
        ~ .select-option
          .option-checkmark
            border: 2px solid $bg-badge-new
            &:after
              display: block

  &__locales-link-wrap
    padding: 8px 16px
    cursor: pointer
    &:hover
      background: #f6f6f6

  &__locales-link
    display: block
    margin: 0 0 5px 0 !important
    cursor: pointer
    span
      display: inline-block !important
      margin-right: 5px
      margin-left: 5px

  &__original_desc_wrapper
    margin-top: 16px
    position: relative
    .opacity-block
      position: absolute
      height: 60px
      width: 100%
      top: -60px
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1))

  &__original_desc_read_more
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    color: $green
    cursor: pointer
    text-decoration: underline
    margin-bottom: 8px
    &:hover
      color: $green-hover !important
      svg
        fill: $green-hover
    svg
      fill: $green

  &__google-translated-text
    font-size: 12px
    margin-right: 15px
    line-height: 20px
    margin-left: 30px
    @media (min-width: $tablet)
      margin-left: 50px
    @media (min-width: $desktop-md)
      font-size: 14px

  &__content

    ul
      li
        @include fontSLH(16px, 24px)
        font-family: MuseoSans, san-serif

  &__description-text
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    overflow-wrap: break-word

  &__title-h2
    margin-bottom: 16px
    @media (max-width: $screen-md-max)
      font-size: 16px
