// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.space-calculator-modal
  background: transparent
  @media (max-width: $desktop-md)
    inset: unset !important
    transform: translate(0, 0) !important
    margin-right: 0 !important
    left: 0 !important
    margin-top: 69px !important
    max-width: 100vw !important
  @media (min-width: $desktop-md)
    width: 974px !important
    max-width: calc(100vw - 64px) !important
    transform: translate(-50%, -37%) !important
    padding-bottom: 50px

  &__wrapper
    padding: 24px 16px 64px
    position: relative
    border-radius: 8px
    background: $white
    @media (min-width: $tablet)
      padding: 32px 80px 80px
    @media (min-width: $desktop-md)
      padding: 32px 40px 40px

  &__close-btn
    @include positionAbsolute(26px, 16px)
    cursor: pointer
    svg
      fill: #999999
    @media (min-width: $tablet)
      top: 32px
      right: 80px
    @media (min-width: $desktop-md)
      right: 40px

  &__headline
    @include fontSLH(20px, 28px)
    font-weight: 900
    padding-right: 32px

  &__subheader
    @include fontSLH(16px, 24px)
    margin-bottom: 32px
    @media (min-width: $tablet)
      margin-bottom: 24px

  &__option-box
    padding: 24px 16px
    border: 1px solid #F3F3F3
    background: rgba(248, 248, 248, 0.50)
    border-radius: 8px
    margin-bottom: 8px
    @media (min-width: $tablet)
      padding: 24px 16px 32px
      margin-bottom: 16px

  &__option-box-body
    margin: 0 auto
    width: fit-content

  &__option-box-headline
    @include fontSLH(18px, 26px)
    font-weight: 900
    margin-bottom: 24px
    text-align: center
    @media (min-width: $tablet)
      margin-bottom: 32px

  &__input-wrapper
    @include displayFlex(center)
    margin-bottom: 16px
    &:last-child
      margin-bottom: 0
    input
      border: none
      text-align: center
      color: $default

    .decrement, .increment
      @include displayFlex(center, center)
      @include fontSLH(16px, 32px)
      @include square(24px)
      border-radius: 8px
      cursor: pointer
      background: $orange
      color: $white
      &:hover
        background: $orange-hover

    &.workplaces
      justify-content: center
      margin-top: 32px
      margin-bottom: 0
      input
        @include fontSLH(24px, 32px)
        width: 125px
        height: 40px
      .decrement, .increment
        @include square(40px)
        @include fontSLH(24px, 32px)
        border-radius: 8px


  &__number-dropdown
    @include fontSLH(16px, 32px)
    height: 24px
    width: 43px
    background: transparent

    &__label
      @include fontSLH(16px, 18px)
      margin-left: 16px
      margin-right: 8px


  input:focus-visible
    outline: none

  &__radio-btns
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row
      justify-content: center


  .radio-buttons
    &__button-wrapper
      margin-right: 0
      &:last-child .radio-buttons__label
        margin-bottom: 0
      &.checked, &:hover
        .radio-buttons__label
          background: $orange
          color: $white
        .radio-buttons__icon
          border-color: $white
          span
            background: $white
      @media (min-width: $tablet)
        margin-right: 16px
        &:last-child
          margin-right: 0
    &__icon
      top: 14px
      left: 12px
      background: transparent
    &__label
      height: 48px
      padding: 12px
      border-radius: 8px
      background: rgba(128, 128, 128, 0.10)
      margin-bottom: 16px
      min-width: 201px
      @media (min-width: $tablet)
        min-width: 127px
        margin-bottom: 0
    input
      visibility: hidden

  &__tooltip-wrapper
    display: block
    position: relative
    cursor: pointer
    svg
      fill: #D9D9D9

  &__tooltip
    max-width: calc(100vw - 32px)
    width: 250px
    transition: none // Bug on Safari
    &.show
      opacity: 1

  &__option-checkbox-wrapper
    @include displayFlex(center)

  &__option-row
    margin-bottom: 16px
    &:last-child
      margin-bottom: 0

  .form-group
    margin-bottom: 0
    label
      margin-bottom: 0
      .checkbox-label
        margin-right: 8px
        @media (min-width: $tablet)
          white-space: nowrap

  &__summary-wrapper
    margin-top: 24px
    @media (min-width: $tablet)
      margin-top: 16px

  &__summary-title
    @include fontSLH(18px, 26px)
    font-weight: 900
    margin-bottom: 16px

  &__summary-list-item
    @include displayFlex(center, space-between)
    @include fontSLH(16px, 24px)
    margin-bottom: 24px
    &:last-child
      border-bottom: 1px solid #E1E1E1
      padding-bottom: 24px

  &__summary-list-item-count,
  &__summary-list-item-area
    font-weight: 900
    white-space: nowrap

  &__summary-list-item-multiplier
    color: rgba(0, 0, 0, 0.30)
    margin: 0 8px

  &__summary-list-item-label
    @include displayFlex(center)
    flex-wrap: wrap

  &__summary-list-item-left
    @include displayFlex(center)

  &__summary-total
    @include displayFlex(center, space-between)
    @include fontSLH(16px, 24px)
    font-weight: 900

  .form-group--checkbox .checkbox-label-wrapper:hover .checkmark
    border-color: $orange

  .form-group--checkbox input:checked ~ .checkmark
    border-color: $orange
    background-color: $orange
