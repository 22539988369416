// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.providers
  margin-top: 64px
  @media (min-width: $desktop-md)
    margin-top: 96px

  &__headline
    margin-bottom: 16px
    text-align: center
    @media (min-width: $tablet)
      margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-bottom: 32px

  &__image
    width: 100%
    @media (min-width: $tablet)
      display: none
    &--desktop
      display: none
      @media (min-width: $tablet)
        display: block

  &__dk
    @include displayFlex(center, center, column)
    margin-top: 64px
    @media (min-width: $tablet)
      flex-direction: row

  &__dk-title
    @include fontSLH(16px, 21px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 16px
    text-align: center
    @media (min-width: $tablet)
      margin: 0 12px 0 0

  &__dk-logos-wrapper
    @include displayFlex(center)
    display: flex
    @media (max-width: 767px)
      justify-content: space-between
      width: 100%

  &__dk-img-wrapper
    img
      height: 40px
      margin-left: 12px
      margin-right: 12px
    &:last-child img
      height: 28px
