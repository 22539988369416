.trustpilot
  display: flex
  flex-direction: column
  align-items: center

  a
    color: black
    &:hover
      color: black

  &__top-block, &__bottom-block
    display: flex
    align-items: center
    justify-content: center

  &__bottom-block
    font-size: 12px
    line-height: 21px
    img
      margin-left: 8px

  &__top-block
    line-height: 24px
    img
      position: relative
      top: -2.5px
      margin-left: 8px

.trustpilot-wrapper
  display: none
  &.visible
    display: block
