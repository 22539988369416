@import '../../src/stylesheets/variables'

.ReactModal__Body--open
  height: 100vh

.sign-up
  background: $grey-bg
  padding: 56px 0 60px
  position: relative
  @media (max-width: $screen-md)
    padding: 48px 0 60px
  @media (max-width: $screen-xs)
    padding: 21px 0 30px

  &__header-headline
    h1
      font-weight: bold

  &__header-subheadline
    margin-top: 15px
    letter-spacing: 0.06px
    p
      margin: 0
    @media (max-width: $screen-xs)
      margin-top: 10px
