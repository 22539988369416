@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.payment-model
  ul
    list-style: none
    padding: 0

  p
    margin: 0

  li p
    margin-left: 16px

  &__main-list
    margin-bottom: 16px
    @media (max-width: $screen-xs)
      margin-bottom: 8px

  &__main-list-radio-wrapper,
  &__sublist-radio-wrapper
    display: flex
    align-items: center
    width: fit-content
    cursor: pointer
    &:hover
      .payment-model__sublist-radio,
      .payment-model__main-list-radio
        border: 2px solid $green

  &__sublist-radio-wrapper
    margin-bottom: 8px
    p
      line-height: 24px
      font-size: 16px
      font-family: MuseoSans, Helvetica, Arial, sans-serif

  &__main-list-radio-wrapper
    margin-bottom: 16px
    .payment-model__main-list-radio
      margin-top: 3px

  &__sublist
    margin: 0 0 32px 36px
    @media (max-width: $screen-xs)
      margin-bottom: 20px

  &__main-list-item.active
    margin-bottom: 0
    .payment-model__main-list-radio
      border: 2px solid $bg-badge-new
    .payment-model__main-list-radio:before
      position: absolute
      top: 2px
      left: 2px
      content: ""
      width: 12px
      height: 12px
      background: #67a700
      border-radius: 50%

  &__sublist-radio-wrapper.active
    .payment-model__sublist-radio
      border: 2px solid $bg-badge-new
    .payment-model__sublist-radio:before
      position: absolute
      top: 2px
      left: 2px
      content: ""
      width: 12px
      height: 12px
      background: #67a700
      border-radius: 50%

  &__main-list-item
    overflow: hidden
    margin-bottom: 16px
    .payment-model__collapsible-wrapper
      overflow: hidden
      max-height: 0
      transition: max-height ease-in-out 0.6s
      -webkit-transition: max-height ease-in-out 0.6s
      -moz-transition: max-height ease 0.6s
      -o-transition: max-height ease 0.6s

    &.active
      .payment-model__collapsible-wrapper
        max-height: 1000px

  &__main-list-radio,
  &__sublist-radio
    @include square(20px)
    display: block
    border-radius: 50%
    border: 2px solid $gull-grey-text
    background: $white
    position: relative
    min-width: 20px
    align-self: flex-start

  &__sublist-radio
    margin-top: 1px

  &__sublist-radio-label,
  &__main-list-radio-label
    cursor: pointer

  &__main-list-radio-label
    @include font(18px, false, 24px)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    font-weight: 900

  &__sublist-header
    @include font(16px, false, 18px, bold)
    margin-bottom: 16px !important
    letter-spacing: -0.2px
    margin-left: 0 !important
    display: flex
    align-items: center
    svg
      margin-left: 8px
      cursor: pointer
      &:hover
        fill: $green-hover
      @media (max-width: $screen-xs-max)
        display: none

  &__sublist-subheader
    margin-left: 0 !important
    margin-bottom: 14px !important
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    line-height: 24px
    font-size: 16px

  &__subheader
    margin-left: 36px !important
    margin-bottom: 24px !important
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    line-height: 24px
    font-size: 16px

  &__notice
    color: $black
    font-size: 16px
    line-height: 24px
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    margin-top: 24px
    display: block

  &__sublist-radio-promo
    color: $orange
    font-size: 16px
    margin-left: 5px

  &__info-block
    padding-top: 32px

  &__info-title
    @include fontSLH(16px, 26px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 32px
    @media (min-width: $desktop-md)
      font-size: 18px

  &__info-title-hours
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    margin-bottom: 8px

  &__info-links
    margin-bottom: 32px
    @media (min-width: $tablet)
      margin-bottom: 40px

  &__info-phone, &__info-email
    @include displayFlex(center)
    img
      margin-right: 13px
    a
      @include font(21px, $default, 32px)
      font-family: MuseoSans, sans-serif
      text-decoration: none
      &:hover
        color: $green-hover
        text-decoration: underline
    @media (min-width: $desktop-md)
      a
        font-size: 24px

  &__info-email
    a
      text-decoration: underline
    @media (min-width: $desktop-md)
      img
        @include boxSize(24px, 20px)

  &__info-phone
    margin-bottom: 16px
    @media (min-width: $desktop-md)
      img
        @include square(24px)

  &__working-hours
    margin-bottom: 0
