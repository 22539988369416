@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.switcher-row
  @include displayFlex(center, space-around)
  height: 100%
  width: 100%
  @media (max-width: $mobile)
    padding-left: 0
    padding-right: 0

.switcher-container
  @include displayFlex(center, center)
  border: 1px solid #E0E2E2
  position: relative
  font-size: 14px
  @media (min-width: $tablet)
    border-radius: 4px

.switcher-pill
  @include displayFlex(center, center, column)
  width: auto
  color: $black
  cursor: pointer
  padding: 13px 10px
  position: static
  @media (min-width: $desktop-md)
    padding: 23px 10px
    flex-direction: row

  &> span
    @include fontSLH(12px, 16px)
    display: block
    text-align: center
    @media (min-width: $desktop-md)
      @include fontSLH(14px, 16px)

  &> svg
    margin: 0
    fill: $black
    @media (min-width: $tablet)
      height: 24px
      width: 24px
      min-width: 24px
    @media (min-width: $desktop-md)
      margin-right: 8px

  &:hover
    color: $green
    &> svg
      fill: $green

  &.active
    position: relative
    color: $green
    border-bottom: 2px solid $green
    &> svg
      fill: $green


