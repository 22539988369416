// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.full-screen-btn
  @include positionAbsolute(0, 0, auto !important)
  background-color: transparent
  padding: 15px
  transition: bottom 1s
  border-radius: 0 0 0 20%
  border: 0
  cursor: pointer
  outline: none
  z-index: 4

  &.full-screen-in
    background-color: $bg-charcoal
    padding: 10px


.dv-gallery-container
  position: static
  height: 100%
  z-index: 9999

  .dv-gallery-wrapper
    height: 100%

  .image-gallery.fullscreen
    background: white
    height: 100vh !important
    width: 100vw !important
    @media (max-width: $tablet)
      .image-gallery-slides,
      .image-gallery-slide-wrapper,
      .image-gallery-slide,
      .image-gallery-image,
      .image-gallery-content,
      .image-gallery-swipe
        height: auto

    .image-gallery-slides
      max-height: 100%
      @media (min-width: $tablet)
        max-height: calc(100vh - 250px)
        border-radius: 4px

    &.container
      @media (min-width: 769px)
        padding: 0 calc((100% - 738px) / 2) !important
      @media (min-width: $desktop-md)
        padding: 0 calc((100% - 930px) / 2) !important
      @media (min-width: $desktop)
        padding: 0 calc((100% - 1184px) / 2) !important

      @media (max-width: $tablet)
        width: 100% !important
        padding: 0 !important
        margin: 0

    .image-gallery-swipe
      border-radius: 0
      overflow: hidden
      @media (min-width: $desktop-md)
        border-radius: 4px

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-bottom
      position: relative
      background: $white
      padding: 0
      margin: 8px 16px
      @media (min-width: $tablet)
        margin: 16px 24px 0 24px
      @media (min-width: $desktop-md)
        margin: 24px 0
        position: absolute


    .image-gallery-landscape-background
      @media (max-width: $tablet)
        max-height: 465px
        position: inherit
        left: 0
        transform: inherit

    .image-gallery-thumbnails
      padding: 0
      @media (max-width: $tablet)
        padding: 0

    .image-gallery-thumbnail
      @include square(80px)
      min-width: 80px
      border-radius: 4px
      overflow: hidden
      border: 0 solid transparent
      @media (min-width: $desktop-md)
        @include square(88px)
        min-width: 88px

      & + .image-gallery-thumbnail
        margin-left: 8px
        @media (min-width: $desktop-md)
          margin-left: 16px

      .image-gallery-thumbnail-inner
        @include square(100%)
        overflow: hidden
        border-radius: 4px
        img
          height: 100%
          width: auto
          min-width: 100%

      &.active, &:hover
        border: 2px solid $green
        .image-gallery-thumbnail-inner
          border-radius: 0

      &.hidden
        display: none

    .image-gallery-index
      right: 24px
      top: 24px


    .dv-gallery-fullscreen-header
      @include boxSize(100vw, 55px)
      @include font(20px, $default, 24px)
      @include displayFlex(center, center)
      position: fixed
      top: 0
      left: 0
      text-transform: capitalize
      border-bottom: 1px solid #E0E2E2
      @media (min-width: 769px)
        display: none

    .image-gallery-custom-right-nav,
    .image-gallery-custom-left-nav,
    .image-gallery-index
      @media (max-width: $tablet)
        top: calc(100% + 112px)
      @media (max-width: $mobile)
        top: calc(100% + 104px)

    .image-gallery-custom-right-nav
      @media (max-width: $tablet)
        margin-right: 24px
      @media (max-width: $mobile)
        margin-right: 16px

    .image-gallery-custom-left-nav
      @media (max-width: $tablet)
        margin-left: 24px
      @media (max-width: $mobile)
        margin-left: 16px

    &:not(.horizontal-mobile)
      .image-gallery-index
        @media (max-width: $tablet)
          opacity: 1 !important
          right: calc(50% - 16px)
          span
            font-size: 14px

    .image-gallery-slide img
      width: 100%
      @media (min-width: $desktop-md)
        object-fit: cover
        height: 100%

    &.horizontal-mobile
      .image-gallery-content.fullscreen
        padding-top: 0
        height: 100vh
      .image-gallery-slide
        min-height: 100vh
        img
          //height: 100%
          width: 100%
          max-height: 100vh

      .image-gallery-custom-right-nav,
      .image-gallery-custom-left-nav
        top: calc(50% - 16px)
      .image-gallery-index
        right: 62px
        top: 15px
      .image-gallery-landscape-background
        height: 100%
        min-height: 100vh

      .full-screen-btn.full-screen-in
        @include positionAbsolute(15px, 15px)
        @include displayFlex(center, center)
        @include square(32px)
        margin: 0
        opacity: 0.68

      .floor-plans-gallery .image-gallery-image img
        max-height: 100vh
        height: 100vh !important

      .image-gallery-slides
        max-height: 100%

    .floor-plans-gallery .image-gallery-image img
      height: auto !important
      width: auto
      max-height: calc(100vh - 338px)
      @media (min-width: $desktop-md)
        max-height: calc(100vh - 272px)


  &.ios-fullscreen
    @include boxSize(100vw, 100vh)
    top: 0
    position: fixed

  .full-screen-btn
    padding: 5px
    margin: 15px
    background-color: $white
    opacity: 0.8
    border-radius: 3px
    svg
      fill: #231F20
    &.full-screen-in
      margin: 16px 16px 0 0
      padding: 0
      position: fixed
      top: 0
      right: 0
      @media (min-width: $tablet)
        margin-right: 24px
      @media (min-width: $desktop-md)
        margin: 28px 28px 0 0
      svg
        fill: $default
        @media (min-width: $desktop-md)
          @include square(28px)
        @media (min-width: $desktop)
          @include square(36px)

  &.location-rented-out
    .image-gallery-right-nav,
    .image-gallery-left-nav
      display: none

  .image-gallery-right-nav,
  .image-gallery-left-nav
    color: $black
    background: $white
    outline: none
    &:before
      font-weight: bold
      text-shadow: 0 1px 1px #1a1a1a
    &:hover:before
      color: $white

  .image-gallery-custom-right-nav
    position: absolute
    right: 0
    top: calc(50% - 16px)
    z-index: 1
    background: #fff
    height: 32px
    width: 32px
    opacity: 0.68
    -webkit-border-radius: 4px
    -moz-border-radius: 4px
    border-radius: 4px
    margin-right: 16px
    cursor: pointer

  .image-gallery-custom-left-nav
    position: absolute
    left: 0
    top: calc(50% - 16px)
    z-index: 1
    background: #fff
    height: 32px
    width: 32px
    opacity: 0.68
    -webkit-border-radius: 4px
    -moz-border-radius: 4px
    border-radius: 4px
    margin-left: 16px
    cursor: pointer

  .image-gallery-index
    right: 70px
    top: 15px
    background: #fff
    opacity: 0
    border-radius: 4px
    cursor: pointer
    color: #000
    height: 32px
    width: 32px
    font-size: 12px
    display: flex
    align-items: center
    justify-content: center
    padding: 0
    &.active
      opacity: 0.68

  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-bottom
    @include positionAbsolute(false, 0, 0, 0)
    background-color: rgba($dark-borders, 0.5)
    padding: 9px 0 9px 9px
    @media (max-width: $mobile)
      padding: 14.5px 0 14.5px 14.5px


  .image-gallery-image
    background-position: center center
    background-size: cover
    background-repeat: no-repeat
    cursor: pointer
    background-color: $white
    .helper-fullscreen
      display: inline-block
      height: 100%
      vertical-align: middle

  .noFullScreen
    cursor: default

  .floor-plans-gallery .image-gallery-image
    background-size: contain
    display: flex
    justify-content: center
    img
      width: 100%
      height: 100%
      max-height: 100%
      max-width: 100%
      @media (max-width: $mobile)
        min-height: 233px
    &.vertical-align
      display: flex
      justify-content: center

  .image-gallery,
  .image-gallery-slides,
  .image-gallery-slide-wrapper,
  .image-gallery-slide,
  .image-gallery-image,
  .image-gallery-content,
  .image-gallery-swipe
    @include square(100%)

  .image-gallery.fullscreen-modal
    z-index: 9999
    width: 100vw

  .image-gallery-content.fullscreen
    z-index: 1000
    padding-top: 136px
    background: $white
    @media (min-width: $desktop-md)
      padding-top: 80px

    .image-gallery-right-nav,
    .image-gallery-left-nav
      font-size: 40px

    .full-screen-btn
      bottom: 0

    .image-gallery-image
      background-size: contain

  .image-gallery-content
    .image-gallery-slide-wrapper
      .image-gallery-swipe
        .image-gallery-slides
          border-top-right-radius: 4px
          border-top-left-radius: 4px

  .image-gallery-thumbnail.active
    border: 2px solid $borders

  .image-gallery-fullscreen-button
    font-size: 10px

  .image-gallery-bullets .image-gallery-bullet
    background-color: $white
    box-shadow: 0 2px 6px 0 rgba(49, 60, 63, 0.25)
    &.active
      background-color: $green

  .image-gallery
    .image-gallery-content
      &:not(.fullscreen) .image-gallery-image img
        max-height: 600px
        object-fit: cover
        @media (max-width: $mobile)
          @include fullWidthSize(auto)
          max-width: 100vw
    &:not(.floor-plans-gallery) .image-gallery-content
      &:not(.fullscreen) .image-gallery-image img
        @media screen and (min-width: $tablet) and (max-width: $desktop-md)
          height: 334px
          width: 100%
        @media (min-width: $screen-md)
          height: 494px
          width: 100%
        border-top-left-radius: 4px
        border-top-right-radius: 4px
        @media (max-width: $mobile)
          height: 100%
          max-width: 100vw

  .image-gallery-slide img
    background-color: $grey-bg
    max-height: 100vh

  @media (max-width: $screen-sm)
    .container
      max-width: 100%

      &.gallery-container
        padding-left: 0
        padding-right: 0

  * :fullscreen .image-gallery-landscape-background
    height: 1200px !important

  * :fullscreen .image-gallery-landscape-text
    padding-top: 20%

  button:focus
    outline: 0

  // Material icons default class
  .MuiSvgIcon-root-1, svg.c1
    @include square(18px !important)
    vertical-align: text-top

// styles for ab test when we added fixed cta button and remove
// label 'get info from gallery images'
.fixed-cta-ab-test-styles
  .full-screen-btn
    @media only screen and (max-width: $screen-s-xs)
      top: 0
  .image-gallery-index
    @media only screen and (max-width: $screen-s-xs)
      top: 15px

.image-gallery.fullscreen-modal .image-gallery-content
  top: 0 !important
  transform: translateY(0) !important

.image-gallery-thumbnails-container
  @include displayFlex(center, flex-start)
  height: 80px
  //overflow: hidden
  @media (min-width: $desktop-md)
    height: 88px

