@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.formsent
  .content-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    padding: 32px 48px
    @media (max-width: $tablet)
      padding: 24px 16px

  .modal-close
    position: absolute
    right: 20px
    top: 20px
    cursor: pointer

  .main-header
    .main-title
      @include fontSLH(24px, 32px)
      font-weight: bold
      margin-bottom: 16px
      @media (max-width: $tablet)
        font-size: 21px
    p
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, san-serif
      margin-bottom: 0

  .thank-close-button
    width: 100%
    height: 48px
    font-size: 16px
    background-color: $orange
    margin-top: 16px
    border-radius: 6px
    border: none
    position: relative
    font-weight: bold
    padding: 12px 24px
    display: inline-block
    color: white
    text-decoration: none
    @media (min-width: $tablet)
      margin-top: 24px
    &:hover
      background-color: $orange-hover

  .icon-wrapper
    margin-right: 20px
    display: none
    @media (min-width: $tablet)
      display: block
