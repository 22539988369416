// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.description-offset
  display: none
  @media (min-width: $desktop)
    display: flex

.dv-info-section-wrapper--description
  padding-top: 32px
  margin-bottom: 64px
  @media (min-width: $tablet)
    padding-top: 48px !important
    padding-right: 16px
  @media (min-width: $desktop-md)
    padding-right: 32px
