// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.smart-text-tooltip
  margin-top: -20px
  margin-bottom: 20px
  height: 20px
  color: $green

  svg
    fill: $green

  .__react_component_tooltip
    @include fontSLH(16px, 24px)
    margin-right: 16px
    padding: 0 8px
    color: $default
    width: calc(100% - 62px)
    text-align: center
    &.show
      opacity: 1
    @media (min-width: $tablet)
      width: auto
