// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.favorites
  position: relative

  h1
    margin-bottom: 8px

  &__header
    @include displayFlex(center, space-between)
    position: relative
    padding-top: 16px
    padding-bottom: 16px
    img
      width: 82px
      height: auto
      margin-left: 16px
    @media (min-width: $tablet)
      padding-top: 24px
      padding-bottom: 24px
    @media (min-width: $desktop-md)
      padding-top: 32px
      padding-bottom: 32px
      img
        width: 98px

  &__search-agent-wrapper
    padding: 40px 0

  .form-set-info-submit
    height: 48px

    &__wrapper
      @include fontSLH(16px, 24px)

  &__action-bar
    @include displayFlex(center, space-between)
    padding-top: 16px
    padding-bottom: 16px

  &__get-info-button
    @include font(14px, $white, 16px, 900)
    padding: 12px 8px
    background: $orange
    border-radius: 8px
    cursor: pointer
    margin-right: 16px
    text-align: center
    &.inactive
      pointer-events: none
      background: #D59F69
    &:hover
      background: $orange-hover
    @media (min-width: $tablet)
      white-space: nowrap
      min-width: 283px
    @media (min-width: $desktop-md)
      @include fontSLH(16px, 20px)
      padding: 10px 16px

  &__action-bar-right
    @include displayFlex(center, flex-end)
    @media (min-width: $desktop-md)
      width: 100%
      justify-content: space-between

  &__select-group,
  &__delete-group
    @include square(40px)
    @include displayFlex(center, center)
    border: 1px solid
    border-radius: 8px
    position: relative

    @media (min-width: $desktop-md)
      @include square(auto)
      border: none
      svg
        display: none

  &__action-buttons-wrapper
    @include positionAbsolute(49px, 0)
    flex-direction: column
    display: none
    padding: 8px
    border-radius: 4px
    border: 1px solid
    white-space: nowrap
    z-index: 1
    background: white

    &.open
      display: flex

    &--orange
      border-color: $orange
      &:before
        border-bottom-color: $orange

    &--red
      border-color: #EA4335
      &:before
        border-bottom-color: #EA4335

    div
      @include fontSLH(12px, 24px)
      cursor: pointer
      @media (min-width: $desktop-md)
        @include fontSLH(16px, 20px)
        border: none
        padding: 9px 8px
        min-width: 133px
        margin-right: 16px
        border: 1px solid
        border-radius: 8px
        text-align: center
        &.favorites__delete-all-btn
          margin-right: 0

    &:before, &:after
      content: ""
      width: 0
      height: 0
      position: absolute

    &:before
      border-bottom: 9px solid
      border-left: 5px solid transparent
      border-right: 5px solid transparent
      top: -9px
      right: 14px
      margin-left: -10px

    &:after
      border-bottom-color: #fff
      border-bottom-style: solid
      border-bottom-width: 7px
      border-left: 3.5px solid transparent
      border-right: 3.5px solid transparent
      top: -7px
      right: 15.5px
      margin-left: -8.5px

    @media (min-width: $desktop-md)
      display: flex
      flex-direction: row
      border: none
      position: unset
      padding: 0
      &:before, &:after
        display: none

  &__select-group
    color: $orange
    border-color: $orange
    margin-right: 16px

  &__delete-group
    color: #EA4335
    border-color: #EA4335

  &__select-all-btn
    border-bottom: 1px solid $orange

  &__delete-selected-btn
    border-bottom: 1px solid #EA4335

  &__select-all-btn,
  &__delete-selected-btn
    padding: 0 8px 4px

  &__deselect-all-btn,
  &__delete-all-btn
    padding: 4px 8px 0

  &__delete-all-btn,
  &__delete-selected-btn
    @media (min-width: $desktop-md)
      color: #979797
      border-color: #979797
      &:hover
        color: #EA4335
        border-color: #EA4335

  &__deselect-all-btn,
  &__select-all-btn
    @media (min-width: $desktop-md)
      &:hover
        color: $orange-hover
        border-color: $orange-hover
