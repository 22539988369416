@import '../../../src/stylesheets/variables'


.virtual-tour-form
  &__input
    border-radius: 0

  &__btn
    border-radius: 8px
    border-color: $orange
    color: $white
    cursor: pointer
    padding: 15px 0
    display: block
    text-align: center
    font-size: 14px
    font-weight: bold
    width: 100%
    white-space: nowrap
    background-color: $orange
    &> i
      color: $white
      margin-right: 8px
      vertical-align: middle
    &.active
      display: block !important
      background-color: $orange-hover

.input-tooltip-error
  color: $red
  font-size: 12px
  line-height: 19px
