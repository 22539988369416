// MOBILE-FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.parking-info-window
  width: 300px
  overflow: auto
  background: $white
  padding: 10px

  &__title-link
    font-family: 'MuseoSans'
    font-weight: 500

  &__kind
    color: #aaaaaa
    font-size: 0.8em
    text-transform: uppercase
    margin-top: 4px

  &__postal-area,
  &__address-line1
    @include truncate()

  &__price
    @include truncate()
    margin-top: 8px

  &__price-label,
  &__price-value
    display: inline
    font-family: 'MuseoSans'
    font-weight: 500

  &__wrapper
    @include displayFlex(center)
    position: relative

  &__container
    flex-grow: 1

  &__image
    max-width: 80px
    height: 56px

  &__block-link
    @include positionAbsolute(0, 0, 0, 0)

.parking-info-cta.typeform-share
  @include font(14px, $orange, 34px, 600)
  @include truncate()
  margin: 0
  margin-top: 10px
  border: 2px solid $orange
  border-radius: 8px
  padding: 0 26px
  display: inline-block
  font-family: MuseoSans, Helvetica, Arial, sans-serif
  font-weight: 900
  text-decoration: none
  background-color: $white
  text-align: center
  max-width: 100%
  transition: all .2s ease
  &:focus,
  &:visited,
  &:active,
  &:link
    color: $orange
    outline: none
  &:hover
    cursor: pointer
    text-decoration: none
    background-color: $orange
    color: $white

.gm-style .gm-style-iw .parking-info-window-title
  padding-bottom: 7px

.gm-style .gm-style-iw .parking-info-window-title
  font-size: 1.3em

.gm-style .gm-style-iw .parking-info-window-kind
  @include truncate()
