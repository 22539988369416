// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.reviews-widget
  width: 100%

  &__wrapper
    padding: 16px 24px
    background: #EEF5FA
    border-radius: 4px
    margin-bottom: 32px
    @media (min-width: $tablet)
      @include displayFlex(center, space-between)
      margin-bottom: 40px

  &__logo-wrapper
    @include displayFlex(center)
    margin-bottom: 8px
    svg
      margin-right: 8px

  &__logo-headline
    @include fontSLH(16px, 19px)
    text-transform: capitalize

  &__rating-wrapper
    @include displayFlex(center)
    margin-bottom: 16px
    @media (min-width: $tablet)
      margin-bottom: 0

  &__rating
    @include font(12px, #5C6268, 14px)
    margin-right: 8px

  &__rating-stars
    @include displayFlex(center)
    margin-right: 16px

  &__reviews-count
    @include font(12px, #5C6268, 14px, 300)

  &__button-wrapper
    text-align: center
    display: block
    &:hover
      text-decoration: none

  &__button
    @include font(12px, $white, 14px)
    display: inline-flex
    justify-content: center
    align-items: center
    height: 32px
    padding: 0 12px
    background: #4885ED
    border-radius: 4px
    cursor: pointer
    &:hover
      background: #0952CD

