.no-linear-input-range
  margin-top: 12px
  .noUi-target
    background: #e8e8e8
    border-radius: 4px
    border: none
    box-shadow: none
    margin: 0 10px
  .noUi-horizontal
    height: 5px
  .noUi-connect
    background: #77bc1f

  .noUi-handle
    width: 21px
    height: 21px
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5)
    border-radius: 100%

  .noUi-handle:before,
  .noUi-handle:after
    content: none

  .input-range
    &__label
      margin-bottom: 20px
    &__inputs
      margin-top: 20px
    &__label,
    &__inputs
      display: flex
      justify-content: space-between
