// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.information-unavailable
  color: $default
  &__wrapper
    @include displayFlex(center, false, column)
    margin: 32px 0 64px
    @media (min-width: $tablet)
      margin: 56px 0 64px
      flex-direction: row
      align-items: flex-start

  &__img-block
    display: none
    align-items: center
    margin-top: 24px
    @media (min-width: $tablet)
      display: flex
      margin-top: 0
    &--mobile
      display: flex
      @media (min-width: $tablet)
        display: none

  &__img
    width: 100%
    height: auto
    @media (min-width: $tablet)
      width: 144px
      margin-right: 0
    @media (min-width: $desktop-md)
      width: 194px
    @media (min-width: $desktop)
      width: 100%

  &__address
    @include fontSLH(16px, 24px)
    font-weight: 100 !important
    margin-bottom: 0
    @media (min-width: $tablet)
      margin-bottom: 16px
    @media (min-width: $desktop-md)
      @include fontSLH(16px, 32px)

  &__title
    @include fontSLH(24px, 32px)
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)

  &__subtitle
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    margin: 0

  &__view-more-link
    text-transform: lowercase
    margin-left: 5px
    text-decoration: underline

  .similar-locations
    padding-top: 0
    margin-bottom: 64px !important
