@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.sign-up-modal
  @include boxSize(100vw, 100%)
  @include displayFlex(center, center)
  position: fixed
  top: 0
  left: 0
  //background: rgba(0, 0, 0, 0.6)
  z-index: 10000
  overflow-x: hidden
  overflow-y: auto
  opacity: 0
  transition: opacity 0.15s linear
  flex-flow: wrap

  &.active
    .sign-up-modal__wrapper
      transform: translate(0, 0)
  &.fade.in
    opacity: 1

  &__wrapper
    @include boxSize(984px, 100%)
    position: relative
    background-color: $white
    border: 1px solid rgba(0,0,0,0.2)
    border-radius: 6px
    box-shadow: 0 3px 9px rgba(0,0,0,0.5)
    background-clip: padding-box
    outline: 0
    transform: translateY(-25%)
    transition: all 0.3s ease-out
    margin: 30px 0
    overflow-y: scroll
    @media (max-width: $screen-lg-md-max)
      width: 700px
    @media (min-width: $screen-sm)
      box-shadow: 0 5px 15px rgba(0,0,0,0.5)
    @media (max-width: $screen-xs-max)
      width: auto
      margin: 30px 10px

  &__header
    display: flex
    justify-content: space-between
    padding: 32px 32px 24px
    width: 100%
    border-bottom: 1px solid #e5e5e5
    h5
      margin: 0
    .sign-up-modal__close-btn
      display: flex

  &__header-title
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    font-weight: 900
    margin: 0


  &__close-btn
    cursor: pointer
    svg
      pointer-events: none

  &__body
    padding: 24px 32px 32px

  &__pricing-description
    font-size: 16px
    line-height: 24px

  &__pricing-table-wrapper
    text-align: center
    h3
      margin: 32px 0 24px

  &__table
    width: 100%
    max-width: 100%
    background-color: transparent
    display: table
    border: 1px solid #E0E2E2
    border-radius: 4px
    border-collapse: separate
    border-spacing: 0
    tr td
      text-align: center
      padding: 18px 15px
      font-size: 16px
      line-height: 21px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      &.bold-green
        font-weight: 900
        color: $green-hover

    tr:first-child
      td
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 900

    thead tr td
      color: $bg-charcoal
      font-weight: bold
    tr td:first-child
      text-align: left
      width: 35%
    thead tr td:not(:empty)
      background: $bg-badge-rented-out
    tbody
      border: 1px solid #E0E2E2
      td
        border-right: 1px solid #E0E2E2
      td:last-child
        border-right: none
      tr:not(.header):nth-child(odd)
        background: $grey-bg
      tr:first-child
        td:first-child
          border-top-left-radius: 3px
        td:last-child
          border-top-right-radius: 3px
      tr:last-child
        td:first-child
          border-bottom-left-radius: 3px
        td:last-child
          border-bottom-right-radius: 3px
    .check-icon
      &:before
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20px' viewBox='0 0 24 24' width='20px' fill='%2377bc1f'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z'/%3E%3C/svg%3E")
        padding-top: 3px

  &__meeting-rooms
    tr td:first-child
      text-align: left
      width: 50%

  &__parkering
    tr td:first-child
      text-align: left
      width: 75%

  &__table-header
    td
      background: $bg-badge-rented-out
      font-family: MuseoSans, Helvetica, Arial, sans-serif !important
      font-weight: 900
      font-size: 18px !important
      &.sign-up-modal__table-td
        background: $white
        border-bottom: 1px solid #E0E2E2
        font-size: 18px

  &__table-td--dark
    background: $bg-charcoal !important
    color: $white !important
    border: 1px solid $inactive

  .highlight-basic
    tr
      td:nth-child(2)
        border-right: none
      td:nth-child(3)
        border-left: 2px solid $green
        border-right: 2px solid $green
      &:first-child td:nth-child(3)
        border-top: 2px solid $green
        border-top-left-radius: 4px
        border-top-right-radius: 4px
      &:last-child td:nth-child(3)
        border-bottom: 2px solid $green
        border-bottom-right-radius: 4px
        border-bottom-left-radius: 4px


  &__table-global
    tr td:first-child
      width: 70%

    tbody
      .sign-up-modal__table-td
        background: #C9E1A5

