// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.virtual-tour
  background-color: #F6F6F6
  height: 100%
  border-top-left-radius: 4px
  border-top-right-radius: 4px
  min-height: 235px
  &> div
    height: 100%
  @media (max-width: 540px)
    padding-bottom: 0
  &__row
    display: flex
    flex-wrap: wrap
    padding: 24px
    flex-direction: column
    @media (min-width: $desktop-md)
      flex-direction: row

  &__form-wrapper
    position: relative
    @media (min-width: $desktop-md)
      flex: 1
    .get-info-submit-button
      background: $orange
      height: 48px
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      color: $white
      font-weight: bold
      border-radius: 4px
      width: 100%
      padding: 12px 0
      border: none
      position: relative
      outline: none
      font-size: 16px
      &:hover
        background-color: $orange-hover
        border-color: $orange-hover
    .sent
      background-color: $orange-hover
      border-color: $orange-hover
    .form-group
      .order-input-valid ~ .floating-label,
      .error ~ .floating-label,
      input:focus ~ .floating-label,
      textarea:focus ~ .floating-label,
      .not-empty ~ .floating-label
        top: 2px
      .validation-message
        font-size: 12px

    //.jeudan-policy-checkbox
    //  margin-bottom: 0
    //  &__wrapper
    //    display: flex
    //    margin-top: 16px
    //    &:before
    //      visibility: visible
    //      @include beforeEmptySquare(17px, $white, 0, $left: 0)
    //      border: 1px solid #E0E2E2
    //      border-radius: 2px
    //      display: block
    //      left: 0
    //      pointer-events: none
    //      z-index: 1
    //      top: 2px
    //    svg
    //      @include positionAbsolute(1px, $left: 0)
    //      fill: $white
    //      z-index: 1
    //      pointer-events: none
    //    &.checked:before
    //      background: $green
    //      border-color: $green
    //    &.error
    //      border: none
    //      &:before
    //        border-color: $red
    //  input
    //    @include positionAbsolute(2px, $left: 0)
    //    font-size: 20px
    //    margin-right: 5px
    //    cursor: pointer
    //    border-color: transparent
    //    background-color: transparent
    //    opacity: 0
    //  &__label
    //    @include font(11px, $default, 14px)
    //    margin-left: 25px
    //    img
    //      height: 18px
    //      cursor: pointer
    //
    //  &__label-read-more-link
    //    margin-left: 5px
    //    color: $green
    //    text-transform: lowercase
    //    cursor: pointer
    //    text-decoration: underline
    //  &__validation-message-jeudan
    //    position: absolute
    //    font-size: 11px
    //    color: #fff
    //    line-height: 14px
    //    width: 196px
    //    left: -10px
    //    padding: 10px
    //    background: #313C3F
    //    border-radius: 2px
    //    right: calc(100% + 13px)
    //    top: 34px
    //    visibility: hidden
    //    &::before
    //      position: absolute
    //      top: -8px
    //      left: 10px
    //      content: ''
    //      width: 0
    //      height: 0
    //      border-left: 9px solid transparent
    //      border-bottom: 9px solid #313C3F
    //      border-right: 10px solid transparent
    //
    //  &__popup
    //    position: absolute
    //    background-color: $black
    //    padding: 16px 44px 16px 16px
    //    font-size: 12px
    //    line-height: 20px
    //    top: -266px
    //    color: $white
    //    width: 280px
    //    z-index: 11
    //    border-radius: 4px
    //    opacity: .86
    //    left: 0
    //    right: 0
    //    margin-left: auto
    //    margin-right: auto
    //    &:before
    //      content: ""
    //      position: absolute
    //      border-left: 10px solid $black
    //      border-right: 10px solid $black
    //      bottom: -8px
    //      left: 50%
    //      margin-left: -10px
    //      height: 20px
    //      transform: rotate(45deg)
    //
    //  &__popup-close-btn
    //    @include closeBtnRight(8px, 8px)
    //    svg
    //      fill: $white
    //      position: relative

  &__description-wrapper
    margin-right: 10px
    @media (min-width: $desktop-md)
      flex: 1
    @media (max-width: $desktop-md)
      margin-bottom: 32px
    @media (max-width: 767px)
      display: flex
      justify-content: center
      flex-direction: column
      margin: 0
      height: 100%
    h2
      @media (max-width: 767px)
        @include fontSLH(20px, 28px)
        margin-bottom: 8px
    p
      margin-bottom: 16px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
    a
      text-decoration: underline
      display: block
      margin-bottom: 16px

  &__open-modal-link
    @include fontSLH(16px, 24px)
    cursor: pointer
    color: $green
    text-decoration: underline
    &:hover
      color: $green-hover

  &__frame-wrapper
    height: 495px
    & > iframe
      @include square(100%)
      border: 0
      margin: 0
      padding: 0


  &__thanks-text-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    @media screen and (max-width: $desktop-md)
      flex: 1
    h2
      margin-bottom: 24px
      @media (max-width: 767px)
        @include fontSLH(20px, 24px)
        margin-bottom: 8px
    p
      margin-bottom: 0
      font-size: 16px
      line-height: 24px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      @media (max-width: $screen-xs-max)
        font-size: 12px
        line-height: 18px
        margin-bottom: 16px

    .icon-wrapper
      display: flex
      align-items: center
      @media (min-width: $desktop-md)
        margin-bottom: 24px
    svg
      fill: $green
      margin-right: 16px
      min-width: 32px
      @media (max-width: 767px)
        display: none
    .get-info-submit-button
      background: $orange
      cursor: pointer
      display: flex
      align-items: center
      color: $white
      font-weight: bold
      border-radius: 4px
      width: 328px
      height: 48px
      border: none
      position: relative
      outline: none
      padding-left: 32px
      font-size: 16px
      &:hover
        background-color: $orange-hover
        border-color: $orange-hover
      svg
        @include positionAbsolute(50%, 24px)
        fill: $white
        margin-top: -15px
      @media (max-width: $mobile)
        padding: 0
        justify-content: center
        width: 100%
        height: 36px

  .form-sent-wrapper
    padding: 20px 24px
    @media (min-width: $desktop-md)
      padding: 20px 159px

.virtual-tour-modal
  background: $white
  width: calc(100vw - 32px) !important
  margin: 0
  height: auto
  padding: 24px
  border-radius: 12px
  margin: 0 !important

  &__close-btn
    @include square(32px)
    @include positionAbsolute(12px, 12px)
    @include displayFlex(center, center)
    border-radius: 32px
    border: 1px solid rgba(0, 0, 0, 0.10)
    cursor: pointer

  h2
    @include fontSLH(20px, 28px)
    margin-bottom: 8px
    margin-right: 24px

  p
    @include fontSLH(14px, 20px)
    margin-bottom: 8px

  .get-info-submit-button
    @include displayFlex(center, center)
    @include font(16px, $white, 24px, 900)
    cursor: pointer
    background: $orange
    border-radius: 4px
    border: none
    outline: none
    width: 100%
    height: 48px
    margin-top: 24px
    &:hover
      background: $orange-hover

