// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.same-address-locations
  .grid-item
    margin-bottom: 0
    margin-top: 16px

  .list-item
    margin-top: 16px
    margin-bottom: 0
    &__wrapper
      margin: 0

  .dv-info-section__title
    margin-bottom: 0
    margin-top: 40px
    @media (min-width: $tablet)
      margin-top: 64px
      margin-bottom: 24px

  .list-item__energy-rating
    @include displayFlex(center)
    @include fontSLH(12px, 24px)
    &.a
      color: #22AB72
    &.b
      color: #7BB12A
    &.c
      color: #DBA600
    &.d
      color: #EF8514
    &.e
      color: #E53E1A
    &.f
      color: #9C181E
    &.g
      color: #8A1925
    svg
      pointer-events: none
      margin-left: 4px

  .list-item__energy-rating-wrapper
    position: absolute
    right: -10px
    top: -10px
    height: 38px
    width: 38px
    display: flex
    align-items: center
    justify-content: center

  .embla-list-item-slider,
  .embla-grid-item-slider
    .embla
      &__buttons
        @include displayFlex(center, space-between)
      &__controls
        position: absolute
        bottom: 16px
        width: 100%
        left: 0
        padding: 0 16px
      &__button
        @include square(24px)
        @include displayFlex(center, center)
        border-radius: 4px
        background: rgba(255, 255, 255, 0.8)
        transition: all .25s ease-in
        svg
          max-width: 24px
          max-height: 24px
          pointer-events: none

  .embla-list-item-slider .embla__controls
    width: calc(100% - 8px)
