@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.body__collapse
  margin-top: 16px
  padding: 0 24px
  .collapse__heading
    font-weight: bold
    font-size: 16px
    display: inline-block
    position: relative
    cursor: pointer
    padding-right: 20px
    user-select: none
    &[aria-expanded="true"]
      color: $green
    &:hover
      color: $green
      svg
        fill: $green
    svg
      position: absolute
      top: 3px
      right: -2px
  .collapse-block
    margin-top: 15px
    font-size: 12px
    &__item
      display: flex
      justify-content: space-between
      &:nth-child(odd)
        background: #F4F5F5
      &:first-child
        .meters-info,
        .price-info
          font-weight: bold
          font-family: inherit
      .meters-info,
      .price-info
        padding: 5px 10px
        line-height: 20px
        font-family: MuseoSans, Helvetica, Arial, sans-serif


// style from bootstrap for collapse
.collapse
  &:not(.show)
    display: none

.collapsing
  position: relative
  height: 0
  overflow: hidden
  transition: height .35s ease
  -webkit-transition: height .35s ease
  @media (max-width: $mobile)
    transition: height 0s ease
    -webkit-transition: height 0s ease


