@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.pie-chart
  width: 100%

  .recharts-wrapper
    width: 100% !important
    svg
      @include boxSize(100%, auto)
