// MOBILE-FIRST
@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'

.locations-comparison

  h1
    @include fontSLH(30px, 44px)
    margin-bottom: 0

  &__header
    @include displayFlex(center, space-between)
    position: relative
    padding-top: 16px
    padding-bottom: 16px
    svg
      margin-left: 16px
    @media (min-width: $desktop-md)
      padding-top: 24px
      padding-bottom: 24px

  &__action-bar
    @include displayFlex(center, space-between)
    padding-top: 16px
    padding-bottom: 16px

  &__get-info-button
    @include font(14px, $white, 16px, 900)
    padding: 12px 8px
    background: $orange
    border-radius: 8px
    cursor: pointer
    margin-right: 16px
    text-align: center
    &.inactive
      pointer-events: none
      background: #D59F69
    &:hover
      background: $orange-hover
    @media (min-width: $tablet)
      white-space: nowrap
      min-width: 283px
    @media (min-width: $desktop-md)
      @include fontSLH(16px, 20px)
      padding: 10px 16px

  &__action-bar-right
    @include displayFlex(center, flex-end)
    @media (min-width: $desktop-md)
      width: 100%

  &__delete-group
    @include square(40px)
    @include displayFlex(center, center)
    border: 1px solid #EA4335
    border-radius: 8px
    position: relative
    color: #EA4335
    @media (min-width: $desktop-md)
      @include square(auto)
      border: none
      svg
        display: none

  &__download-btn-wrapper
    position: relative

  &__download-btn
    @include square(40px)
    @include displayFlex(flex-start, center)
    border: 1px solid $green
    margin-left: 16px
    border-radius: 8px
    cursor: pointer
    padding-top: 4px
    position: relative
    svg
      fill: $green
    &:hover
      border-color: $green-hover
      svg
        fill: $green-hover
      & ~ .locations-comparison__download-tooltip
        display: flex

  &__download-tooltip
    @include positionAbsolute(51px, 0)
    @include fontSLH(14px, 24px)
    padding: 8px
    background: $white
    border-radius: 4px
    border: 1px solid $green
    white-space: nowrap
    display: none
    &:before, &:after
      @include square(0)
      content: ""
      position: absolute
    &:before
      border-left: 7px solid transparent
      border-right: 7px solid transparent
      top: -12px
      right: 11px
      margin-left: -10px
      border-bottom: 11px solid $green
    &:after
      border-left: 6px solid transparent
      border-right: 6px solid transparent
      top: -10px
      right: 12px
      margin-left: -8px
      border-bottom-color: #fff
      border-bottom-style: solid
      border-bottom-width: 10px

  &__delete-selected-btn
    border-bottom: 1px solid #EA4335
    padding: 0 8px 4px
    @media (min-width: $desktop-md)
      color: #979797
      border-color: #979797
      &:hover
        color: #EA4335
        border-color: #EA4335

  &__action-buttons-wrapper
    @include positionAbsolute(49px, 0)
    flex-direction: column
    display: none
    padding: 8px
    border-radius: 4px
    border: 1px solid
    white-space: nowrap
    z-index: 1
    background: white

    &.open
      display: flex

    &--orange
      border-color: $orange
      &:before
        border-bottom-color: $orange

    &--red
      border-color: #EA4335
      &:before
        border-bottom-color: #EA4335

    div
      @include fontSLH(12px, 24px)
      cursor: pointer
      @media (min-width: $desktop-md)
        @include fontSLH(16px, 20px)
        border: none
        padding: 9px 8px
        min-width: 133px
        margin-right: 16px
        border: 1px solid
        border-radius: 8px
        text-align: center
        &.locations-comparison__delete-all-btn
          margin-right: 0

    &:before, &:after
      content: ""
      width: 0
      height: 0
      position: absolute

    &:before
      border-bottom: 9px solid
      border-left: 5px solid transparent
      border-right: 5px solid transparent
      top: -9px
      right: 14px
      margin-left: -10px

    &:after
      border-bottom-color: #fff
      border-bottom-style: solid
      border-bottom-width: 7px
      border-left: 3.5px solid transparent
      border-right: 3.5px solid transparent
      top: -7px
      right: 15.5px
      margin-left: -8.5px

    @media (min-width: $desktop-md)
      display: flex
      flex-direction: row
      border: none
      position: unset
      padding: 0
      &:before, &:after
        display: none

  &__delete-all-btn
    padding: 4px 8px 0
    @media (min-width: $desktop-md)
      color: #979797
      border-color: #979797
      &:hover
        color: #EA4335
        border-color: #EA4335

  &__search-agent-wrapper
    margin-bottom: 64px

  &__empty-message
    @include displayFlex(center, center)
    @include fontSLH(20px, 24px)
    position: relative
    height: 270px

  &__empty-message-border
    @include positionAbsolute(-16px, 0)
    box-shadow: 0px 6px 11px -7px black
    height: 16px
    width: 100%

  .spinner-wrapper
    margin-bottom: 300px
    padding-top: 50px
    @media (min-width: $tablet)
      padding-top: 100px

  .smoothie-spinner
    @include square(60px)
    margin: 0 auto
    background: $green
    border-radius: 50%
    animation: boing .8s linear infinite
    position: relative
    top: calc(50% - 35px)
    &:before
      @include beforeEmptySquare(56px, $light-green, calc(50% - 28px), $left: calc(50% - 28px))
      display: block
      border-radius: 50%
      animation: boing-inner .8s linear infinite

    &:after
      @include beforeEmptySquare(52px, $white, calc(50% - 26px), $left: calc(50% - 26px))
      display: block
      border-radius: 50%
      animation: boing-inner-2 .8s linear infinite

  @keyframes boing
    0%
      transform: scale(1)
    25%
      transform: scale(.75)
    100%
      transform: scale(1)

  @keyframes boing-inner
    0%
      transform: scale(1)
    25%
      transform: scale(.6)
    100%
      transform: scale(1)

  @keyframes boing-inner-2
    0%
      transform: scale(1)
    10%
      transform: scale(.1)
    100%
      transform: scale(1)

.pdf-modal

  &__wrapper
    @include displayFlex(center)
    flex-direction: column
    padding: 40px 40px 32px

  &__headline
    @include fontSLH(22px, 24px)
    margin-bottom: 8px
    text-align: center

  &__button
    @include font(14px, $white, $fontWeight: 900)
    @include displayFlex(center, $justifyContent: center)
    background: $orange
    padding: 8px 24px
    min-width: 157px
    cursor: pointer
    border-radius: 4px
    text-decoration: none
    margin-top: 32px
    &:hover
      background: $orange-hover
      color: $white

  .file-loader
    width: 208px

  svg
    margin-bottom: 16px

  p
    margin-bottom: 16px
