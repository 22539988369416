// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.closed-locations-banner
  border-radius: 4px
  border: 1px solid $grey-bg
  background: $border-light-grey
  box-shadow: 0px 12px 32px -16px rgba(222, 231, 231, 0.55)
  padding: 50px 16px
  margin: 0 16px
  @media (min-width: $tablet)
    margin: 0
    position: absolute
    width: calc(100% - 8px)
    top: 0
    left: 0
    z-index: 111

  svg
    margin-bottom: 16px
    width: 100%
    min-width: 100%
    height: auto

  &__button
    @include displayFlex(center, center)
    @include fontSLH(16px, 24px)
    font-weight: 900
    padding: 12px 16px
    cursor: pointer
    width: 100%
    border-radius: 4px
    &.similar-locations
      background: $orange
      color: $white
      margin-bottom: 16px
      &:hover
        background: $orange-hover
    &.nearby-locations
      background: #FFFCEA
      color: $orange
      border: 1px solid #FFA300
      &:hover
        border-color: $orange-hover
        color: $orange-hover
        background: #FFF5C5
