// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.space-calculator

  &__toggle
    display: inline-flex
    align-items: center
    justify-content: center
    cursor: pointer
    svg
      margin-right: 8px

  &__toggle-label
    white-space: nowrap

