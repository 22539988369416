@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.advbanner-section
  .advbanner-wrapper
    background: $border-light-grey
    padding: 32px
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column
    border-radius: 4px
    @media (min-width: $tablet)
      flex-direction: row
    .advbanner-button-block
      width: 100%
      @media (min-width: $tablet)
        width: auto
    .advbanner-button
      display: block
      background-color: $green
      border-radius: 4px
      padding: 12px 42px
      font-size: 16px
      font-weight: bold
      color: #fff
      line-height: 24px
      text-decoration: none
      white-space: nowrap
      cursor: pointer
      text-align: center
      min-width: 240px
      &:hover
        background-color: $green-hover

    .advbanner-description-text
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      font-size: 22px
      line-height: 24px
      font-weight: bold
      margin-top: 0
      margin-bottom: 16px
      margin-left: 0
      @media screen and (min-width: $mobile) and (max-width: $desktop-md)
        margin-bottom: 0
      @media (min-width: $desktop-md)
        margin-bottom: 0