// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.list-item-tooltip
  @include font(12px, false, 20px, 100)
  padding: 0 11px
  border-radius: 5px
  background: $black
  text-align: center

.read-more
  cursor: pointer
  color: $green
  border-bottom: 1px solid $pale-pale-green
  font-size: 16px

  &:hover, &:focus
    text-decoration: none
    color: #539533

.map-icon svg
  cursor: pointer
  &:hover
    fill: $green

.description-line
  max-height: 34px
  p
    line-height: 1.25em
    margin: 0
  p>span>span>span
    white-space: nowrap
    display: inline-flex


.provider-logo
  &--list-view
    display: flex
    img
      @include maxSize(85px, 30px)
      margin-right: 16px
  &--grid-view
    display: flex
    img
      @include maxSize(120px, 40px)
    .provider-partner-icon
      svg
        max-height: 32px
        max-width: 32px
        margin-left: 16px

.provider-partner-icon
  svg
    height: 32px !important
    width: 32px !important
    max-height: 25px
    max-width: 25px
    transform: none !important


.nearby-locations-banner
  position: relative
  background: linear-gradient(#77BC1F, #FFA300)
  padding: 3px
  color: $default
  border-radius: 4px
  -webkit-tap-highlight-color: transparent

  &.grid
    height: 100%
    @media (max-width: 767px)
      height: auto

    .nearby-locations-banner
      &__img
        height: auto
        padding: 40px
        img
          @include boxSize(248px, 206px)
        @media (max-width: 767px)
          padding: 64px 0 16px
          img
            @include boxSize(183px, 149px)

      &__description-content
        padding: 0 24px
        @media (max-width: 767px)
          margin-bottom: 29px
        @media (min-width: $desktop-md)
          padding: 0 32px
        @media (min-width: $desktop)
          padding: 0 40px

      &__logo
        position: absolute
        justify-content: center
        align-items: flex-end
        bottom: 24px
        width: 100%
        @media (max-width: 767px)
          position: unset
          margin-bottom: 24px

  &:not(.grid)
    margin-left: 0 !important
    margin-right: 0 !important
    height: 223px
    @media (min-width: $tablet)
      margin: 16px 0
    @media (min-width: $desktop-md)
      margin: 24px 0

  &__background
    position: absolute !important
    top: 3px
    right: 3px
    height: calc(100% - 6px)
    width: calc(100% - 6px) !important
    border-radius: 2px
    background: $white

  &__img,
  &__logo
    @include displayFlex(center, center)

  &__img
    height: 100%
    border-top-left-radius: 2px
    border-bottom-left-radius: 2px
    position: relative

  &__logo
    border-top-right-radius: 2px
    border-bottom-right-radius: 2px
    img
      z-index: 1

  &__description-content
    padding-left: 5px
    position: relative

  &__content-wrapper
    height: 100%
    @media (min-width: $tablet)
      @include displayFlex(flex-start, center, $flexDirection: column)

  &__headline
    @include font(16px, $default, 24px, 900)
    z-index: 1
    margin-bottom: 8px

  &__description
    @include fontSLH(18px, 22px)
    z-index: 1
