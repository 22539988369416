@import "../../../src/stylesheets/variables"

.gray-bg-contracts
  background: #F4F5F5
  .tab-content

    .tabs__wrapper
      max-width: 590px
      position: relative
      margin: 0 auto
      .tab__block
        &__body
          .body-price
            min-height: auto
  .signup-tabs-subheading
    a
      display: block
      text-align: center
      color: #77BC1F
      text-decoration: underline
      font-size: 16px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      margin-bottom: 32px
      &:hover
        color: $green-hover

  .signup-tabs-bottom
    max-width: 590px
    display: flex
    @media (max-width: $mobile)
      text-align: center
      margin-top: 32px
    @media screen and (min-width: $mobile) and (max-width: $screen-md-max)
      margin: 0 auto
    @media (min-width: $desktop)
      margin: 32px auto 0
    p
      margin-bottom: 0
      font-size: 14px


.bottom-tabs-wrapper
  @media (min-width: $desktop)
    display: flex
    justify-content: space-around

  .parking
    @media (max-width: $desktop)
      margin-top: 64px

    .signup-tabs-bottom
      width: 282px

    .tabs__wrapper
      max-width: fit-content
      @media (min-width: $desktop-md)
        min-height: 1041px

    .sub_subheading
      margin-bottom: 32px

    .signup-tabs-subheading
      margin: 0 auto 32px

      @media (min-width: $desktop)
        width: 284px
        margin: 0 auto 68px


  .meeting-room
    @media (min-width: $desktop)
      width: 584px

.mobiles
  @media (min-width: $desktop-md)
    display: none

.desktops
  display: none !important
  @media (min-width: $tablet)
    display: flex !important

.bottom-contracts .slick-slider
  @media (min-width: $tablet)
    display: none !important
