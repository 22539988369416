// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.get-info
  @include displayFlex(flex-start, center)
  position: fixed
  height: 100vh
  width: 100vw
  top: 0
  left: 0
  background: rgba(0,0,0,0.6)
  overflow-y: scroll
  overflow-x: hidden
  z-index: 999
  -ms-overflow-style: none
  scrollbar-width: none
  scroll-behavior: smooth

  @media (min-width: $desktop-md)
    align-items: center
    &.flex-start
      align-items: flex-start
      .get-info__content
        margin-bottom: 32px
    @media (max-height: 600px)
      align-items: flex-start

  &::-webkit-scrollbar
    display: none

  &.success
    @media (min-width: $tablet)
      align-items: center
    .get-info__wrapper
      background: $white
      padding: 24px 16px
      @media (min-width: $tablet)
        width: 597px
        height: auto
        min-height: auto
        border-radius: 4px
        padding: 24px
      @media (min-width: $desktop-md)
        width: 635px
        padding: 32px
      @media (min-width: $desktop)
        width: 784px

  &__wrapper
    min-height: 100vh
    width: 100vw
    position: relative
    background: $white
    @media (min-width: $desktop-md)
      background: transparent
      width: 635px
      height: auto
      min-height: auto
      border-radius: 4px
      margin-top: 32px
    @media (min-width: $desktop)
      width: 784px

  &__mobile-header
    @include displayFlex(center, space-between)
    position: fixed
    height: 55px
    border-bottom: 1px solid $bg-badge-rented-out
    padding: 0 16px
    z-index: 9999
    background: white
    width: 100vw
    top: 0
    left: 0
    @media (min-width: $tablet)
      height: 57px
      padding: 0 24px
    @media (min-width: 769px)
      display: none
    svg
      fill: $default
      &:first-child
        margin-right: 8px
      &:last-child
        margin-left: 8px
    h3
      @include fontSLH(18px, 26px)
      font-family: MuseoSans, san-serif
      font-weight: 300 !important
      margin: 0

  &__content
    padding: 16px
    position: relative
    top: 55px
    background: $white
    @media (min-width: $tablet)
      top: 57px
      padding: 24px
    @media (min-width: $desktop-md)
      top: 0
      border-radius: 4px
      padding: 32px

  &__address-line
    @include fontSLH(12px, 19px)
    font-weight: 300
    margin-bottom: 16px
    display: inline-flex
    position: relative

  &__title
    @include fontSLH(16px, 24px)
    margin-bottom: 8px
    @media (min-width: $tablet)
      @include fontSLH(21px, 32px)
    @media (min-width: $desktop-md)
      font-size: 24px
      margin-bottom: 16px

  &__description
    @include fontSLH(16px, 24px)
    margin-bottom: 16px
    @media (min-width: $tablet)
      margin-bottom: 24px

  &__form
    display: flex
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row
      justify-content: space-between
      & > div
        width: calc(50% - 8px)

  .floating-label
    @include positionAbsolute(15px, $left: 16px)
    font-size: 14px
    line-height: 24px
    color: $black
    transition: all ease-in-out 0.3s
    pointer-events: none
    &.active
      font-size: 12px
      transform: translate(0, -12px)
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      font-weight: 300

  input
    @include fullWidthSize(52px)
    @include font(14px, $black, 16px)
    font-weight: 500
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 0 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    @media (min-width: $tablet)
      height: 54px
    @media (min-width: $desktop-md)
      height: 52px
    &:focus, &.filled
      padding-top: 15px
      & ~ .floating-label
        font-size: 12px
        transform: translate(0, -12px)
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 300
    &.error
      border-color: $light-red
      margin-bottom: 8px
      & ~ .error-tooltip
        display: block
    &::placeholder
      color: $gull-grey-text
    &.sign-up-form__input-error
      border-color: $light-red
    input::-webkit-credentials-auto-fill-button
      visibility: hidden
      pointer-events: none
      position: absolute
      right: 0

  .error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    display: none
    font-weight: 300

  .form-group
    margin-bottom: 16px
    display: flex
    flex-direction: column

  textarea
    @include font(14px, $black, 24px)
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    min-height: 96px
    width: 100%
    @media (min-width: $tablet)
      min-height: 124px
    @media (min-width: $desktop-md)
      min-height: 120px
    &:focus, &.filled
      padding-top: 15px
      & ~ .floating-label
        display: none
    & ~ .floating-label
      min-width: 250px

  .data-protection
    &__wrapper
      @media (min-width: $tablet)
        display: none
      &.mobile-hidden
        display: none
        @media (min-width: $tablet)
          display: block
    &__toggle
      @include displayFlex(center)
      flex-wrap: nowrap
      cursor: pointer
      span
        @include fontSLH(12px, 19px)
        font-weight: 300
        display: block
        margin-right: 2px
        color: $green
        text-decoration: underline
    &__info-icon
      margin-right: 4px
      fill: $green
    &__show-info
      font-size: 16px
      color: $default
    &__body
      height: 0
      min-height: 0
      overflow: hidden
      &.open
        height: auto
        min-height: 50px
        margin-top: 24px
        overflow: visible
    &__headline
      @include fontSLH(16px, 24px)
      font-weight: bold
      margin-bottom: 8px
    &__content
      @include fontSLH(14px, 21px)
      p
        margin-bottom: 20px
        @include fontSLH(14px, 21px)
      ul
        padding-left: 36px
    &__policy-link
      font-family: MuseoSans, san-serif
      a
        @include fontSLH(14px, 21px)
        font-family: MuseoSans, san-serif
      p
        @include fontSLH(14px, 21px)
        margin-bottom: 0

  .form-set-info-submit
    @include fontSLH(16px, 24px)
    text-transform: none
    height: 48px
    border-radius: 4px
    margin-bottom: 16px
    width: 100%
    &__wrapper
      position: relative

  &__success-wrapper
    h2
      @include fontSLH(21px, 32px)
      margin-bottom: 8px
      @media (min-width: $desktop-md)
        font-size: 24px
        margin-bottom: 16px
    p
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, san-serif
      margin: 0

  &__close
    @include closeBtnRight(16px, 16px)
    @media (min-width: $tablet)
      top: 24px
      right: 24px
    @media (min-width: $desktop-md)
      right: 15px
      &--success
        right: 32px
    @media (min-width: $desktop)
      right: 24px
      &--success
        right: 24px
    &.mobile-hidden
      display: none
      @media (min-width: 769px)
        display: block

  &__success-address-line
    @include displayFlex(flex-start)
    padding-right: 30px
    margin-bottom: 16px
    span
      margin-bottom: 0
    svg
      fill: $green
      margin-right: 8px

  &__address-line-link-wrapper
    margin-left: 5px
    &.open
      .get-info__address-line-link
        &:before, &:after
          display: block
      .get-info__locations-list
        display: block
    @media (min-width: $tablet)
      position: relative

  &__address-line-link
    color: $green
    text-decoration: underline
    cursor: pointer
    position: relative

    &:before, &:after
      content: ""
      width: 0
      height: 0
      position: absolute
      display: none

    &:before
      border-bottom: 9px solid
      border-left: 5px solid transparent
      border-right: 5px solid transparent
      top: 17px
      right: calc(50% - 5px)
      margin-left: -10px

    &:after
      border-bottom-color: #fff
      border-bottom-style: solid
      border-bottom-width: 7px
      border-left: 3.5px solid transparent
      border-right: 3.5px solid transparent
      top: 19px
      right: calc(50% - 3.5px)
      margin-left: -8.5px
      z-index: 2

  &__locations-list
    @include positionAbsolute(25px, $left: 0)
    padding: 16px 16px 8px
    border: 1px solid $green
    border-radius: 8px
    text-decoration: none
    width: calc(100vw - 32px)
    z-index: 1
    background: $white
    display: none
    a
      @include font(12px, $default, 19px)
      margin-bottom: 8px
      text-decoration: none
      display: block
      &:hover
        color: $green-hover
    @media (min-width: $tablet)
      width: auto
      //max-width: 400px
      a
        white-space: nowrap

  //.jeudan-policy-checkbox
  //  width: unset !important
  //  position: relative
  //  font-size: 9px
  //  line-height: 14px
  //  margin-right: 0 !important
  //
  //  &__wrapper
  //    display: flex
  //    &:hover
  //      .jeudan-policy-checkbox__validation-message
  //        visibility: visible
  //    &:before
  //      visibility: visible
  //      @include beforeEmptySquare(16px, $white, 0)
  //      border: 1px solid $inactive
  //      border-radius: 2px
  //      display: block
  //      left: 0
  //      pointer-events: none
  //      z-index: 1
  //      top: 2px
  //
  //    svg
  //      position: absolute
  //      fill: $white
  //      z-index: 1
  //      top: 2px
  //      left: 1px
  //      pointer-events: none
  //
  //    &.checked:before
  //      content: ''
  //      background: $green
  //      border-color: $green
  //    &.error:before
  //      border-color: $light-red
  //
  //  input
  //    @include positionAbsolute(2px, $left: 0)
  //    font-size: 20px
  //    margin-right: 5px
  //    cursor: pointer
  //    width: 18px !important
  //    height: 13px
  //    top: 3px
  //    opacity: 0
  //
  //  &__label
  //    @include font(12px, $default, 19px)
  //    margin-left: 22px
  //    font-weight: 300
  //    &.bold
  //      font-weight: 500
  //
  //  &__multi-tooltip
  //    @include font(11px, $default, 19px, 300)
  //    margin-bottom: 8px
  //    margin-top: -8px
  //
  //  &__label-read-more-link
  //    margin-left: 5px
  //    color: $green
  //    text-transform: lowercase
  //    cursor: pointer
  //    text-decoration: underline
  //
  //  &__validation-message
  //    @include font(12px, $light-red, 19px)
  //    font-family: MuseoSans, san-serif
  //    display: none
  //    margin-top: 8px
  //    &.visible
  //      display: block

  .not-showing
    display: none

.ReactModalPortal
  .ReactModal__Overlay
    .modal-header
      h2
        font-size: 16px
        line-height: 24px
    .modal-footer
      .close-modal-button
        color: #333
        background-color: $white
        border-color: #ccc
        padding: 6px 12px
        border-radius: 4px

@keyframes rotating
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)


