// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.pulsing-loader-wrapper
  .pulsing-loader
    @include square(60px)
    margin: 0 auto
    background: $green
    border-radius: 50%
    animation: boing .8s linear infinite
    position: relative
    top: calc(50% - 35px)
    &:before
      @include beforeEmptySquare(56px, $light-green, calc(50% - 28px), $left: calc(50% - 28px))
      display: block
      border-radius: 50%
      animation: boing-inner .8s linear infinite
    &:after
      @include beforeEmptySquare(52px, $white, calc(50% - 26px), $left: calc(50% - 26px))
      display: block
      border-radius: 50%
      animation: boing-inner-2 .8s linear infinite

  @keyframes boing
    0%
      transform: scale(1)
    25%
      transform: scale(.75)
    100%
      transform: scale(1)

  @keyframes boing-inner
    0%
      transform: scale(1)
    25%
      transform: scale(.6)
    100%
      transform: scale(1)

  @keyframes boing-inner-2
    0%
      transform: scale(1)
    10%
      transform: scale(.1)
    100%
      transform: scale(1)
