@import '../../../src/stylesheets/variables'


.dv-gallery
  margin-bottom: 24px
  @media (max-width: 767px)
    padding-left: 0 !important
    padding-right: 0 !important
  @media (min-width: $tablet)
    margin-bottom: 32px

.asset-container
  padding-left: 0 !important
  padding-right: 0 !important
  height: 235px
  position: relative
  max-width: 100%
  overflow: hidden
  @media (min-width: $tablet)
    height: 318px
    border-radius: 4px
    margin-bottom: 8px
    .map-container,
    .map-element
      height: 100%
  @media (min-width: $desktop-md)
    height: 441px
  &.virtual-tour-box
    @media (min-width: $desktop-md)
      min-height: 441px
      height: 441px
  .virtual-tour__frame-wrapper
    height: 235px
    @media (min-width: $tablet)
      height: 318px
    @media (min-width: $desktop-md)
      height: 441px
  iframe
    background: $border-light-grey
