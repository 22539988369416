// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.benefits
  margin-top: 64px
  @media (min-width: $desktop-md)
    margin-top: 96px
    margin-bottom: 56px

  &__wrapper
    display: none
    @media (min-width: $tablet)
      display: flex

  &__headline
    text-align: center
    margin-bottom: 32px
    line-height: 28px
    @media (min-width: $tablet)
      @include fontSLH(24px, 32px)

  &__item
    margin-bottom: 40px

  &__item-headline
    @include fontSLH(24px, 32px)
    @include displayFlex(flex-start)
    position: relative
    font-weight: 300 !important
    margin-bottom: 16px
    img
      margin-right: 8px
      position: relative
      top: 6px

  &__item-body
    margin-bottom: 0
    a
      @include font(16px, $green, 24px)
      font-family: MuseoSans, sans-serif
      margin-left: 4px
      margin-top: 0
      display: inline-block
      &:hover
        color: $green-hover
