// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.dv-header
  position: relative
  @media (min-width: $tablet)
    padding-right: 16px
  @media (min-width: $desktop-md)
    padding-right: 32px

  &__info-tooltip
    margin-left: 4px
    cursor: pointer
    svg
      fill: #7F7F7F
      margin-right: 0 !important
      position: relative
      bottom: 1px
    &:hover svg
      fill: $green

  .info-tooltip
    padding: 4px 8px
    font-size: 12px
    line-height: 15px
    font-weight: 300
    max-width: 130px
    text-align: left
    white-space: normal

  &__scraped-info-block
    border: 1px solid rgba(219,140,0,0.42)
    border-radius: 4px
    background-color: rgba(255,163,0,0.11)
    padding: 16px
    margin-bottom: 32px
    margin-top: 10px

  &__scraped-info-wrapper
    @media (min-width: $mobile) and (max-width: $tablet)
      padding-right: 16px !important

  &__scraped-headline
    @include displayFlex(center)
    font-weight: 900
    margin-bottom: 8px
    svg
      margin-right: 8px

  &__scraped-content
    margin-bottom: 0
    @media (min-width: $desktop-md)
      padding-left: 30px
    .read-more
      border-bottom: none
      &:hover
        text-decoration: underline

  &__top-wrapper
    @include displayFlex(center, space-between)
    margin-bottom: 8px

  &__action-icons-wrapper
    @include displayFlex(center)
    .compare-icon
      margin-right: 16px
    .favourite-icon__label
      margin-left: 0

  &__location-details-wrapper
    margin-bottom: 24px
    @media (min-width: $tablet)
      margin-bottom: 0

  &__location-kinds
    display: flex
    flex-wrap: wrap
    span
      @include font(16px, $dark-borders, 24px)
      white-space: nowrap
      &:after
        content: '·'
        color: $dark-borders
        margin: 0 8px
      &:last-child:after
        display: none

  &__show-all-kinds-link:hover
    @include font(16px, $green, 24px)
    text-decoration: underline
    cursor: pointer

  &__location-title
    @include fontSLH(24px, 30px)
    margin-bottom: 16px
    &.extra-indentation
      margin-bottom: 32px
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)
      margin-bottom: 32px

  &__economy-section-link
    @include font(16px, $green, 24px)
    text-decoration: underline
    cursor: pointer
    margin-top: 4px
    &:hover
      color: $green-hover

  &__location-details-row
    @include displayFlex(flex-start)
    flex-direction: row
    flex-wrap: wrap
    .dv-header__location-details-cell
      min-width: 50%
      padding-right: 16px
      @media (min-width: $desktop-md)
        min-width: 33.3%

  &__location-details-separator
    height: 1px
    background-color: #E0E2E2
    margin-bottom: 16px

  &__location-details-cell
    @include fontSLH(16px, 24px)
    margin-bottom: 16px
    @media (min-width: $tablet)
      margin-top: 4px

  &__cell-label
    font-weight: 300
    font-size: 14px
    @media (min-width: $desktop-md)
      font-size: 16px

  &__cell-value
    display: flex
    align-items: center
    white-space: nowrap
    svg
      margin-right: 8px
    span
      @include fontSLH(16px, 24px)
      font-weight: 900

  &__cafe-disposal-block
    @include displayFlex(flex-start, false, column)
    flex-wrap: wrap
    margin-bottom: 24px
    @media (min-width: $tablet)
      flex-direction: row
      align-items: center
      margin-bottom: 8px
      padding-right: 16px !important
    @media (min-width: $desktop-md)
      margin-top: 0
      margin-bottom: 0
    &>div
      margin-bottom: 8px
      &:last-child
        margin-bottom: 0
      @media (min-width: $tablet)
        margin-right: 33px
        &:last-child
          margin-bottom: 8px
    .separator
      height: 1px
      background-color: #E0E2E2
      margin-bottom: 12px
      width: 100%
      margin-top: -12px
      margin-right: 0 !important
      @media (min-width: $tablet)
        margin-top: 0
        margin-bottom: 16px

  &__bottom-border
    @media (max-width: 991px)
      display: none
    @media (min-width: $desktop-md)
      &.indentation-mobile
        margin-top: 8px

  &__suitable-for
    @include displayFlex(center)

  &__suitable-for-icon
    margin-right: 8px
    margin-bottom: 0
    fill: #0FADA2

  &__suitable-for-label
    @include fontSLH(14px, 24px)
    @media (min-width: $desktop-md)
      font-size: 16px

  &__verified-lease
    margin-bottom: 16px
    @include displayFlex(center)
    svg
      margin-right: 6px
    span
      @include fontSLH(16px, 22px)
      display: block

  .notification
    top: auto
    bottom: 100px
    left: 50%
    width: 320px
    z-index: 700
    transform: translate(-50%, 0)
    &.with-policy-block
      @media (max-width: $screen-xs-max)
        bottom: 175px

  .space-calculator
    svg
      display: none
    &__toggle-label
      @include fontSLH(16px, 24px)
      color: $green
      text-decoration: underline
      &:hover
        color: $green-hover



@keyframes bounce
  0%
    transform: scale(1.1)
    opacity: 1
  50%
    transform: scale(1.6)
    opacity: .7
  60%
    transform: scale(0.6)
    opacity: 1
  80%
    transform: scale(0.95)
  100%
    transform: scale(0.85)
