// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.location-unavailable
  color: $default
  &__wrapper
    @include displayFlex(center, false, column)
    margin: 17px 0 64px
    @media (min-width: $tablet)
      margin: 41px 0 64px
      flex-direction: row

  &__img-block
    @include displayFlex(center)
    margin-right: 0
    margin-bottom: 24px
    @media (min-width: $tablet)
      margin-bottom: 0

  &__img
    width: 70px
    height: auto
    margin-right: 16px
    @media (min-width: $tablet)
      width: 144px
      margin-right: 0
    @media (min-width: $desktop-md)
      width: 184px
    @media (min-width: $desktop-md)
      width: 100%

  &__address
    @include fontSLH(16px, 24px)
    display: none
    font-weight: 100 !important
    margin-bottom: 0
    @media (min-width: $tablet)
      margin-bottom: 16px
      display: block
    @media (min-width: $desktop-md)
      @include fontSLH(16px, 32px)
    &--mobile
      display: block
      @media (min-width: $tablet)
        display: none

  &__title
    @include fontSLH(24px, 32px)
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)

  &__subtitle
    @include fontSLH(16px, 24px)
    margin: 0

  &__view-more-link
    text-transform: lowercase
    margin-left: 5px
    text-decoration: underline

  .similar-locations
    padding-top: 0
    margin-bottom: 64px !important
