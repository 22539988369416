@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.social-media__wrapper
  text-align: right
  background: $white
  width: 100%
  padding-top: 10px
  @media only screen and (max-width: $screen-sm)
    padding-right: 10px
    padding-top: 10px
  span
    @include square(20px)
    cursor: pointer
    display: inline-block
    vertical-align: middle
    text-align: center
    border-radius: 3px
    position: relative
    &.text
      font-size: 14px
      cursor: default
      user-select: none
      margin-top: 1px
      display: inline
    svg
      @include square(1em)
      @include positionAbsolute(50%, $left: 50%)
      display: inline-block
      vertical-align: middle
      pointer-events: none
      font-size: 12px
      margin-left: -6px
      margin-top: -6px
    &:not(:last-child)
      margin-right: 10px
    &.facebook
      background: $facebook
    &.twitter
      background: $twitter
    &.linkedin
      background: $linkedin
    &.google
      background: $google

  // SocialImg ===> JSS
.social-media__img
  @include square(20px)
  display: inline-block
  margin-right: 10px
