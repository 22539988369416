// MOBILE-FIRST
@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'


.comparison-table
  padding-top: 32px
  padding-bottom: 64px
  @media (min-width: $desktop-md)
    padding-top: 40px

  tr, td
    min-height: 64px

  tr
    display: flex
    align-items: center
    &:nth-child(even)
      background: #F7F7F7

  td
    @include displayFlex(center)
    @include fontSLH(12px, 24px)
    padding: 8px 8px 8px 0
    &:first-child
      padding: 8px
      @media (min-width: $desktop-md)
        padding: 8px 16px
    @media (min-width: $desktop-md)
      padding-right: 16px
      font-size: 13px
    @media (min-width: $desktop)
      font-size: 16px

  &__ordered-banner
    @include positionAbsolute(0, 0)
    @include square(100%)
    @include font(16px, $white, 21px, 900)
    @include displayFlex(center, center)
    padding: 16px
    background: rgba(255, 163, 0, 0.7)

  &__logo-header
    padding: 8px
    width: 100%
    height: auto
    @media (min-width: $desktop-md)
      padding: 0
    @media (min-width: $desktop)
      padding: 8px

  &__img-cell
    position: relative
    padding: 8px 0
    text-align: center
    user-select: none
    width: 100%
    img
      width: 100%
      height: auto
      &.rented-out
        filter: grayscale(100%)
    a
      @include fontSLH(12px, 24px)
      display: inline-flex
      text-decoration: underline
    @media (min-width: $desktop)
      padding: 0

  &__rented-out-badge, &__crawled-badge
    @include positionAbsolute($right: 0, $bottom: 0)
    @include fontSLH(14px, 31px)
    @include displayFlex(center, center)
    @include boxSize(100%, 31px)
    font-weight: 900

  &__rented-out-badge
    background-color: #E0E2E2

  &__crawled-badge
    font-size: 13px
    background-color: $bg-badge-parsed
    color: $white

  &__img-wrapper
    position: relative
    width: 100%
    min-width: 100%
    min-height: 30px

  &__check-wrapper
    @include closeBtnRight(16px, 8px)
    @include square(24px)
    @include displayFlex(center, center)
    background: white
    border-radius: 4px
    border: 1px $default solid
    z-index: 1
    @media (min-width: $desktop)
      top: 8px

  &__address-cell
    font-weight: 900

  &__energy-rating-cell
    @include displayFlex(center)
    svg
      margin-right: 8px
      min-width: 18px

  &__facilities-list-item
    @include displayFlex(flex-start)
    margin-bottom: 8px
    &:last-child
      margin-bottom: 0

  &__facilities-list-item-icon
    @include square(16px)
    margin-right: 4px
    background-repeat: no-repeat
    background-size: contain
    position: relative
    top: 4px

  &__section-trigger
    @include displayFlex(center)
    @include fontSLH(12px, 24px)
    font-weight: 900
    margin-top: 24px
    cursor: pointer
    svg
      margin-left: 4px
    @media (min-width: $desktop-md)
      font-size: 13px
    @media (min-width: $desktop)
      font-size: 16px

  &__dynamic-section-wrapper
    overflow: hidden
    min-height: auto
    min-height: 61px
    visibility: visible
    &.close
      min-height: 0
      height: 0
      padding: 0 0 0
      visibility: hidden
      animation: .25s ease 0s close
    &.open
      height: auto
      min-height: 61px
      visibility: visible
      animation: .25s ease 0s open

  #facilitiesTableWrapper
    tr
      align-items: flex-start
    td
      padding: 16px 8px 16px 0
    .single, .empty
      position: relative
      top: -7px

@keyframes open
  from
    min-height: 0
    height: 0
    visibility: hidden
  to
    height: auto
    min-height: 61px
    visibility: visible

@keyframes close
  from
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 29px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
