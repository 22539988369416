@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.terms-of-service
  overflow-x: hidden
  overflow-y: auto
  color: $default
  align-items: flex-start !important

  em
    @include fontSLH(16px, 24px)
    padding-bottom: 16px
    display: block
    font-style: normal
    text-align: left
    @media (min-width: $tablet)
      padding-bottom: 24px

  p em
    padding-bottom: 0

  h3
    @include fontSLH(16px, 24px)
    margin-bottom: 16px

  p
    @include fontSLH(16px, 24px)
    margin-bottom: 0

  ol
    margin: 0
    padding-left: 16px
    & > li
      font-weight: 900
    & > li > ol > li
      font-weight: 500
    & > li > p
      margin-bottom: 8px
    & > li > ol
      padding-bottom: 16px
      margin: 0
      @media (min-width: $tablet)
        padding-bottom: 24px
    & > li > ul
      list-style: disc

  hr
    display: none

  li > ul, li > ol
    margin-top: 8px
    margin-bottom: 8px
    padding-left: 16px
  li > ul
    & ~ li
      color: green

  .sign-up-modal__wrapper
    position: relative
    margin: 70px 0 10px
    background-color: transparent
    box-shadow: none
    border: none
    height: auto
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    @media (min-width: $tablet)
      margin: 90px 0 90px

  &__content
    background-color: $white
    border-radius: 4px
    padding: 24px 16px 8px
    position: relative
    @media (min-width: $tablet)
      padding: 24px 24px 0
    @media (min-width: $desktop-md)
      padding: 32px 32px 8px
    ul
      padding-left: 32px
      margin-bottom: 16px
      @media (min-width: $tablet)
        margin-bottom: 24px
      li
        @include fontSLH(16px, 24px)
        font-family: MuseoSans, san-serif

  &__header
    position: relative
    margin: 0 30px 0 0
    padding-bottom: 24px
    @media (min-width: $desktop-md)
      padding-bottom: 32px
    h2
      @include fontSLH(21px, 32px)
      margin-bottom: 0
      @media (min-width: $desktop-md)
        font-size: 24px

  &__close-btn
    @include closeBtnRight(16px, 16px)
    @media (min-width: $tablet)
      top: 24px
      right: 24px
    @media (min-width: $desktop-md)
      top: 32px
      right: 32px

  &__body
    & > ol > li
      @include fontSLH(16px, 24px)
      ol li
        @include fontSLH(16px, 24px)

    & > ol > li > p
      @include fontSLH(16px, 24px)

  &__footer
    padding: 15px
    display: flex
    justify-content: flex-end
    border-top: 1px solid #e5e5e5

  &__footer-close-btn
    @include font(14px, #333, 1.428571429, normal)
    display: inline-block
    margin-bottom: 0
    text-align: center
    vertical-align: middle
    cursor: pointer
    background-image: none
    border: 1px solid #ccc
    white-space: nowrap
    padding: 6px 12px
    border-radius: 4px
    user-select: none
    background-color: $white
    &:hover
      color: #333
      background-color: #e6e6e6
      border-color: #adadad
