// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.fixed-nav
  @include displayFlex(flex-start)
  @include boxSize(100vw, 48px)
  position: fixed
  background: $white
  z-index: 998
  box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
  left: 0
  transform: translateY(-100%)
  transition: transform ease .2s
  &.active
    transform: translateY(0)
  @media (min-width: $tablet)
    height: 56px

  &__links-list
    list-style: none
    display: flex
    margin: 0
    padding: 0
    overflow-x: auto
    scroll-snap-type: x mandatory
    height: 100%
    scroll-behavior: smooth
    -webkit-overflow-scrolling: touch
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none

  &__link
    @include displayFlex(center)
    margin-right: 16px
    flex-shrink: 0
    height: 100%
    @media (min-width: $mobile)
      margin-right: 24px
    @media (min-width: $desktop-md)
      margin-right: 40px

    span
      @include font(14px, $dark-borders, false, bold)
      white-space: nowrap
      cursor: pointer
    &.active span
      color: $green

  &__arrow-left,
  &__arrow-right
    @include positionAbsolute(0)
    height: 48px
    display: flex
    align-items: center
    background: white
    opacity: 0.87
    width: 24px
    cursor: pointer
    svg
      @include svgSquare(34.4px, $dark-borders)
      position: absolute

  &__arrow-left
    left: 0
    svg
      left: -5px

  &__arrow-right
    right: 0
    svg
      right: -5px

  &__links-wrapper
    height: 40px
    overflow: hidden
    position: relative

  .order-form-scroll-btn
    margin-left: 16px
    @media (max-width: 767px)
      display: none

  .container
    display: flex
    align-items: center
    justify-content: space-between

  &.with-button
    @media (min-width: $tablet)
      .container
        padding-top: 8px
      .fixed-nav__arrow-left,
      .fixed-nav__arrow-right
        top: -6px


@media (hover: hover)
  .fixed-nav__link:hover
    span
      color: $green
