.body-contact
  min-height: 114px
  strong
    display: block
    font-size: 24px
    margin-bottom: 4px
    span
      font-size: 24px
.info-wrapper
  margin-top: 20px
  padding-left: 45px
  ul
    padding: 0
    font-size: 14px
    margin-bottom: 0
    li
      position: relative
      display: block
      text-align: left
      &:not(:last-child)
        margin-bottom: 16px
      &.tel,
      &.email
        span
          font-family: MuseoSans, Helvetica, Arial, sans-serif
          font-size: 16px
        a
          background: none
          color: #000
          text-decoration: none
          &:hover
            color: #539533
            text-decoration: underline
      img
        height: 18px
        width: 18px
        margin-right: 10px
        display: inline-block