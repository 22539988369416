@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.signup-top-banner
  position: relative
  background: #f6f6f6
  &::before
    content: ""
    position: absolute
    display: block
    top: 100%
    left: calc(50% - 22px)
    border-right: 22px solid #fff
    border-top: 22px solid #f6f6f6
    border-left: 22px solid #fff

  .container
    display: flex
    align-items: center
    position: relative
    -ms-flex-direction: column
    -webkit-box-orient: vertical
    flex-direction: column
    @media (min-width: $tablet)
      -ms-flex-direction: row
      -webkit-box-orient: horizontal
      flex-direction: row

  &__prices-button
    @include displayFlex(center, space-between)
    width: 100%
    height: 48px
    font-size: 16px
    border-radius: 6px
    border: 1px solid $orange
    position: relative
    font-weight: bold
    padding: 12px 24px
    color: $orange
    background: $white
    margin-top: 16px
    &:hover,
    &:active,
    &:focus
      border-color: $orange-hover
      color: $orange-hover
      svg
        fill: $orange-hover
    @media (min-width: $tablet)
      width: 280px
    @media only screen and (orientation: landscape)
      width: 280px
    svg
      fill: $orange
      min-width: 28px

  .signup-top-content
    padding: 56px 0
    flex: 1
    width: 100%
    font-size: 28px
    text-align: left
    @media (max-width: $desktop-md)
      padding: 32px 0
    h1
      margin-top: 0
      font-weight: 900
      margin-bottom: 24px
      font-size: 28px
      line-height: 32px
      color: $default
      width: 100%
      @media (min-width: $tablet)
        font-size: 24px
        line-height: 32px
      @media (min-width: $desktop)
        font-size: 30px
        line-height: 40px

    ul
      padding-left: 0
      text-align-last: left
      margin-bottom: 24px
      width: 100%
      @media (min-width: $tablet)
        margin-bottom: 24px
        max-width: 96%
      @media (min-width: $desktop-md)
        margin-bottom: 32px
      li
        list-style: none
        position: relative
        padding-left: 32px
        color: #000
        font-size: 16px
        line-height: 24px
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        &:not(:last-child)
          margin-bottom: 16px
          @media (max-width: $desktop-md)
            margin-bottom: 8px
        &:before
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='22px' viewBox='0 0 24 24' width='22px' fill='%2377bc1f'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z'/%3E%3C/svg%3E")
          position: absolute
          top: 0
          left: 0

    .main-button
      @include displayFlex(center, space-between)
      width: 100%
      height: 48px
      font-size: 16px
      background-color: #ffa300
      border-radius: 6px
      border: none
      position: relative
      font-weight: bold
      padding: 12px 24px
      color: white
      text-decoration: none
      &:hover,
      &:active,
      &:focus
        color: #fff
      @media (min-width: $tablet)
        width: 280px
      @media only screen and (orientation: landscape)
        width: 280px
      svg
        fill: $white
        min-width: 28px

  .signup-image-block
    position: relative
    top: 0
    max-width: 292px
    margin: 0 auto 30px auto
    right: auto
    width: 100%
    @media (min-width: $tablet)
      margin: 0
    @media (min-width: $desktop-md)
      max-width: 315px
    @media (min-width: $desktop)
      max-width: 382px
      margin: 0
    img
      flex: 1
      width: 100%
