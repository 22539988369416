@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.dropdown-with-search
  position: relative
  padding-bottom: 10px
  &__wrapper
    @include displayFlex(center, space-between)
    @include fullWidthSize(52px)
    @include font(14px, $black, 1.428571429)
    border: 1px solid $bg-badge-rented-out
    background-color: $white
    border-radius: 4px
    padding: 6px 12px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    position: relative
    cursor: pointer

  &__input-toggle
    @include positionAbsolute(0, 0)
    @include displayFlex(center, center)
    @include square(51px)
    color: $black

  &__select-wrapper
    @include positionAbsolute(52px)
    background: $white
    border-radius: 4px
    border: 1px solid #ced4da
    width: 100%
    z-index: 11
    input
      height: 30px !important

  &__search-icon
    @include square(18px)
    @include positionAbsolute(23px, 35px)
    display: block
    background-image: url("../../src/images/icons/search.svg")
    z-index: 9
    background-size: cover

  &__label
    font-size: 14px
    color: $black
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    transition-duration: 0.5s

  &__input-field
    display: flex
    flex-direction: column
    width: 100%
    &.active
      .dropdown-with-search__label
        font-size: 12px
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 300
      .dropdown-with-search__selected-option
        font-family: MuseoSans, Helvetica, Arial, sans-serif
