@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

$namespace: '.language-switcher'

#{$namespace}
  position: relative
  z-index: 1031
  @media only screen and (max-width: $screen-lg-md-max)
    border-top: 1px solid $border-form-input

#{$namespace}__language-selected
  @include displayFlex(center)
  height: 100%
  padding: 15px 15px
  font-size: 12px
  color: $default
  cursor: pointer
  text-transform: uppercase
  background-color: $white
  border-right: 1px solid $borders
  border-left: 1px solid $borders
  &:hover
    text-decoration: none
    color: $default
  img
    height: 19px
    @media only screen and (max-width: $screen-lg-md-max)
      margin-right: 15px
  @media only screen and (max-width: $screen-lg-md)
    min-height: 55px
    padding: 0 15px
    #{$namespace}__language-selected
      &::after
        @include positionAbsolute(18px, 10px)
        content: ''
        margin: 7px 12px 0 0
        border-color: $inactive transparent transparent transparent
        border-style: solid
        border-width: 5px 4px 0 4px


#{$namespace}__option-list
  @media only screen and (min-width: $screen-s-xs)
    position: absolute
    top: 50px
    width: 100%

#{$namespace}__option
  @include displayFlex(center)
  transition: 0.1s all linear
  padding: 15px
  font-size: 12px
  color: $default
  cursor: pointer
  text-transform: uppercase
  background-color: $white
  border-right: 1px solid $borders
  border-left: 1px solid $borders
  border-bottom: 1px solid $borders
  &:hover
    color: $default
    text-decoration: none
    background: $borders
  img
    height: 19px
    margin-right: 8px
  @media only screen and (max-width: $screen-lg-md)
    min-height: 55px
    padding: 0 15px

#{$namespace}__choose-language, #{$namespace}__locale-name
  @media (min-width: $desktop-md)
    display: none
