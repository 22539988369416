// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.payment-teasers
  margin-top: 64px

  &__headline, &__content-text
    text-align: center

  &__headline
    margin-bottom: 16px

  &__content-text
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-bottom: 32px

  &__teaser-block-wrapper
    margin-bottom: 40px
    @media (min-width: $tablet)
      margin-bottom: 0

  &__teaser-block
    @include displayFlex(false, space-between, column)
    min-height: 100%
    height: 100%
    overflow: hidden
    border-radius: 6px
    padding: 24px 16px
    position: relative
    transition: transform 0.3s ease-in-out
    background-size: cover
    &--left
      background-image: url("../../../src/images/list_office/category_left.jpg")
      background-position: -48px
      @media (min-width: $desktop-md)
        background-position: 70px
      @media (min-width: $desktop)
        background-position: 109px
    &--right
      background-image: url("../../../src/images/list_office/category_right.jpg")
      background-position: -455px
      position: relative
      @media (min-width: $desktop-md)
        background-position: -272px
      @media (min-width: $desktop)
        background-position: 298px
    @media (min-width: $tablet)
      padding: 24px
    @media (min-width: $desktop-md)
      padding: 32px

  &__teaser-content-background
    @include positionAbsolute(0, false, 0, 0)
    background: $border-light-grey
    display: block
    z-index: 0
    width: 64%
    @media (min-width: $tablet)
      width: 66%
    &:after
      @include beforeEmptySquare(100%, $border-light-grey, 0, -50px, 0)
      display: block
      transform: skew(12deg)
      @media (min-width: $tablet)
        right: -45px
      @media (min-width: $desktop-md)
        right: -50px
        transform: skew(17deg)
      @media (min-width: $desktop)
        right: -61px
        transform: skew(24deg)

  &__teaser-headline
    color: $default
    margin-bottom: 16px
    position: relative
    z-index: 1
    max-width: 67%

  &__teaser-content
    flex-grow: 1
    margin-bottom: 24px
    max-width: 67%
    color: $default
    z-index: 1
    @media (min-width: $desktop-md)
      margin-bottom: 16px

  &__teaser-button
    @include font(16px, $white, 48px, bold)
    @include boxSize(263px, 48px)
    text-transform: capitalize
    position: relative
    border-radius: 6px
    background: $green
    z-index: 1
    text-align: center
    max-width: 226px
    text-decoration: none
    &:hover
      text-decoration: none
      background: $green-hover
      transition: background ease 0.3s
      color: $white
    @media (min-width: $tablet)
      max-width: 263px

  &__splash
    @include square(110px)
    @include displayFlex(center, center)
    @include positionAbsolute(-15px, 6px)
    background-color: rgba(255,163,0,0.3)
    border-radius: 50%
    z-index: 12
    @media (min-width: $tablet)
      top: -16px
      right: 8px
    @media (min-width: $desktop-md)
      top: -32px

  &__splash-wrapper
    @include square(92px)
    @include displayFlex(center, center)
    border-radius: 50%
    background-color: $orange
    p
      @include font(12px, $white, 17px)
      transform: rotate(15deg)
      text-align: center
      width: 100%
      margin-bottom: 0
      b
        font-family: MuseoSans, sans-serif
        font-weight: 900
