@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.contact-info-block
  margin-top: 48px
  &--provider-badge-only
    margin-top: 32px
  p
    @include font(18px, $default, 26px, bold)

.contact-info-content
  display: flex
  min-width: 250px
  justify-content: flex-start
  line-height: 18px
  &:not(:last-child)
    margin-bottom: 16px
    padding-bottom: 16px
    border-bottom: 1px solid rgba(0, 0, 0, 0.10)

  &__logo-wrapper,
  &__photo-wrapper
    margin-right: 16px
    min-width: 56px

  &__provider-info.with-indentation
    margin-left: 72px

  &__photo-wrapper img
    border-radius: 50%

  &__title
    @include fontSLH(16px, 24px)
    font-weight: bold

  &__name
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    margin: 4px 0

  img
    width: 56px

  a
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    text-decoration: underline

  &__info-links
    @include displayFlex(center)
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    cursor: pointer
    &:hover
      text-decoration: underline
    &:first-child
      margin-bottom: 4px
    svg
      margin-right: 8px

  &__info-phone-link
    display: inline-flex
    color: $default
    text-decoration: none !important
    &:hover
      color: $default
      text-decoration: underline

  &__phone-number
    &:hover
      text-decoration: none
