@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.image-gallery
  height: 235px
  position: relative
  overflow: hidden
  @media (min-width: $tablet)
    border-radius: 4px
    height: 318px
  @media (min-width: $desktop-md)
    height: 441px

  &__img-wrapper
    height: 100%
    width: 100%
    position: relative
    img
      height: 100%
      width: 100%
      object-fit: cover
      object-position: center
      &:not(.rented-out, .no-fullscreen)
        cursor: pointer
        &:hover
          filter: brightness(80%)
      &.rented-out
        filter: grayscale(1)

  &__right-images-row
    height: 100%

  &__right-images-col
    height: calc(50% - 4px)
    &:first-child
      margin-bottom: 8px

  &__main-img-column
    &.anonymous, &.rented-out
      .embla__controls
        bottom: 47px
    &.rented-out .image-gallery__img-wrapper img
      filter: grayscale(1)

    &.crawled
      .embla__controls
        bottom: 99px

  &__more-photos-wrapper
    @include positionAbsolute(0, $left: 4px)
    @include boxSize(calc(100% - 8px), 100%)
    @include font(16px, $white, 24px, 900)
    @include displayFlex(center, center)
    background-color: rgba(0, 0, 0, 0.6)
    text-transform: lowercase
    pointer-events: none

  &__mobile-fallback
    position: absolute
    height: 100%
    width: 100%

  & > .row
    height: 100%

  .row
    margin-left: -4px
    margin-right: -4px

  .col-md-6, .col-md-12
    padding-right: 4px
    padding-left: 4px
    &:not(.image-gallery__right-images-col)
      height: 100%

  .anonymous-badge,
  .crawled-badge__top-line,
  .rented-out-badge
    min-height: 31px
    line-height: 31px
    @media (min-width: $desktop-md)
      min-height: 48px
      line-height: 48px
      font-size: 16px

  .crawled-badge
    &__bottom-line
      padding: 8px 24px
      font-size: 12px

  .info-badges__new-special-wrapper
    top: 16px
    left: 16px
    .new-badge, .special-offer-badge
      @include fontSLH(16px, 24px)
      height: 32px
      padding: 0 12px
      @media (min-width: $desktop-md)
        height: 40px
