// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.search-options-modal
  padding: 20px 24px 77px
  &.success
    background: transparent !important
  @media (max-width: 767px)
    &:not(.success)
      inset: unset !important
      margin-right: 0 !important
      transform: none !important
      bottom: 0 !important
      margin: 0 !important
      min-width: 100vw
      left: 0 !important
      max-width: 100vw !important
      max-height: 100vh
      overflow-y: scroll
  @media (min-width: $tablet)
    width: 564px !important
    min-width: 564px !important
    padding: 44px 72px
    border-radius: 16px !important

  &__close-icon
    display: none
    @media (min-width: $tablet)
      @include closeBtnRight(24px, 24px)
      display: block
      cursor: pointer
      svg
        fill: #999999
        pointer-events: none

  &__success-headline
    @include fontSLH(18px, 24px)
    text-align: center
    margin-bottom: 32px
    @media (min-width: $tablet)
      font-size: 20px

  &__success-icon-wrapper
    display: flex
    justify-content: center

  &__success-icon
    @include square(180px)
    @include displayFlex(center, center)
    background: $green
    border-radius: 50%
    animation: bounce 1s .1s
    transform: scale(0.85)
    svg
      height: 160px
      width: 160px
      fill: white

  &__info
    h2
      @include fontSLH(18px, 24px)
      margin-bottom: 4px
      @media (min-width: $tablet)
        font-size: 20px
        margin-bottom: 8px
    p
      @include fontSLH(14px, 16px)
      font-weight: 300
      margin-bottom: 16px
      letter-spacing: -0.14px
      @media (min-width: $tablet)
        margin-bottom: 24px

  .basic-dropdown
    .Dropdown-root.is-open,
    .Dropdown-root:hover
      .Dropdown-control
        border-color: $green
    .Dropdown-menu
      margin-top: 0
    .Dropdown-control
      height: 48px
      adding-bottom: 0
      padding-top: 20px
      font-size: 14px
      @media (min-width: $tablet)
        height: 52px
        padding-top: 24px
    .Dropdown-placeholder:not(.is-selected)
      color: $default
      opacity: 0.5
    .Dropdown-option
      padding: 10px 16px
      font-size: 14px
    .Dropdown-arrow-wrapper
      top: 10px
      @media (min-width: $tablet)
        top: 12px

  .form-group
    margin-bottom: 16px

  .kinds
    position: relative
    .floating-label
      position: absolute
      top: 12px
      left: 16px
      font-size: 14px
      line-height: 24px
      color: #000
      transition: all ease-in-out 0.3s
      pointer-events: none
      z-index: 1
      @media (min-width: $tablet)
        top: 14px
    &.filled
      .floating-label
        font-size: 12px
        transform: translate(0, -11px)
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 300

  &__price-range, &__time-range
    p
      @include fontSLH(12px, 15px)
      font-weight: 300
      margin-bottom: 8px
      @media (min-width: $tablet)
        line-height: 20px

  &__price-inputs
    @include displayFlex(center, space-between)
    margin-bottom: 16px

  &__price-inputs-separator
    @include boxSize(18px, 1px)
    background: #E0E2E2

  &__price-input
    position: relative
    height: 48px
    border: 1px solid #e0e2e2
    background-color: #fff
    border-radius: 4px
    width: 138px
    @media (min-width: $tablet)
      height: 52px
      width: 177px
    .floating-label
      text-transform: capitalize
      position: absolute
      top: 2px
      left: 16px
      font-size: 12px
      color: #000
      transition: all ease-in-out 0.3s
      pointer-events: none
      z-index: 1
      font-weight: 300
      @media (min-width: $tablet)
        top: 4px
    .form-group
      margin-bottom: 0
    .base-input input
      padding-left: 0 !important
      border: none !important
      background: transparent

  &__price-input-bottom
    display: flex
    align-items: flex-end

  &__price-currency
    color: $default
    opacity: 0.5
    font-size: 14px
    padding: 0 5px 6px 16px
    &.active
      opacity: 1
    @media (min-width: $tablet)
      top: 25px

  .base-input input
    height: 48px !important
    & ~ .floating-label
      top: 12px
      @media (min-width: $tablet)
        top: 14px
    &::placeholder
      color: $default
      opacity: 0.5
    @media (min-width: $tablet)
      height: 52px !important

  .base-input input:focus, .base-input input.filled
    @media (min-width: $tablet)
      padding-top: 19px

  &__time-range-row
    @include displayFlex(center, space-between)
    margin-bottom: 8px

  &__time-range-trigger
    @include displayFlex(center, center)
    @include fontSLH(14px, 16px)
    width: calc(50% - 4px)
    height: 48px
    border-radius: 4px
    border: 1px solid #E0E2E2
    text-align: center
    position: relative
    cursor: pointer
    padding: 0 16px
    &.active
      border-color: $green
    &:hover
      border-color: $green
    &--calendar
      padding: 0

  &__time-range-label
    @include fontSLH(14px, 16px)
    position: absolute
    top: 16px
    z-index: 1
    pointer-events: none

  .react-datetime-picker
    width: 100%
    height: 48px
    min-width: 100%
    &__wrapper
      border: none
    &__clear-button,
    &__inputGroup
      display: none
    &__calendar-button
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      svg
        display: none
    &__calendar
      width: 288px !important
      inset: auto -1px 100% auto !important
    @media (min-width: $tablet)
      height: 44px

  .react-calendar
    font-family: MuseoSans, sans-serif
    border-radius: 4px
    box-shadow: 0px 6px 15px 0px rgba(0, 4, 10, 0.15)
    overflow: hidden
    border: none
    padding: 12px

    &__navigation
      height: 36px
      margin-bottom: 8px

    &__navigation__label__labelText
      @include fontSLH(16px, 24px)

    &__navigation__arrow
      @include fontSLH(24px, 24px)
      padding: 0
      min-width: 24px
      &:disabled
        background: white

    &__month-view__weekdays__weekday
      @include font(14px, $bg-charcoal, 16px, 500)
      text-transform: capitalize
      height: 36px
      margin-bottom: 2px
      padding: 8px 6px 12px
      abbr
        text-decoration: none

    &__tile
      @include fontSLH(14px, 20px)
      @include displayFlex(center, center)
      font-weight: 300
      height: 36px
      border-radius: 50%
      &--active
        background: $green
        color: $white !important
        font-weight: 500

    &__month-view__days__day--weekend
      color: $default
      &:disabled, &.react-calendar__month-view__days__day--neighboringMonth
        color: #757575

  &__buttons-wrapper
    @include displayFlex(center, space-between)
    position: fixed
    width: 100vw
    left: 0
    bottom: 0
    padding: 12px 24px
    border-top: 1px solid #E0E2E2
    background: $white
    z-index: 1
    @media (min-width: $tablet)
      position: relative
      padding: 16px 0 0
      max-width: 100%
      border-top: none

  &__close-button, &__submit-button
    @include displayFlex(center, center)
    border-radius: 4px
    height: 40px
    cursor: pointer
    padding: 8px
    @media (min-width: $tablet)
      height: 44px

  &__close-button
    @include fontSLH(14px, 24px)
    color: #5B5B5B
    border: 1px solid #B9B9B9
    min-width: 102px
    padding: 8px
    &:hover
      border-color: $orange-hover
    @media (min-width: $tablet)
      min-width: 130px

  &__submit-button
    @include font(14px, $white, 24px, 900)
    background: $orange
    margin-left: 8px
    width: 100%
    &:hover
      background: $orange-hover
    &.inactive
      background: #979797
      pointer-events: none
    &.loading
      pointer-events: none
      background: $orange-hover

  .ellipsis-loader
    height: auto
    span
      background: $white

  input[type='number']
    -moz-appearance: textfield

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none


@keyframes bounce
  0%
    transform: scale(1.1)
    opacity: 1
  50%
    transform: scale(1.6)
    opacity: .7
  60%
    transform: scale(0.6)
    opacity: 1
  80%
    transform: scale(0.95)
  100%
    transform: scale(0.85)
