@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.top-info
  margin-top: 56px
  @media (min-width: $desktop-md)
    margin-top: 96px

  &__block
    display: flex
    justify-content: space-between
    position: relative
    flex-direction: column
    box-shadow: 1px 8px 12px rgba(0, 0, 0, 0.15)
    border-radius: 8px
    overflow: hidden
    @media (min-width: $tablet)
      height: 100%

  &__block-wrapper
    margin-bottom: 64px
    &:last-child
      margin-bottom: 0
    @media (min-width: $tablet)
      margin-bottom: 0

  &__img-block
    @include boxSize(100%, 225px)
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    margin-bottom: 28px
    @media (min-width: $tablet)
      height: 157px
    @media (min-width: $desktop-md)
      height: 212px

  &__block-headline
    @include fontSLH(22px, 29px)
    font-weight: 900
    margin-bottom: 24px

  &__block-benefit
    @include displayFlex(flex-start)
    @include fontSLH(16px, 19px)
    margin-bottom: 16px
    &::before
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20px' viewBox='0 0 24 24' width='20px' fill='%23ffa300'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z'/%3E%3C/svg%3E")
      height: 20px
      margin-right: 4px
    ol
      margin: 8px 0 0
      padding-left: 23px

  &__block-bottom-wrapper
    @include displayFlex(center)
    flex-direction: column
    text-align: center
    padding: 16px 24px 32px

  &__block-button
    @include font(16px, $white, 19px, 900)
    position: relative
    display: inline-flex
    justify-content: center
    align-items: center
    background: $orange
    min-height: 48px
    border-radius: 4px
    text-decoration: none
    width: 100%
    margin-bottom: 24px
    cursor: pointer
    &:hover
      background: $orange-hover
      color: $white
    img
      margin-right: 8px

  &__block-bottom-info
    @include fontSLH(12px, 14px)
    font-weight: 500
    @media (min-width: $desktop)
      @include fontSLH(16px, 19px)
      height: 43px
      img
        height: 43px

  &__block-bottom-hotline
    @include displayFlex(center, center)
    margin-top: 4px
    svg
      margin-right: 8px
    @media (min-width: $desktop)
      margin-top: 5px

  &__block-headline,
  &__block-benefit
    padding: 0 24px

  .providers-logos
    @media (max-width: 1199px)
      height: 34px
