@import '../../src/stylesheets/mixins'
@import '../../src/stylesheets/variables'


.similar-locations
  &__wrapper
    margin-bottom: 23px

  &__heading
    @include push--auto
    width: 100%
    margin-bottom: 24px
    text-align: left
    font-size: 24px
    @media (max-width: $screen-xs)
      padding-left: 15px
    @media (max-width: $screen-lg-md-max)
      max-width: 100%
      font-size: 21px
      line-height: 24px
    @media (max-width: $screen-sm)
      margin-bottom: 16px
    @media (min-width: $screen-sm)
      max-width: 712px
    @media (min-width: $screen-lg-md)
      max-width: 952px
    @media (min-width: $screen-lg)
      max-width: 1170px

  &__slider-wrapper
    &.container
      @media (max-width: $screen-sm)
        padding: 0 !important
        width: 100% !important
        margin: 0
        overflow: visible
