@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.energy-rating-filter

  .dropdown-selector
    &__body--open
      width: 100%
      z-index: 11
    &__action-icon
      pointer-events: none
