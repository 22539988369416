@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.form-section
  .get-info-submit-button
    background: $orange
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    color: $white
    font-weight: bold
    border-radius: 4px
    width: 100%
    padding: 12px 0
    text-transform: capitalize
    border: none
    position: relative

  //.jeudan-policy-checkbox
  //  &__wrapper
  //    display: flex
  //    svg
  //      fill: $white
  //      position: absolute
  //      top: 2px
  //      left: 1px
  //      pointer-events: none
  //      z-index: 1
  //    &:before
  //      visibility: visible
  //      @include beforeEmptySquare(17px, $white, 0, $left: 0)
  //      border: 2px solid black
  //      border-radius: 2px
  //      display: block
  //      left: 0
  //      pointer-events: none
  //      z-index: 1
  //      top: 2px
  //    &.checked:before
  //      content: ''
  //      background: $green
  //      border-color: $green
  //  input
  //    @include positionAbsolute(2px, $left: 0)
  //    font-size: 20px
  //    margin-right: 5px
  //    cursor: pointer
  //    border-color: transparent
  //    background-color: transparent
  //    opacity: 0
  //  &__label
  //    @include font(10px, $default, 14px)
  //    margin-left: 25px
  //    font-weight: 300
  //  &__label-read-more-link
  //    margin-left: 5px
  //    color: $green
  //    text-transform: lowercase
  //    cursor: pointer
  //    text-decoration: underline
  //  .validation-message--jeudan
  //    margin-top: 8px
  //    line-height: 14px
  //    font-weight: 300


  .form-control:focus
    box-shadow: none
