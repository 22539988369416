// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.marker-info-window
  @include boxSize(328px, auto)
  @include displayFlex(false, space-between, column)
  max-width: calc(100vw - 32px)
  box-shadow: none
  margin: -8px !important
  position: relative
  background: $white
  border-radius: 8px
  z-index: 3
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2)
  bottom: 8px
  a
    display: block
    color: $default
    text-decoration: none
    user-select: none
    &:hover
      color: $default
      text-decoration: none

  .compare-icon,
  .favourite-icon
    &__click-area
      z-index: 1

  .parsed-location-placeholder
    @include displayFlex(center, center)
    font-weight: bold
    color: $white
    background-size: cover
    height: 180px
    position: relative
    background-repeat: no-repeat
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    .stripe-badge
      position: absolute
      margin: 0
      bottom: 0
      .top-wrapper
        font-size: 14px
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 900
      .bottom-wrapper
        font-size: 14px
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 300


  .parsed-title
    margin-bottom: 0

  &__header
    text-decoration: none
    width: 100%
    &:hover
      text-decoration: none
    .rented-out
      bottom: 0

  &__photo-block
    @include displayFlex(false, flex-end, column)
    background-size: cover
    background-position: center
    height: 180px
    position: relative
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    .stripe-badge
      margin: 0 0 8px
      bottom: 0
      font-size: 14px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      font-weight: 900
      height: 31px
      line-height: 31px

  &__kind
    @include fontSLH(14px, 21px)

  &__close-btn
    @include square(24px)
    position: absolute
    left: 16px
    top: 16px
    margin: 0
    border: 0
    padding: 0
    cursor: pointer
    color: inherit
    font: inherit
    line-height: normal
    display: block
    z-index: 1
    background: rgba(255,255,255,0.8)
    border-radius: 4px

  .favourite-icon-click-area
    top: 6px
  .favourite-icon-wrapper
    .favourite-icon
      z-index: 10

  &__description-wrapper
    padding: 4px 12px 12px
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px

  &__info-line
    @include font(14px, $black, 21px, 500)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    width: 100%
    display: flex
    justify-content: space-between
    &:first-child
      margin-bottom: 4px
    span:first-child
      color: #636363
    svg
      fill: #636363
      margin-right: 8px

  &__address-line
    font-size: 14px
    line-height: 22px
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    font-weight: 900
    margin-bottom: 4px
    padding-right: 16px

  &__header-wrapper
    @include displayFlex(flex-start, space-between)

    .suitable-for__item
      display: flex
      align-items: center
      cursor: pointer
    .suitable-for__more
      margin-left: 8px
      font-size: 14px
      line-height: 21px
      font-weight: 300
      position: relative
    .suitable-for__icon
      fill: #0FADA2
    .tooltip-wrapper-active
      .my-tooltip
        opacity: 1
        visibility: visible
        top: 30px
        white-space: initial !important
        min-width: 160px
        padding: 8px
        right: -8px
        transform: translate(0)
        left: unset
        &::after
          border-bottom-color: #222
          border-bottom-style: solid
          border-bottom-width: 8px
          top: -8px
          right: 8px
          border-top-style: none
          left: auto

  &__kinds
    display: flex
    align-items: center
    .secondary-kinds
      margin-left: 11px
      font-size: 14px
      line-height: 21px
      position: relative
      &:before
        content: " "
        position: absolute
        top: 11px
        width: 2px
        margin-left: -6px
        height: 2px
        border-radius: 50%
        background: #000

  &__suitableFor
    display: flex
    align-items: center
    position: relative

  .hover-pin
    position: absolute
    bottom: -65px
    left: 44%
    width: 37px
    height: 53px

