// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.interior-design-banner
  display: flex
  flex-direction: column
  padding: 64px 16px 24px
  white-space: normal
  text-align: left
  background-color: $border-light-grey
  @media (min-width: $tablet)
    padding: 64px 24px 24px
  @media (min-width: $desktop-md)
    min-height: 100%
    padding: 56px 64px 32px
  @media (min-width: $desktop)
    padding: 56px 64px 64px
    flex-direction: row

  &__title
    @include fontSLH(18px, 24px)
    font-weight: 900
    margin-bottom: 19px
    @media (min-width: $tablet)
      line-height: 26px
      margin-bottom: 24px
    @media (min-width: $desktop-md)
      @include fontSLH(24px, 32px)
      margin-bottom: 16px
    @media (min-width: $desktop)
      max-width: 452px

  &__content-wrapper
    display: flex
    flex-direction: column
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      flex-direction: row-reverse
      align-items: flex-start
    @media (min-width: $desktop)
      margin-bottom: 40px
      position: relative

  &__img
    margin-bottom: 24px
    align-self: center
    height: auto
    @media (min-width: $tablet)
      width: 179px
      min-width: 179px
    @media (min-width: $desktop-md)
      margin-bottom: 0
      align-self: flex-start
    @media (min-width: $desktop)
      @include positionAbsolute(0, 0)

  &__paragraph
    font-family: MuseoSans, sans-serif
    margin-bottom: 0
    @media (min-width: $desktop-md)
      margin-right: 24px
    @media (min-width: $desktop)
      margin-right: 232px

  &__subtitle
    @include fontSLH(16px, 24px)
    font-weight: 900
    margin-bottom: 8px

  &__subparagraph
    @include fontSLH(12px, 20px)
    font-family: MuseoSans, sans-serif
    margin-bottom: 16px
    @media (min-width: $tablet)
      margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-bottom: 16px

  &__link-btn
    @include boxSize(100%, 52px)
    @include displayFlex(center, space-between)
    padding: 0 24px
    background-color: $orange
    border-radius: 4px
    text-decoration: none
    color: $white
    &:hover
      background-color: $orange-hover
      color: $white
    @media (min-width: $tablet)
      height: 48px
      padding: 0 32px
    @media (min-width: $desktop-md)
      max-width: 365px
    span
      @include fontSLH(16px, 19px)
      font-weight: 900
    svg
      fill: $white

  // FULLSCREEN ============================>

  &--fullscreen
    padding: 16px
    @media (min-width: $tablet)
      padding: 56px 24px 64px
      flex-direction: row
    @media (min-width: $desktop-md)
      padding: 90px 80px
      min-height: 778px
    @media (min-width: $desktop)
      padding: 90px 100px

    .interior-design-banner__title
      @include fontSLH(16px, 24px)
      margin-bottom: 8px
      @media (min-width: $tablet)
        @include fontSLH(21px, 32px)
        margin-bottom: 24px
        max-width: 413px
      @media (min-width: $desktop-md)
        font-size: 24px
        max-width: 544px
        margin-bottom: 32px
      @media (min-width: $desktop)
        max-width: 484px

    .interior-design-banner__paragraph,
    .interior-design-banner__subtitle
      @include fontSLH(12px, 19px)
      @media (min-width: $tablet)
        @include fontSLH(16px, 24px)
        padding-right: 307px
      @media (min-width: $desktop-md)
        padding-right: 386px
        margin-right: 0
      @media (min-width: $desktop)
        padding-right: 500px


    .interior-design-banner__content-wrapper
      margin-bottom: 8px
      @media (min-width: $tablet)
        margin-bottom: 32px
        position: relative


    .interior-design-banner__subtitle
      margin-bottom: 0
      @media (min-width: $tablet)
        margin-bottom: 8px

    .interior-design-banner__subparagraph
      @include fontSLH(12px, 19px)
      margin-bottom: 8px
      @media (min-width: $tablet)
        line-height: 24px
        margin-bottom: 32px

    .interior-design-banner__link-btn
      @include boxSize(241px, 34px)
      padding: 0 14px
      span
        @include fontSLH(12px, 14px)
      svg
        @include square(15px)
      @media (min-width: $tablet)
        @include boxSize(361px, 48px)
        padding: 0 32px
        span
          @include fontSLH(16px, 19px)
        svg
          @include square(20px)

    .interior-design-banner__img
      @media (min-width: $tablet)
        width: 290px !important
        height: auto !important
        min-width: 290px
        position: absolute
        top: 0
        right: 0
      @media (min-width: $desktop-md)
        width: 303px !important
        min-width: 303px
      @media (min-width: $desktop-md)
        width: 346px !important
        min-width: 346px
      @media (min-width: $desktop)
        top: -32px

  // HORIZONTAL MOBILE

  &--orientation-horizontal-mobile
    justify-content: center
    align-items: center
    padding: 0 64px
    height: 100vh

    .interior-design-banner__title
      @include fontSLH(16px, 24px)
      margin-bottom: 16px

    .interior-design-banner__paragraph,
    .interior-design-banner__subtitle
      @include fontSLH(12px, 19px)

    .interior-design-banner__content-wrapper
      margin-bottom: 16px

    .interior-design-banner__subtitle
      margin-bottom: 8px

    .interior-design-banner__subparagraph
      @include fontSLH(12px, 19px)
      margin-bottom: 16px

    .interior-design-banner__link-btn
      @include boxSize(241px, 34px)
      padding: 0 14px
      span
        @include fontSLH(12px, 14px)
      svg
        @include square(15px)
