// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.dv-wrapper
  .map-element
    height: 224px
    border-top-left-radius: 4px
    border-top-right-radius: 4px

  .map-container
    height: 224px
    position: relative
    background-position: center
    background-size: 1200px
    background-repeat: no-repeat

  .dv-description-wrapper
    background-color: $white

  .dv-frame-wrapper
    height: 100%
    & > iframe
      @include square(100%)
      border: 0
      margin: 0
      padding: 0

  &--rented-out
    margin-bottom: 10px
    .image-gallery-right-nav,
    .image-gallery-left-nav,
    .switcher-container,
    .dv-ask-provider-link,
    .bottom-cta__wrapper,
    .image-gallery__fullscreen-btn,
    .dv-header__action-icons-wrapper
      display: none
    .image-gallery__img-wrapper img
      filter: grayscale(1)
      cursor: default

  .bottom-wrapper
    max-width: 100vw

.order-form-scroll-btn-mobile
  @include displayFlex(center, center)
  position: fixed
  bottom: 16px
  z-index: 111
  width: 100vw
  left: 0
  @media (min-width: $tablet)
    display: none

.back-to-top-btn
  @media (max-width: 767px)
    @include square(36px !important)
    right: 16px !important

.order-form-scroll-btn
  @include font(16px, $white, 24px, 900)
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 0 24px
  border-radius: 4px
  background: $orange
  cursor: pointer
  height: 36px
  white-space: nowrap
  &:hover
    background: $orange-hover
  svg
    fill: $white
    margin-right: 8px
  @media (min-width: $tablet)
    height: 40px
    font-size: 14px
    svg
      height: 20px !important
      width: 20px !important

.get-info-form-modal-content
  &__title-section
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid #eee
    padding: 16px 20px
    .modal-close
      height: 24px
  &__title
    p
      font-size: 20px
      margin-bottom: 0
  &__main-content
    padding: 16px
    .order-form__title
      padding-bottom: 8px

.remove-cta
  display: none

.form-control
  @include boxSize(100%, calc(1.5em + .75rem + 2px))
  @include font(1rem, $black, 1.5, 400)
  display: block
  padding: .375rem .75rem
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: .25rem
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
  outline: none
  font-family: MuseoSans,Helvetica,Arial,sans-serif
  font-size: 14px

.form-group
  margin-bottom: 1rem
  textarea
    height: auto

.skeleton-box
  @include boxSize(100%, 100%)
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60%) $grey-bg
  background-size: 200% 100%
  background-position-x: 180%
  animation: 0.7s loading ease-in-out infinite
  border-radius: 4px
  margin: 4px 0

  @keyframes loading
    to
      background-position-x: -20%

.widget-visible iframe
  html.tawk-mobile
    width: 34px !important
    height: 34px !important
