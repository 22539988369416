// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.search-banner
  .search-agent-banner
    @include displayFlex(center, center)
    border-top: 1px solid #E0E2E2
    border-bottom: 1px solid #E0E2E2
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
    .search-image
      height: 132px
  .list-item__description-wrapper
    padding: 16px 16px 7px
    @media (min-width: $desktop-md)
      padding: 32px 16px 10px

  .list-item__description-title
    margin-bottom: 5px
    @media (min-width: $desktop-md)
      margin-bottom: 10px
    p
      white-space: normal

  .description-line
    margin: 0
    span
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif

  .adver-description-tablet
    @media (min-width: $desktop-md)
      display: none

  .adver-description-desktop
    display: none
    @media (min-width: $desktop-md)
      display: block

  .list-item__get-info-button
    background: $green
    text-transform: none
    padding: 5px 16px
    line-height: 26px
    min-width: 173px
    margin: 0 auto
    display: inline-block
    border-radius: 6px
    color: #fff
    min-height: 36px
    text-align: center
    &:hover
      text-decoration: none
      background: $green-hover

  .list-item__bottom
    position: absolute
    bottom: 2.5px

  .advert-images
    display: none
    @media (min-width: $desktop-md)
      display: block
    img
      max-width: 123px
      max-height: 44px
      height: 44px


  &__button-wrapper
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    position: relative
    border: 1px solid #E0E2E2
    border-left: none
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    font-size: 16px
    font-weight: bold
    &:before
      content: ''
      border-left: 1px solid $bg-badge-rented-out
      position: absolute
      height: calc(100% - 36px)
      top: 18px
      left: 8px
