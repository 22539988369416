// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.list-item
  position: relative
  scroll-behavior: smooth
  @media (max-width: $screen-lg-md-max)
    max-width: 768px

  .form-block
    min-height: 0 !important

  &--premium
    &:first-child a
      margin-top: 0

    .anonymous-badge,
    .crawled-badge,
    .rented-out-badge
      width: 100% !important

    .list-item__bottom-left
      align-items: center

    .list-item__metrics-description
      margin-bottom: 4px

    .list-item__metrics-square,
    .list-item__price
      font-size: 15px !important
      line-height: 18px !important

    .embla__controls
      width: 100% !important

    .embla__buttons
      bottom: 144px !important
      @media (min-width: $desktop-md)
        bottom: 110px !important

    @media (min-width: 768px) and (max-width: 991px)
      .list-item__kinds-line
        margin-bottom: 12px

      .map-icon__label
        display: none

      .map-icon ~ div.list-item-tooltip
        display: inline-block !important

      .list-item__description-title p
        margin-bottom: 4px
      .list-item__wrapper,
      .list-item__image-slider,
      .list-item__image-wrapper
        height: 357px
      .list-item__image-slider
        width: 418px
      .list-item__bottom-wrapper
        display: none
      .list-item__metrics-wrapper
        border-top: none
        border-top-right-radius: 0
        position: absolute
        right: 0
        bottom: 0
        padding: 12px 24px 16px !important
        height: 244px
        max-width: unset !important
        width: 302px
        &:before
          display: none
        .list-item__get-info-button
          padding: 7.5px 10px
          font-size: 14px
      .list-item__description-wrapper
        height: 113px
        max-width: 302px
        border-bottom: none
        padding: 16px 24px 12px
        border-top-right-radius: 4px
        border-right: 1px solid #E0E2E2
        margin-bottom: 12px
      .list-item__description-title
        margin-bottom: 4px
      .suitable-for
        margin: 0 !important
        &__item
          margin-bottom: 0
          svg
            height: 18px
            width: 18px
        &__label, &__more
          font-size: 14px !important
          line-height: 21px !important

      .list-item__energy-rating-wrapper
        right: 3px
        top: -7px

      .list-item__bottom
        bottom: unset
        position: relative

      .list-item__bottom-right
        right: 0
        bottom: unset
        display: none
        &.tablet
          display: flex
          justify-content: flex-end
          right: 24px
          bottom: 12px

      .list-item__premium-separator
        display: block
        border-top: 1px solid #E0E2E2
        position: absolute
        bottom: 0
        left: 24px
        width: calc(100% - 48px)

      .description-line
        display: none

      .promoted-location
        & > div
          margin-right: 16px
          margin-bottom: 12px

      .list-item__metrics
        padding-left: 0

      .list-item__info-button-wrapper
        width: 100%
        padding: 0

      .list-item__metrics-area
        margin-bottom: 12px



    @media (min-width: $desktop-md)
      .list-item__kinds-line
        margin-bottom: 8px

      .list-item__image-wrapper,
      .list-item__wrapper,
      .list-item__image-slider
        height: 279px
      .list-item__image-slider
        width: 373px
      .list-item__description-wrapper
        width: 350px
        max-width: 350px
        padding: 24px 8px 24px 16px
      .list-item__metrics-wrapper
        justify-content: space-between
        padding: 16px 8px
        &:before
          height: calc(100% - 48px)
          top: 24px
          left: 6px
        .list-item__get-info-button
          padding: 9.5px 10px
          font-size: 14px
      .suitable-for__item
        margin-top: -16px
      .list-item__bottom
        bottom: 13px
      .list-item__bottom-right
        right: 10px
        bottom: 13px
      .favourite-icon__click-area, .compare-icon__click-area
        top: 11px
        right: 11px
      .list-item__price-wrapper
        margin: 16px 0
      .promoted-location
        & > div
          margin-bottom: 16px
      .list-item__metrics-square, .list-item__price
        @include fontSLH(14px, 17px)
      .list-item__metrics
        padding-right: 16px
        width: 100%
      .list-item__metrics-area
        margin-bottom: 16px

    @media (min-width: $desktop)
      .list-item__image-wrapper,
      .list-item__wrapper,
      .list-item__image-slider
        height: 280px
        .list-item__image-slider
          width: 461px
        .list-item__description-wrapper
          max-width: 100%
          padding: 10px 0 13px 16px
        .list-item__metrics-wrapper:before
          left: 8px

    .list-item__energy-rating.exempted
      .list-item__energy-rating-label
        display: none

    .list-item__energy-rating-label
      font-size: 14px

    .list-item__energy-rating-wrapper
      right: -5px
      top: -8px
      &.exempted
        @include square(38px !important)
        pointer-events: all
        position: absolute !important
        top: -8px !important
        right: -8px !important

  .favourite-icon__click-area
    right: 8px

  .compare-icon__click-area
    right: 50px

  &__premium-link
    @include fontSLH(14px, 24px)
    text-align: center
    margin-top: 8px
    cursor: pointer
    text-decoration: underline
    color: $green
    width: 100%
    &:hover
      color: $green-hover
    @media (min-width: 768px) and (max-width: 991px)
      margin-top: 8px
      @include fontSLH(14px, 24px)

  &__description-title-wrapper
    @media (min-width: $desktop-md)
      display: none
    &.desktops
      display: none
      @media (min-width: $desktop-md)
        display: block

  &__energy-rating-label
    display: none
    white-space: nowrap
    @media (min-width: $desktop)
      display: block

  &__energy-rating-wrapper.list
    @media (min-width: $desktop)
      @include square(unset !important)
      pointer-events: none
      position: relative !important
      top: unset !important
      right: unset !important

  &__premium-separator
    display: none

  &__wrapper
    @include displayFlex(false, space-between)
    position: relative
    background: $white
    cursor: pointer
    color: $default
    margin: 16px 0
    height: 223px
    border-radius: 4px
    -webkit-tap-highlight-color: transparent
    &:hover
      -webkit-box-shadow: 0 6px 15px 0px rgba(0,4,10,0.06)
      box-shadow: 0 6px 15px 0px rgba(0,4,10,0.06)
      color: $link-list-view-hover
      text-decoration: none
      .embla__buttons
        visibility: visible
    &.row
      margin-left: 0 !important
      margin-right: 0 !important

  &__image-slider
    @include boxSize(71px, 65px)
    position: relative
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
    overflow: hidden
    padding-left: 0 !important
    border-top: 1px solid #E0E2E2
    border-bottom: 1px solid #E0E2E2
    @media (min-width: $tablet)
      height: 223px
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
    &.rented-out .list-item__image-wrapper img
      filter: grayscale(1)
    &.anonymous, &.rented-out, &.crawled
      .embla__dots-wrapper
        position: relative
        bottom: 23px

  &__image-wrapper
    height: 65px
    overflow: hidden
    img
      @include square(auto)
      background: $white
      min-height: 100%
      min-width: 100%
    @media (min-width: $tablet)
      height: 223px

  &__description-wrapper
    @include displayFlex(false, $flexDirection: column)
    padding: 16px
    flex: 1 0
    position: relative
    border-top: 1px solid $bg-badge-rented-out
    border-bottom: 1px solid $bg-badge-rented-out
    @media (min-width: $screen-sm) and (max-width: $screen-lg-md-max)
      .description-line
        margin-top: -7px
    @media (max-width: $screen-xs-max)
      @include truncate()
      padding-top: 0 !important
      padding-bottom: 0 !important

    &.rented-out
      border-top: 1px solid #e0e2e2
      border-bottom: 1px solid #e0e2e2
      border-right: 1px solid #e0e2e2
      border-top-right-radius: 3px
      border-bottom-right-radius: 3px
      @media (min-width: $tablet)
        border-right: none
        border-top-right-radius: 0
        border-bottom-right-radius: 0

    .description-line
      max-width: 83.33%
      margin-bottom: 16px
      min-height: 42px
      p
        @include fontSLH(16px, 24px)
      @media (max-width: $tablet)
        min-width: 80%
      &.reduced
        min-height: 21px
        margin-bottom: 11px
        @media (min-width: $desktop)
          margin-bottom: 16px
      &.mobile-hidden
        @media (max-width: $screen-lg-md-max)
          display: none

  .suitable-for
    display: none
    margin-top: 2.5px
    margin-bottom: 2px
    @media (min-width: $tablet)
      display: flex
      flex-direction: column
      margin-top: -1px
      margin-bottom: 5px
      &.top
        margin-top: -4px
    @media (min-width: $desktop-md)
      @include positionAbsolute($bottom: 16px)
      flex-direction: row
      margin-bottom: 0
      &.top
        bottom: 52px

    &__item
      @include displayFlex(center)
      position: relative
      @media (min-width: $tablet)
        margin-bottom: 10px
      @media (min-width: $desktop-md)
        margin-right: 12px
        margin-bottom: 0
        &:last-child
          margin-right: 0

    svg
      margin-right: 3px
      fill: #0FADA2
      @media (min-width: $tablet)
        margin-right: 4px

    &__icon
      width: 14px
      height: auto
      margin-right: 3px
      @media (min-width: $tablet)
        margin-right: 4px

    &__label
      @include fontSLH(14px, 21px)
      font-weight: 300
      white-space: nowrap
      &::after
        content: " "
        position: absolute
        top: 11px
        width: 2px
        margin-left: 4px
        height: 2px
        border-radius: 50%
        background: #000
      &:last-child
        &::after
          display: none
      @media (max-width: $tablet)
        @include fontSLH(12px, 16px)

    &__more
      font-size: 14px
      margin-left: 12px
      font-weight: 300
      @media (max-width: $tablet)
        @include fontSLH(12px, 16px)

  &__description-title
    margin: 0
    width: 100%
    @media (min-width: $tablet)
      margin-bottom: 12px
    @media (min-width: $desktop-md)
      margin-bottom: 8px
    p
      @include truncate()
      font-weight: bold
      margin: 0 10px 0 0
      font-size: 16px
      @media (max-width: $screen-xs-max)
        margin-right: 0 !important
        font-size: 14px
    h4
      margin-bottom: 0
      height: 0
      color: $white
      user-select: none

  &__kinds-line
    @include displayFlex(center, space-between)
    margin-bottom: 0
    position: relative
    @media (min-width: $tablet)
      margin-bottom: 8px

  &__kinds
    white-space: nowrap
    @media (max-width: $screen-xs-max)
      display: flex
      height: 10px
      line-height: 10px
      overflow: hidden
      text-overflow: ellipsis

  &__kind
    @include font(14px, $black, 21px)
    font-weight: 300
    padding: 0
    display: inline-block
    text-transform: capitalize
    position: relative
    margin-right: 10px
    &::after
      content: " "
      position: absolute
      top: 11px
      width: 2px
      margin-left: 4px
      height: 2px
      border-radius: 50%
      background: #000
    &:last-child
      &::after
        display: none

  &__sent-info .sent-info
    background: $orange-hover

  &__info-sent .sent-info
    background: $orange-hover

  &__bottom
    position: absolute
    bottom: 16px

  &__bottom-left
    @include displayFlex(flex-end)
    svg
      @include square(18px)
      transform: scale(0.9)
      display: block
      margin-right: 1ex
      text-align: center

  &__bottom-right
    position: absolute
    right: 8px
    bottom: 16px
    &.tablet
      display: none
    @media (min-width: $tablet)
      left: 0
      right: unset
    @media (min-width: $desktop-md)
      left: unset
      right: 8px

  &__metrics-wrapper
    @include displayFlex(flex-start, space-between, column)
    padding: 16px 8px
    position: relative
    border: 1px solid $bg-badge-rented-out
    border-left: none
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    .list-item__get-info-button
      padding: 7.5px 10px
      line-height: 21px
      min-width: 173px
      svg
        margin-right: 8px
    &.rented-out
      border: 1px solid #e0e2e2
      border-left: none
      border-top-right-radius: 6px
      border-bottom-right-radius: 6px
    &:before
      content: ''
      border-left: 1px solid $bg-badge-rented-out
      position: absolute
      height: calc(100% - 36px)
      top: 18px
      left: 8px
    &.mobile-hidden
      display: none
      @media (min-width: $tablet)
        display: flex

    .sale-image-badge
      @include displayFlex(center)
      @include font(14px, $white, 20px, 900)
      margin-bottom: -10px
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.30)
      padding: 2px 8px
      gap: 4px
      border-radius: 20px
      background: linear-gradient(180deg, #86CB2F -10.42%, #59B71F 129.17%)
      position: relative
      top: -3px
      margin-left: 16px
      max-height: 24px

  &__metrics
    padding-left: 16px
    @media (min-width: $desktop-md)
      padding-left: 24px

  &__metrics-area
    margin-bottom: 8px

  &__metrics-square, &__price
    @include fontSLH(14px, 17px)
    margin-bottom: 12px
    white-space: nowrap
    font-family: MuseoSans, Helvetica, Arial, sans-serif

  &__metrics-description
    @include fontSLH(12px, 14px)
    color: #7F7F7F
    margin: 0 0 2px 0
    font-family: MuseoSans, Helvetica, Arial, sans-serif

  &__info-button-wrapper
    width: 100%
    padding: 0 8px 0 16px
    @media (min-width: $desktop-md)
      padding: 0 16px 0 24px

  &__get-info-button
    padding: 10px 10px
    line-height: 15px
    font
      pointer-events: none

  &__icon-wrapper
    @include displayFlex(center, center)
    position: relative
    height: 23px
    pointer-events: none

  .default-placeholder
    @include displayFlex(center, center)
    font-weight: bold
    background: top center
    background-size: cover
    height: 100%
    @media (min-width: $tablet)
      align-items: flex-end

  &__bottom-mobile
    display: flex
    img
      margin-right: 7px
    .cafe-icon
      height: 12px
    .disposal-icon
      height: 13px

  &__badges
    display: none
    @media (min-width: $tablet)
      display: flex
      flex-direction: column
      min-height: 21px
    @media (min-width: $desktop-md)
      flex-direction: row

  .read-more
    @media (max-width: $screen-xs-max)
      @include boxSize(fit-content, 15px)
      display: flex
      line-height: 15px
      font-size: 10px

    &--standalone
      @include positionAbsolute($right: 20px, $bottom: 48px)
      height: 18px

  .favourite-icon,
  .compare-icon
    &__icon
      @media (max-width: $screen-xs-max)
        top: 5px
        right: 5px
        cursor: pointer
        width: 16px
        height: 16px

  .list-view__info-tooltip
    margin-left: 5px
    svg
      position: relative
      bottom: 2px
      fill: #7F7F7F
    &:hover svg
      fill: $green

  .persons-tooltip
    padding: 4px 8px
    font-size: 12px
    line-height: 15px
    font-weight: 300
    max-width: 130px

  .info-notice
    @media (min-width: $tablet)
      margin-bottom: 28px
    @media (min-width: $desktop-md)
      margin-bottom: 0

  .anonymous-badge
    @include font(14px, $white, 31px)

  .rented-out-badge
    @include displayFlex(center, center)
    bottom: 16px

  .crawled-badge__top-line
    @include displayFlex(center, center)
    height: 100%

  .anonymous-badge,
  .crawled-badge,
  .rented-out-badge
    width: calc(100% - 8px)
    height: 31px

  .embla__buttons
    position: absolute
    width: 100%
    left: 0
    padding: 0 16px
    bottom: 78px
    visibility: hidden
