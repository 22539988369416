// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.info-sections__block-wrapper.with-favourite
  margin-bottom: 64px

.info-sections-row
  position: relative

.full-width-background-wrapper
  position: relative
  width: 100%

.full-width-background
  @include positionAbsolute(0, 0, 0, calc((100% - 100vw) / 2))
  @include boxSize(100vw, 100%)
  background-color: $border-light-grey

.dv-info-section-wrapper
  display: block
  padding-top: 64px // !!!IMPORTANT FOR NAVIGATION!!!

  .dv-info-section
    &__title
      margin: 0 0 24px 0
      @media (min-width: $desktop-md)
        margin: 0 0 32px 0

    &__content
      display: block
      min-height: 100px
      flex-direction: column
      box-sizing: border-box
      flex-wrap: wrap
      @media (min-width: $tablet)
        flex-direction: row

    &__blank-title
      font-size: 14px
      @media (min-width: $tablet)
        font-size: 16px

    &__section
      width: 50%
      &> h1:first-child
        padding-top: 0
      a
        color: $link-color-info-tabs
      strong
        line-height: 32px
      @media (max-width: $desktop-md)
        width: 100%
        margin-bottom: 0
        margin-top: 10px
        &:nth-child(2)
          margin-left: 0


    &__header
      font-weight: bold
      margin-bottom: 8px
      display: flex
      justify-content: space-between
      align-items: center
      &.inline
        display: inline-block
        line-height: initial
      &>svg
        fill: $green
        &:first-child
          margin-left: 5px
        &:last-child
          float: right

    &__label
      background-color: $border-light-grey
      color: $default
      overflow: hidden
      text-overflow: ellipsis
      padding: 12px 8px
      width: 25%
      position: relative
      display: flex
      align-items: center
      height: 72px
      span
        @include truncate(100%)
        margin-left: 9px
      &:nth-child(odd)
        background-color: $dropdown-border
      @media (max-width: $mobile)
        width: 100%
      &--full-width
        width: 100%
        display: flex
        justify-content: space-between
      &:first-child
        border-top-left-radius: 4px
        border-top-right-radius: 4px
      &:last-child
        border-bottom-left-radius: 4px
        border-bottom-right-radius: 4px

    &__ask-provider-link
      cursor: pointer
      text-decoration: underline
      text-align: right
      &:hover
        color: $green-hover

    &__read-more-smart-text
      @include fontSLH(16px, 24px)
      color: $green
      cursor: pointer
      text-decoration: underline
      font-size: 16px
      margin-bottom: 40px
      margin-top: 16px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      svg
        fill: $green
      a:hover svg
        fill: $green-hover

  .smart-text
    @include fontSLH(16px, 24px)
    margin-bottom: 40px
    font-size: 16px
    margin-top: -8px
    font-family: MuseoSans, Helvetica, Arial, sans-serif

  .smart-text-extended
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    margin-top: -8px

  // ECONOMY -------------------------------------->
  &--economy
    h3
      line-height: 26px
      font-size: 16px
      @media (min-width: $tablet)
        font-size: 18px
    .dv-info-section
      &__header
        justify-content: flex-start
        @media (max-width: $tablet)
          margin-bottom: 24px
        svg
          margin-right: 10px
        .hide-show-mobile
          font-weight: normal
          font-family: MuseoSans, Helvetica, Arial, sans-serif

      &__label
        font-size: 16px
        font-family: MuseoSans, Helvetica, Arial, sans-serif
      &__section
        width: 100%
        margin-top: 40px
        &:first-child
          margin-top: 0
        @media (min-width: $tablet)
          margin-top: 0


      &__section-wrapper
        .card-header-wrapper
          display: flex
          justify-content: space-between
          align-items: center
        .card
          border: none
          .card-header
            padding: 0
            border-bottom: 0
            background: none


      &__section-wrapper--disposal
        margin-bottom: 40px
        max-width: 100%
        @media (min-width: $desktop-md)
          margin-bottom: 17px
        .disposal-header
          justify-content: space-between
          img
            height: 22px
      &__content
        display: flex
        .disposal-true
          @media (min-width: $screen-sm)
            margin-bottom: 40px

      &__info-wrapper
        display: inline-flex
        align-items: center
        a
          margin: 0 !important
          display: flex
          cursor: pointer

      &__info-tooltip
        @include font(12px, 20px)
        font-weight: 100
        padding: 5px 21px
        &::after
          border-top-color: $default !important

      &__table-row
        white-space: normal
        align-items: center

      &__table-col
        min-width: 50%
        white-space: normal
        &--left
          padding-right: 10px
        &--right
          text-align: right


  &--location
    .smart-text-tooltip
      margin-top: -12px

    .bottom-location-map
      height: 296px
      border-radius: 4px
      @media (min-width: $tablet)
        height: 390px
      .location-title
        margin-bottom: 24px
        @media (min-width: $desktop-md)
          margin-bottom: 32px

    .wrapper-map-location
      position: relative
      width: 100%

    .here-map
      .H_imprint
        display: none


.sectionScrollTarget
  position: relative
  &.description
    @include positionAbsolute(30px)
    @media (min-width: $tablet)
      top: 0
  @media (max-width: $mobile)
    top: 30px

.dv-map-section
  &.location-title
    margin-bottom: 0

.dv-map-section-location-address-wrapper
  @include displayFlex(flex-start)
  margin-top: 16px
  margin-bottom: 16px
  @media (min-width: $desktop-md)
    margin-bottom: 24px
  svg
    margin-right: 10px

.dv-map-section-location-address
  display: flex
  flex-wrap: wrap
  flex-direction: column
  h2
    font-size: 18px
    line-height: 26px
    margin-bottom: 0
  @media (min-width: $tablet)
    flex-direction: row
  span
    @include font(16px, $default, 26px, bold)
    @media (min-width: $desktop-md)
      font-size: 18px

.favouritePopUp
  position: fixed
  bottom: 100px
  left: 50%
  background: #539533
  padding: 16px
  box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)
  border-radius: 4px
  color: $white
  width: 320px
  z-index: 700
  transform: translate(-50%, 0)

  p
    font-size: 16px
    line-height: 19px
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    margin-bottom: 0
    margin-right: 30px

.policy-block
  @media (max-width: $screen-xs-max)
    bottom: 175px

.viewed-locations-wrapper
  background: $grey-bg
  padding-top: 64px
  padding-bottom: 64px
  &.top-indentation
    margin-top: 64px
    background: $white
    padding-bottom: 0


.viewed-locations
  padding-bottom: 0
  &__headline
    @include push--auto
    margin-bottom: 8px
    width: 100%
    text-align: left
    line-height: 32px
    &.container
      @media (min-width: 769px)
        padding: 0 !important
      @media (max-width: $tablet)
        padding: 0 8px
    @media (max-width: $screen-lg-md-max)
      max-width: 100%
      font-size: 21px
    @media (max-width: $mobile)
      padding-left: 16px !important
    @media (min-width: $tablet)
      max-width: 712px
    @media (min-width: $desktop-md)
      max-width: 952px
      margin-bottom: 16px
    @media (min-width: $desktop)
      max-width: 1170px

  &.container
    @media (max-width: $tablet)
      padding-left: 0 !important
      padding-right: 0 !important
      width: 100% !important
      margin: 0
      overflow: visible
      padding-bottom: 60px

.dv-info-section__title
  @include font(24px, $default, 32px, bold)
  font-family: "MuseoSans"


