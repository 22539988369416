// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.benefits-mobile
  @media (min-width: $tablet)
    display: none

  &__item
    overflow: hidden
    border-top: 1px solid $bg-badge-rented-out

    &:last-child
      border-bottom: 1px solid $bg-badge-rented-out

    h3
      @include font(14px, $default, 24px)
      padding: 15px 30px 15px 37px
      position: relative
      user-select: none
      cursor: pointer
      margin: 0
      img
        position: absolute
        left: 0
        top: 17px
      svg
        @include positionAbsolute($right: 0)


    &.close
      .benefits-mobile__body
        min-height: 0
        height: 0
        padding: 0 0 0
        visibility: hidden
        animation: .25s ease 0s close
      svg
        transform: rotate(0deg)
        transition: transform ease 0.3s

    &.open
      svg
        transform: rotate(-180deg)
        transition: transform ease 0.3s
      .benefits-mobile__body
        height: auto
        min-height: 61px
        padding: 0 0 24px
        visibility: visible
        animation: .25s ease 0s open

  &__body
    @include fontSLH(14px, 24px)
    min-height: 0
    height: 0
    padding: 0 0 0
    visibility: hidden
    margin-bottom: 0
    a
      display: inline-block
      margin-top: 0
      line-height: 24px
      margin-left: 4px

@keyframes open
  from
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
  to
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 29px

@keyframes close
  from
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 29px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
