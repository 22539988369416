// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.modal-loader
  @include centralize
  position: fixed
  height: 100vh
  width: 100vw
  z-index: 99999
  top: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.5)
