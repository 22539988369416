@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.ep-newsletter-widget
  background-color: $border-light-grey
  border-radius: 4px
  margin-top: 64px
  padding: 24px 16px
  @media (min-width: $tablet)
    padding: 32px
  @media (min-width: $tablet) and (max-width: 991px)
    width: 100%
  @media (min-width: $desktop-md)
    padding: 32px 24px
    margin-top: 0
  @media (min-width: $desktop)
    padding: 32px

  &__headline
    @include displayFlex(center)
    svg
      margin-right: 16px
      @media (min-width: $desktop)
        margin-right: 24px

  &__title
    @include font(18px, $default, 30px, bold)
    margin-bottom: 0
    @media (min-width: $desktop)
      font-size: 21px

  &__description
    @include font(14px, $default, 21px)
    margin: 16px 0
    font-weight: 300

  &__form-input
    @include fullWidthSize(52px)
    @include font(1rem, $black, 21px)
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    padding: 24px 16px 10px 16px
    font-family: MuseoSans,Helvetica,Arial,sans-serif
    font-size: 14px
    &::placeholder
      color: $gull-grey-text
    &.sign-up-form__input-error
      border-color: $red
    &:focus, &.active
      &~.ep-newsletter-widget__form-floating-label
        font-size: 12px
        transform: translate(0, -12px)

  &__form-floating-label
    @include positionAbsolute(14px, $left: 16px)
    @include font(14px, $black, 24px)
    transition: all ease-in-out 0.3s

  &__form-label
    width: 100%
    margin: 0

  .form-group
    margin-bottom: 8px
    &:nth-child(2)
      margin-bottom: 16px
    &.ep-newsletter-widget__form-group-error
      .ep-newsletter-widget__form-input
        border-color: $red !important
      .ep-newsletter-widget__form-input-tooltip-error
        display: block !important
      &:before
        color: $red

  &__form-input-tooltip-error
    @include font(12px, $color: $red, $lineHeight: 19px)
    text-align: left
    font-weight: 300

  &__servers-error
    margin-top: 10px
    color: $red

  .ReactModal__Overlay--after-open
    z-index: 99999

  .policy-info-description
    display: inline-flex
    align-items: center
    color: $green
    margin-top: 16px
    cursor: pointer
    width: fit-content
    svg
      fill: $green
      margin-right: 4px
    span
      @include fontSLH(12px, 19px)
      text-decoration: underline
      font-weight: 300
    &:hover
      span
        color: $green-hover

  &__form-btn-submit
    margin-bottom: 0 !important
    button
      @include font(16px, $white, 24px, bold)
      @include boxSize(100%, 48px)
      text-transform: none
      margin-bottom: 0
      white-space: normal
      cursor: pointer
      padding: 12px 24px
      text-align: center
      background-color: $orange
      border-radius: 6px
      display: inline-block
      touch-action: manipulation
      background-image: none
      border: 1px solid transparent
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

  &__form-resubscribe-link
    display: block
    width: 100%
    text-align: left
    color: $link-color
    text-decoration: none
    margin-bottom: 10px
    &:hover
      color: $link-color-hover
      text-decoration: underline

  input::-webkit-credentials-auto-fill-button,
  input::-webkit-contacts-auto-fill-button
    visibility: hidden
    pointer-events: none
    position: absolute
    right: 0

  input::-webkit-contacts-auto-fill-button
    display: none !important

  .success
    .ep-newsletter-widget__description
      margin-bottom: 0

@media (min-width: $tablet) and (max-width: 991px)
  .ep-newsletter-widget
    &__wrapper
      display: flex
      justify-content: space-between
    &__form-wrapper
      min-width: 48.782%
      width: 48.782px
    &__headline-wrapper
      padding-right: 16px

  .success
    .ep-newsletter-widget__description
      text-align: center
    .ep-newsletter-widget__headline
      justify-content: center
