// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.grid-item
  margin-bottom: 20px
  @media (min-width: $tablet)
    height: 542px
    min-height: 542px
  @media (min-width: $desktop-md)
    margin-bottom: 16px
  @media (max-width: $screen-xs-max)
    height: auto
    margin-left: auto
    margin-right: auto

  .sale-image-badge
    @include displayFlex(center)
    @include font(14px, $white, 20px, 900)
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.30)
    padding: 2px 8px
    gap: 4px
    border-radius: 20px
    background: linear-gradient(180deg, #86CB2F -10.42%, #59B71F 129.17%)
    position: relative
    top: -3px
    margin-left: 16px
    max-height: 24px
    margin-bottom: -10px

  .energy-rating-tooltip
    max-width: 250px

  &__top-row
    @include displayFlex(center, space-between)
    margin-bottom: 8px
    position: relative

  &__suitable-for-wrapper
    height: 20px

  &__map-icon-wrapper
    @include positionAbsolute($right: 0, $bottom: 16px)
    justify-content: flex-end

    > div
      margin-right: 13px
    @media (min-width: $tablet)
      display: flex

  &__wrapper
    height: 100%
    background-color: $white
    cursor: pointer
    display: block
    color: $default
    position: relative
    border: 1px solid $borders
    border-radius: 4px
    -webkit-tap-highlight-color: transparent
    &:hover
      color: $link-list-view-hover
      text-decoration: none
      box-shadow: 0 6px 15px 0px rgba(0,4,10,0.06) !important
      .embla__buttons
        visibility: visible
    .badge-wrapper
      position: absolute
      display: flex
      align-items: baseline
      flex-direction: column
      left: 16px
      top: 16px
    &.premium
      border: 2px solid #FFA300
      border-radius: 6px
      box-shadow: 0px 0px 20px 2px #FFA30040

  &__button-wrapper .sent-info
    background: $orange-hover

  &__image-slider
    position: relative
    height: 233px
    overflow: hidden
    &.rented-out .grid-item__image-wrapper img
      filter: grayscale(1)
    &.anonymous, &.rented-out, &.crawled
      .embla__dots-wrapper
        position: relative
        bottom: 23px

  &__location-kind
    @include positionAbsolute(0)
    @include truncate()
    background: $bg-charcoal
    color: $white
    display: block
    padding: 10px 15px
    border-bottom-right-radius: 10px
    font-weight: bold
    max-width: calc(100% - 45px)
    z-index: 100
    &:hover
      white-space: inherit
      height: auto
      text-overflow: clip
      word-wrap: break-word
      max-width: calc(100% - 45px)
      overflow: hidden
    @media (min-width: $desktop-md)
      border-bottom-right-radius: 8px
      max-width: 166px

  &__metrics-wrapper
    margin-top: 8px
    z-index: 11
    width: 100%
    position: relative

  &__area
    margin-bottom: 12px
    p
      margin-bottom: 0
    p:first-child
      @include fontSLH(12px, 14px)
      color: #7F7F7F
      margin-bottom: 4px
    p:last-child
      @include fontSLH(15px, 18px)
      white-space: nowrap

  &__price-block
    min-height: 48px

  &__price
    margin-bottom: 12px
    p
      margin-bottom: 0
    p:first-child
      @include fontSLH(12px, 14px)
      color: #7F7F7F
      margin-bottom: 4px
    p:last-child
      @include fontSLH(15px, 18px)
      white-space: nowrap

  &__month-price-block
    display: flex
    justify-content: space-between
    p
      margin-bottom: 0
    @media (min-width: $tablet)
      min-height: 24px

  .premium
    @media (max-width: 767px)
      min-height: initial

  &__premium-link
    @include fontSLH(14px, 24px)
    margin: 8px 0 0
    text-align: center
    cursor: pointer
    text-decoration: underline
    color: $green
    &:hover
      color: $green-hover
    @media (min-width: $tablet)
      display: none

  &__description-wrapper
    padding: 10px 15px 15px
    @media (min-width: $desktop-md)
      padding-bottom: 20px
    &--bordered
      min-height: 229px

  &__kind
    @include font(14px, $black, 21px, 300)
    display: inline-block
    text-transform: capitalize
    position: relative
    margin-right: 10px
    &::after
      content: " "
      position: absolute
      top: 11px
      width: 2px
      height: 2px
      right: -6px
      border-radius: 50%
      background: #000
    &:last-child
      &::after
        display: none

  &__description-title
    position: relative
    margin-bottom: 10px
    min-height: 60px
    border-bottom: 1px solid #E8EAEA
    .text-title p
      @include truncate()
      margin: 0 10px 0 0
      font-weight: bold
    h4
      margin-bottom: 0
      height: 0
      width: 100%
      color: $white
      user-select: none

  &__sale-badge
    .text-title
      width: 80%
    .percentage
      position: absolute
      right: 10px
      top: 17px
      color: #fff
      transform: rotate(-45deg)
      font-size: 10px
      line-height: 18px

  &__info-button
    width: 100%
    position: relative
    padding: 6px 20px !important
    svg
      margin-right: 8px
    font
      pointer-events: none

  &__icons-block
    @include displayFlex(center, space-between)
    text-align: right
    margin-bottom: 8px
    margin-top: 5px
    position: relative

  .default-placeholder
    @include displayFlex(flex-end, center)
    @include fullWidthSize(200px)
    font-weight: bold
    background: no-repeat top center
    background-size: 100%
    @media (min-width: $desktop-md)
      @include fullWidthSize(233px)
  .description-line
    width: 90%
    position: relative
    margin-bottom: 5px
    max-height: 40px
    @media (min-width: $tablet)
      margin-bottom: 8px
    p
      word-break: break-word
      min-height: 34px
      line-height: 20px

  &__bottom
    position: relative
    @media (min-width: $tablet)
      position: absolute
      bottom: 16px
      left: 16px
      width: calc(100% - 32px)

  &__badges
    min-height: 21px
    display: flex
    flex-direction: column
    @media (min-width: $desktop)
      flex-direction: row

  &__image-wrapper
    img
      width: 100%
      height: auto
      background: #fff
      min-height: 233px
      min-width: 100%
      object-fit: cover

  .suitable-for
    display: flex
    margin-top: 4px
    @media (min-width: $desktop-md)
      margin-top: 3px
      margin-bottom: 4px
    @media (min-width: $desktop)
      margin-top: 5px
      margin-bottom: 5px

    &__item
      @include displayFlex(center)
      position: relative
      @media (min-width: $desktop)
        margin-right: 10px
        &:last-child
          margin-right: 0

    &__icon
      fill: #0FADA2
      margin-right: 4px

    &__label
      @include fontSLH(14px, 21px)
      font-weight: 300
      &::after
        content: " "
        position: absolute
        top: 11px
        width: 2px
        margin-left: 4px
        height: 2px
        border-radius: 50%
        background: #000
      &:last-child
        &::after
          display: none

    &__more
      font-size: 14px
      margin-left: 10px
      font-weight: 300

  .list-view__info-tooltip
    margin-left: 5px
    svg
      position: relative
      bottom: 2px
      fill: #7F7F7F
    &:hover svg
      fill: $green

  .persons-tooltip
    padding: 4px 8px
    font-size: 12px
    line-height: 15px
    font-weight: 300
    max-width: 130px

  .info-notice
    margin-top: 10px
    &__info-modal
      top: 68px !important
      box-shadow: none
      border: 1px solid black
    &__info-icon:after
      top: 25.5px
      z-index: 999
      box-shadow: none
      border-top: 1px solid black
      border-left: 1px solid black

  .anonymous-badge
    @include font(14px, $white, 31px)

  .rented-out-badge
    @include displayFlex(center, center)
    bottom: 16px

  .crawled-badge__top-line
    @include displayFlex(center, center)
    height: 100%

  .anonymous-badge,
  .crawled-badge,
  .rented-out-badge
    width: 100%
    height: 31px

  .embla__buttons
    position: absolute
    width: 100%
    left: 0
    padding: 0 16px
    bottom: 86px
    visibility: hidden


