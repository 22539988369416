@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.register-modal
  width: 484px !important
  @media (max-width: $screen-xs-max)
    width: calc(100vw - 32px) !important

  &__wrapper
    padding: 16px 16px 8px
    max-height: 95vh
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    @media (min-width: $tablet)
      padding: 32px 32px 24px

  input, .Dropdown-control
    @include fullWidthSize(52px)
    @include font(14px, $black, 1.428571429)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    //max-width: 470px
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 0 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    &::placeholder
      color: $gull-grey-text
    &.sign-up-form__input-error
      border-color: $red
  input::-webkit-credentials-auto-fill-button
    visibility: hidden
    pointer-events: none
    position: absolute
    right: 0
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button
    visibility: hidden
    display: none !important
    pointer-events: none
    height: 0
    width: 0
    margin: 0

  .form-group
    margin-bottom: 8px

    &.sign-up-form__form-group-error
      .sign-up-form__input
        border-color: $red !important
      .sign-up-form__input-tooltip-error
        display: block !important
      &:before
        color: $red

    &--not-required,
    &--checkbox
      &:before
        display: none

    &--password
      @media (max-width: $screen-xs)
        max-width: 100%
      input
        padding-right: 43px

    &--checkbox
      display: flex
      align-items: center
      height: 21px
      margin-bottom: 32px
      padding-top: 5px
      margin-top: 16px
      input:checked ~ .checkmark
        background-color: $green
      .checkmark
        position: absolute
        top: 5px
        left: 0
        height: 16px
        width: 16px
        border: 1px solid #e0e2e2
        border-radius: 4px
        &::after
          left: 5px
          top: 1px
          width: 5px
          height: 9px
          border: solid white
          border-width: 0 2px 2px 0
          -webkit-transform: rotate(45deg)
          transform: rotate(45deg)
          display: block
          content: ""
          position: absolute

  .Dropdown-placeholder
    height: 100%
    line-height: 27px

  .password-visibility
    @include positionAbsolute(16px, 22px)
    fill: $gull-grey-text
    cursor: pointer

  .bigger-margin-bottom
    margin-bottom: 24px

  .b-button
    height: 48px
    text-transform: inherit
    width: 100%

  &::-webkit-credentials-auto-fill-button
    visibility: hidden
    pointer-events: none
    position: absolute
    right: 0

  #terms_of_service
    height: 16px
    width: 1px
    position: absolute
    left: 8px
    top: 5px
    border: none


.sign-up-form

  &__input.active
    padding-top: 15px

  &__floating-label
    @include positionAbsolute(15px, $left: 16px)
    font-size: 14px
    line-height: 24px
    color: $black
    transition: all ease-in-out 0.3s
    &.active
      font-size: 12px
      transform: translate(0, -12px)
      font-weight: 300

  &__label
    width: 100%
    margin: 0
    input:focus, input:-webkit-autofill
      padding-top: 15px
    input:focus, input:-webkit-autofill
      & ~ .sign-up-form__floating-label
        font-size: 12px
        transform: translate(0, -12px) !important
        font-weight: 300

  &__inline
    margin: 0 !important
    //max-width: 470px
    display: flex
    flex-wrap: nowrap
    .form-group
      &--short
        //margin-right: 7.5px
        width: 44%
      &--long
        margin-left: 7.5px
        width: 56%
    @media (max-width: $screen-sm)
      .form-group
        &--short
          width: 50%
        &--long
          width: 50%
    @media (max-width: $screen-xs)
      .form-group
        &--short
          width: 42%
        &--long
          width: 58%

  &__terms-conditions-label
    margin-left: 15px
    cursor: pointer
    user-select: none
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    label
      margin: 0
      cursor: pointer
      margin-left: 12px
    &:hover
      .checkmark
        border: 1px solid $green


  &__terms-conditions-toggle
    color: $green
    text-decoration: underline
    cursor: pointer
    &:hover
      color: $green-hover

  &__input-tooltip
    @include font(12px, $black, 16px)
    margin-top: 8px
    font-family: MuseoSans, Helvetica, Arial, sans-serif

  &__input-tooltip-error
    @include font(12px, $red, 19px)
    font-weight: 300
    display: none

  &__inline-group-tooltip
    margin-bottom: 10px
    margin-top: -10px

  &__input-tooltip-postal-error
    width: 100%

  &__tooltip
    position: absolute
    right: 16px
    top: 15px

  &__tooltip-icon
    cursor: pointer
    &:active
      fill: $green-hover
    @media (min-width: $desktop-md)
      &:hover
        fill: $green-hover
        & ~ .sign-up-form__tooltip-content-wrapper
          display: flex

  &__tooltip-content-wrapper
    @include positionAbsolute(30px, -32px)
    font-family: MuseoSans, san-serif
    padding: 16px
    background: rgba(0,0,0,0.86)
    width: 241px
    border-radius: 4px
    z-index: 1
    @media (min-width: $desktop-md)
      width: 213px
      right: -48px
      pointer-events: none
      display: none
      &:after
        right: 40px

  &__tooltip-content
    @include font(14px, $white, 20px)
    @include displayFlex(flex-start)
    svg
      min-width: 22px
      fill: $white
      pointer-events: all
      margin-left: 8px

  &__subheading
    font-size: 12px
    color: $default
    margin-top: 15px
    display: block
    font-family: MuseoSans, Helvetica, Arial, sans-serif
