@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/modules/buttons'
@import '../../../src/stylesheets/mixins'


.order-conf

  &__page-wrapper
    p
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, san-serif

    .b-button--huge
      @include fontSLH(16px, 19px)
      position: relative
      padding: 14.5px 58px 14.5px 24px
      text-transform: none
      justify-content: space-between
      width: auto
      max-width: 328px
      border-radius: 4px
      svg
        position: absolute
        right: 24px
        margin-right: 0
        fill: $white
      &.bg-green
        background-color: $green
        &:hover
          background-color: $green-hover

  &__header
    @include fontSLH(24px, 32px)
    padding: 32px 0 16px
    margin: 0
    @media (min-width: $tablet)
      text-align: center
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)
      padding: 56px 0 24px

  &__main-text
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    margin-bottom: 32px
    @media (min-width: $tablet)
      text-align: center

  &__main-text-hint
    margin-bottom: 0
    font-weight: 900
    @media (min-width: $tablet)
      text-align: center

  &__cols-wrapper
    display: flex
    margin-bottom: 32px
    @media (min-width: $tablet)
      height: 267px
      margin-bottom: 40px

  &__col,
  &__more-block
    flex-grow: 1
    flex-basis: 0
    display: flex
    flex-direction: column

  &__more-blocks-wrapper
    @media (min-width: $desktop-md)
      display: flex
      align-content: stretch

  &__more-block
    background-color: $grey-bg
    padding: 32px 16px
    border-radius: 4px
    &.top
      margin-bottom: 32px
      @media (min-width: $desktop-md)
        margin-bottom: 0
    @media (min-width: $tablet)
      padding: 32px
    @media (min-width: $desktop-md)
      height: 100%
      padding: 56px 32px
    @media (min-width: $desktop)
      padding: 56px
    h2
      @include fontSLH(21px, 32px)
      margin-bottom: 16px
      @media (min-width: $desktop-md)
        font-size: 24px
    p
      margin-bottom: 24px
      @media (min-width: $tablet)
        margin-bottom: 16px
        max-width: 85%
      @media (min-width: $desktop-md)
        margin-bottom: 24px
        max-width: 100%

  &__propectus-image
    @include square(100%)
    background-size: cover
    background-position: center center
    background-repeat: no-repeat
    border-radius: 4px 0 0 4px

  &__propectus-info
    background-color: $border-light-grey
    height: 100%
    border-radius: 0 0 4px 4px
    display: flex
    flex-direction: column

  &__propectus-address
    background-color: $green
    color: $white
    padding: 16px
    position: relative
    border-radius: 4px 4px 0 0
    &::after
      @include beforeEmptySquare(14px, $green, $bottom: -7px, $left: 48%)
      transform: rotate(45deg)
    @media (min-width: $tablet)
      padding: 16px 32px
      border-radius: 0 4px 0 0
      &::after
        left: 48%
    p
      margin-bottom: 4px
    b
      @include fontSLH(16px, 24px)
      margin: 0

  &__propectus-actions
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    @media (max-width: $desktop-md)
      margin: 40px
    p
      margin: 0
      max-width: 99%
      @media (min-width: $tablet)
        line-height: 24px
    svg
      margin-right: 8px
    svg, span
      vertical-align: top
    .virtual-tour-icon
      margin-top: 10px
      padding: 5px 20px
      background: #77bc1f
      border-radius: 4px
      cursor: pointer
      fill: #fff
      svg
        margin-right: 8px
        margin-top: 3px
    b
      @include fontSLH(16px, 26px)
      display: block
      @media (min-width: $desktop-md)
        font-size: 18px
    a
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, san-serif
      display: flex
      align-items: center
      color: #fff !important
      &:hover,
      &:hover svg,
      &:hover i
        text-decoration: none
        color: #fff !important

    .spinner-wrapper
      width: 100%
      p
        text-align: center
        font-weight: bold
        margin-top: 5px

  &__prospectus-actions-button
    padding: 5px 20px
    background: #77bc1f
    border-radius: 4px
    cursor: pointer
    &:hover
      background: #539533
    svg
      fill: $white

  &__actions-wrapper
    padding-bottom: 30px
    margin-bottom: 30px
    border-bottom: 1px solid $dark-grey
    &.order-conf__cols-wrapper
      @media (max-width: $screen-xs-max)
        flex-direction: column
    .order-conf__col
      + .order-conf__col
        margin-left: 30px
      @media (max-width: $screen-xs-max)
        flex-basis: initial
        + .order-conf__col
          margin-top: 30px
          margin-left: 0

  &__other-materials
    padding: 8px 20px

  &__typeform
    margin: 10px 0
    text-align: center
    @media (max-width: $screen-xs-max)
      margin: 20px 0

  &__gallery-title
    text-align: center
    padding: 0 25px

.flex-main
  flex-grow: 1

.hidden-xs
  @media (max-width: $screen-xs-max)
    display: none

.visible-xs
  display: none
  @media (max-width: $screen-xs-max)
    display: block

.similar-locations__heading
  line-height: 32px
  margin-bottom: 16px
  @media (min-width: $tablet)
    text-align: center
  @media (min-width: $desktop-md)
    font-size: 24px

.similar-locations__subheading
  @include fontSLH(16px, 24px)
  font-family: MuseoSans, san-serif
  margin-bottom: 8px
  @media (min-width: $tablet)
    text-align: center
  @media (min-width: $desktop-md)
    margin-bottom: 16px

.similar-locations
  margin-bottom: 64px !important
