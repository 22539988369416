@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.viewed-locations-wrapper
  background: white
  padding-top: 64px
  margin-bottom: -32px

.viewed-locations
  padding-bottom: 0

  &__headline
    @include push--auto
    margin-top: 0
    margin-bottom: 24px
    width: 100%
    text-align: left

    &.container
      @media (min-width: 769px)
        padding: 0 !important
      @media (max-width: $tablet)
        padding: 0 8px
    @media (max-width: $screen-lg-md-max)
      max-width: 100%
      font-size: 21px
      line-height: 24px
    @media (max-width: $mobile)
      padding-left: 16px !important
    @media (min-width: $tablet)
      max-width: 712px
    @media (min-width: $desktop-md)
      max-width: 952px
    @media (min-width: $desktop)
      max-width: 1170px

  &.container
    @media (max-width: $tablet)
      padding-left: 0 !important
      padding-right: 0 !important
      width: 100% !important
      margin: 0
      overflow: visible
      padding-bottom: 32px
