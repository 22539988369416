// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.advertisement
  height: 100%
  border-radius: 4px
  overflow: hidden

  .grid-item__wrapper
    position: relative

  .adver-content-title
    position: relative
    padding: 16px 0 8px
    font-weight: bold
    margin: 0
    color: $black

  .search-agent-images
    display: none
    @media (min-width: $tablet)
      display: block
      margin-bottom: 6px
    img
      max-width: 170px
      max-height: 60px
      height: 60px

  .grid-item__description-wrapper
    @include displayFlex(false, space-between, column)
    height: 251px
    padding: 0 16px 18px
    @media (min-width: $tablet)
      height: 306px
    span
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif

  .adver-description
    @media (min-width: $desktop-md) and (max-width: $screen-md-max)
      display: none

  .adver-description-md
    display: none
    @media (min-width: $desktop-md) and (max-width: $screen-md-max)
      display: block

  .grid-item__info-button
    @include fontSLH(14px, 21px)
    background: $green
    position: relative
    text-transform: none
    padding: 0
    line-height: 31px
    &:hover
      background: $green-hover

  .grid-item__search-agent-button
    @include fontSLH(14px, 21px)
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    background: $green
    position: relative
    text-transform: none
    padding: 0
    height: 36px
    line-height: 36px
    border-radius: 6px
    border: none
    font-weight: bold
    color: #fff
    bottom: -3px
    &:hover
      text-decoration: none

  .grid-height
    height: 54px
    line-height: 54px
    margin-bottom: -6px

  .search-agent__image-wrapper
    @include fullWidthSize(207px)
    @include displayFlex(center, center)
    color: $white
    text-align: center
    @media (min-width: $tablet)
      height: 200px
    @media (min-width: $desktop-md)
      height: 189px
    @media (min-width: $desktop-md)
      height: 233px

.search-agent
  .search-image
    height: 132px
