// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.info-notice
  a
    text-decoration: none

  &__wrapper
    @include displayFlex(center)

  &__title
    cursor: pointer
    display: flex
    align-items: center
    font-family: Museo Sans, sans-serif
    span
      user-select: none

  &__info-icon
    pointer-events: none
    display: flex
    margin-left: 5px
    color: $green
    cursor: pointer
    position: relative
    user-select: none
    &:after
      @include beforeEmptySquare(18px, $white, $top: 26px, $left: 0)
      display: none
      -webkit-transform: rotate(45deg)
      transform: rotate(45deg)
      z-index: 10
      box-shadow: 3px 6px 13px 0px rgba(0,4,10,0.2)
    &.active
      &:after
        display: initial

  &__info-modal
    position: absolute
    background: $white
    width: 420px
    -webkit-box-shadow: 0 6px 15px 0px rgba(0,4,10,0.06)
    box-shadow: 0 6px 15px 0px rgba(0,4,10,0.06)
    padding: 20px
    color: $default
    text-align: left

  &__modal-title
    margin-bottom: 6px
    font-weight: bold
    font-family: 'MuseoSans', san-serif

  &__modal-content
    margin: 0
    font-size: 14px
    line-height: 21px
    font-family: 'MuseoSans', san-serif
    &.copyright
      margin-bottom: 8px

  // GRID_VIEW & LIST_VIEW ================================>

  &.grid-view, &.list-view
    .info-notice__title span
      font-size: 12px
      font-weight: bold
      font-family: 'MuseoSans', san-serif

    .info-notice__info-icon
      margin-left: 10px

    .info-notice__info-modal
      width: 100%
      z-index: 101

    .info-notice__close-modal-btn
      @include closeBtnRight(8px, 10px)

  &.grid-view
    @media (max-width: $mobile)
      //position: relative
      margin-bottom: 8px
    .info-notice__info-modal
      top: 45px
      width: 100%
      z-index: 101
      left: 0
      border-radius: 4px
      @media (max-width: $mobile)
        top: 34px

  &.list-view
    position: relative
    .info-notice__info-modal
      top: 35px
      left: 50px
      width: 40vw
      max-width: 420px
      z-index: 10
      -webkit-border-radius: 4px
      -moz-border-radius: 4px
      border-radius: 4px


  // DETAIL_VIEW ================================>

  &.detail-view
    margin-bottom: 16px
    position: relative

    .info-notice__title span
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, san-serif

    .info-notice__info-icon
      margin-left: 10px
      svg
        @include square(24px)
        position: relative
        top: 1px

    .info-notice__info-modal
      left: 0
      top: 35px
      width: 100%
      z-index: 10
      -webkit-border-radius: 4px
      -moz-border-radius: 4px
      border-radius: 4px
      @media (min-width: $tablet)
        width: 420px

    .info-notice__modal-title
      font-size: 16px
      margin-top: 0

    .info-notice__close-modal-btn
      @include closeBtnRight(15px, 15px)


