// MOBILE-FIRST
@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'


.list-office
  p
    font-family: MuseoSans, sans-serif

  .FAQSection
    @media (min-width: $tablet)
      padding-top: 72px
    @media (min-width: $desktop-md)
      margin-bottom: 96px
    &__title
      text-align: center
      @media (min-width: $desktop-md)
        margin-bottom: 32px

  &__trustpilot
    margin-top: 64px

