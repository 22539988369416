@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.signup-tabs-wrapper
  padding-top: 64px
  padding-bottom: 64px
  position: relative
  @media (max-width: $mobile)
    padding-top: 30px
  @media (min-width: $desktop-md)
    padding-bottom: 96px
  .signup-tabs-heading
    text-align: center
    h2
      font-weight: bold
      margin-top: 0
      margin-bottom: 16px
  .signup-tabs-subheading
    margin-bottom: 64px
    p
      text-align: center
      font-size: 16px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      margin-top: 0
      margin-bottom: 16px
      @media (min-width: $tablet)
        margin-bottom: 8px

  .text-center
    text-align: center

  .desktop-target
    position: absolute
    top: -64px

  .signup-tabs-tabs
    @media (max-width: $mobile)
      margin-top: 64px
    .tabs-wrapper
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 72px
      @media (max-width: $mobile)
          margin-bottom: 64px
      .tab
        border: 2px solid #77BC1F
        width: 207px
        height: 40px
        color: #000
        margin: 0
        font-size: 16px
        padding: 7px 9px
        text-align: center
        cursor: pointer
        &:hover:not(.active)
          background: $border-light-grey
        @media (max-width: $mobile)
          font-size: 14px
          padding: 7px 2px
      .tab-left
        border-radius: 6px 0 0 6px
      .tab-right
        border-radius: 0 6px 6px 0
      .active
        background: $green
        span
          font-weight: 900
          color: $white

    .signup-tabs-bottom
      margin-top: 32px
      @media (max-width: $mobile)
        text-align: center
      @media screen and (min-width: $mobile) and (max-width: $desktop)
        max-width: 590px
        margin: 0 auto
      p
        margin: 0
        font-size: 14px

    .text-center
      text-align: center
      @media screen and (min-width: $mobile) and (max-width: $desktop)
        max-width: 500px

  .slick-slider
    display: flex
    flex-wrap: nowrap
    position: relative
    box-sizing: border-box
    user-select: none
    touch-action: pan-y
    -webkit-tap-highlight-color: transparent

  .slick-arrow
    padding: 0
    border: none
    box-shadow: none !important
    -webkit-appearance: none
    font-size: 0
    outline: none
    background: transparent
    user-select: none
    display: block
    position: absolute
    top: 250px
    z-index: 11
    svg
      cursor: pointer

  .slick-prev
    left: -15px

  .slick-next
    right: -15px

  .slick-list
    transform: translate3d(0, 0, 0)
    position: relative
    overflow: hidden
    display: block
    margin: 0
    padding: 0

  .slick-track
    position: relative
    left: 0
    top: 0
    display: block
    margin-left: auto
    margin-right: auto

  .slick-slide
    padding: 0 8px
    display: block
    float: left
    height: 100%
    min-height: 1px
    position: relative
    @media (min-width: $desktop-md)
      width: 33.3% !important
      padding: 0 8px
      &:last-child
        right: -8px
      &:first-child
        left: -8px

  .slick-dots
    list-style: none
    position: absolute
    top: -40px
    width: 100%
    justify-content: center
    bottom: -15px
    display: flex !important
    padding: 0
    margin: 0
    height: 10px
    li
      margin: 0 2px
      button
        @include square(6px)
        border-radius: 50%
        background: $bg-badge-rented-out
        padding: 0
        width: 6px
        box-shadow: none !important
        -webkit-appearance: none
        font-size: 0
        outline: none
        border: none
      &.slick-active button
        @include square(8px)
        background-color: $default
      &:last-child:not(.slick-active) button
        @include square(4px)

  .tabs__wrapper
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    align-items: stretch
    @media screen and (min-width: $tablet) and (max-width: $desktop - 1)
      margin: auto
      max-width: 590px
  .tab__wrapper
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
  .tab__block
    width: 284px
    max-width: 300px
    border: 1px solid #E0E2E2
    border-radius: 6px
    display: flex!important
    flex-direction: column
    justify-content: space-between
    position: relative
    background: #fff
    padding-bottom: 32px
    min-height: 530px
    box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
    @media screen and (min-width: $tablet) and (max-width: $desktop - 1)
      width: 284px
      margin-bottom: 32px
    @media (max-width: $mobile)
      margin: 0 auto
      width: 100%

    .green-block-wrapper
      position: absolute
      background: #F2FBE7
      color: #477907
      font-size: 16px
      line-height: 24px
      font-weight: bold
      margin-top: -40px
      text-align: center
      padding: 9px 0
      width: calc(100% + 2px)
      margin-left: -3px
      border-top-left-radius: 6px
      border-top-right-radius: 6px
      border: 1px solid #E0E2E2
      border-bottom: none
      @media (max-width: $mobile)
        position: inherit
        margin-top: 0
        border-top: none
      p
        margin-bottom: 0

    &.plus, &.premium-bottom
      border: 3px solid #6BBF00
      .tab__block__body
        .body-footer
          .main-button
            background: #FFA300
            color: $white
            &:hover
              background-color: #d17c0a
      .green-block-wrapper
        background: #6BBF00
        color: white
        width: calc(100% + 6px)
        border: none

    &.premium, &.premium-plus
      .green-block-wrapper
        margin-left: -1px

    &.premium-bottom
      .green-block-wrapper
        margin-left: -3px

    &.parkering
      @media (min-width: $desktop)
        min-height: 1016px

    &__head
      border-radius: 6px 6px 0 0
      text-align: center
      .heading-text
        text-transform: capitalize
        font-weight: bold
        font-size: 21px
        line-height: 24px
        display: flex
        flex-direction: column
        height: 115px
        padding: 32px 0 24px
        border-bottom: 1px solid #E0E2E2
        justify-content: center
        .subhead
          font-size: 18px
          color: $green
          font-weight: bold
          padding-top: 10px
          text-transform: initial
      .subheading-text
        height: 48px
        border-bottom: 1px solid #CBCECF
        background: #fff
        display: flex
        align-items: center
        justify-content: center
        font-size: 16px
        font-family: MuseoSans, Helvetica, Arial, sans-serif

    &__body
      margin-top: 24px
      text-align: center
      flex-grow: 1
      .plan-image
        margin-bottom: 20px
      .body-price
        min-height: 114px
        strong
          display: block
          font-size: 24px
          margin-bottom: 4px
          line-height: 34px
          span
            font-size: 24px
        .price-description-text
          font-family: MuseoSans, Helvetica, Arial, sans-serif
          margin-bottom: 8px
          display: block
          font-size: 16px
          &:last-child
            margin-bottom: 0

      .body-list
        margin: 0 24px
        border-top: 1px solid #E0E2E2
        ul
          font-size: 14px
          text-align: left
          margin-top: 32px
          padding-left: 0
          list-style-type: none
          li
            position: relative
            padding-left: 27px
            text-align: left
            width: 100%
            &:not(:last-child)
              margin-bottom: 16px
            &::before
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='%2377BC1F'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z'/%3E%3C/svg%3E")
              position: absolute
              left: 0
              top: 1px
            span
              font-family: MuseoSans, Helvetica, Arial, sans-serif
              font-size: 14px
        &__item-content
          @include displayFlex(center, center)
          svg
            margin-right: 8px
            min-width: 32px

      .body-footer
        .main-button
          display: inline-block
          background: $white
          font-size: 16px
          line-height: 42px
          font-weight: bold
          min-width: 236px
          border-radius: 6px
          margin: 32px 0
          text-decoration: none
          font-family: inherit
          cursor: pointer
          height: 42px
          color: $black
          border: 1px solid #FFA300
          &:hover
            background-color: #FFA300
            color: #fff
            transition: background ease 0.3s
          @media (max-width: $desktop - 1)
            min-width: 244px

    &__footer
      color: #313C3F
      text-align: center
      display: block
      width: 100%
      margin: 11px auto 0
      padding: 0 50px
      p
        margin: 0
        font-size: 14px
        line-height: 21px
        font-family: MuseoSans, Helvetica, Arial, sans-serif

  .two-columns-center
    justify-content: center
    column-gap: 20px

  .tab__block:nth-child(-n + 2)
    @media screen and (min-width: $tablet) and (max-width: $desktop - 1)
      margin-bottom: 72px


  .bottom-tabs-wrapper
    .signup-tabs-subheading
      margin-bottom: 60px
    .tab__block__body
      .body-footer
        .main-button
          background: #FFA300
          color: $white
          &:hover
            background-color: #d17c0a

#pay_per_showing, #lease
  .tabs__wrapper
    display: none
    @media (min-width: $tablet)
      display: flex
  .slick-slider
    @media (min-width: $tablet)
      display: none

#lease
  .basic
    border: 3px solid #6BBF00
    .main-button
      background: #FFA300
      color: #fff
      &:hover
        background-color: #d17c0a

    .plan-image
      display: none

.signup-tabs-wrapper
  .tab__wrapper
    .global-business-center-wrapper
      display: flex
      justify-content: center
      @media (min-width: $screen-md)
        width: 47%

      .basic
        border: 2px solid #77bc1f
        box-shadow: 0px 0px 14px 5px rgba(119, 188, 31, 0.25)
        @media (max-width: $screen-md)
          margin-bottom: 32px

      .body-price
        min-height: auto

      .signup-cta-button
        background: #ffa300
        color: #fff


    .meeting-rooms-wrapper
      width: 50%

      .body-price
        min-height: auto

      .signup-cta-button
        background: #ffa300
        color: #fff


  .global-title-wrapper
    display: flex

    .business-center-title, .meeting-rooms-title
      width: 50%
      text-align: center

  .global-bottom-wrapper
    display: flex

    .business-enter-bottom, .meeting-room-bottom
      width: 50%
      text-align: center





