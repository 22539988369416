@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.dv-gallery-map-fullscreen-header
  background: $white
  height: 0
  span
    display: none
  .map-mobile-fullscreen-btn
    @include displayFlex(center, center)
    @include positionAbsolute(0, 0)
    @include square(40px)
    background: none rgb(255, 255, 255)
    border: 0
    margin: 10px
    padding: 0
    cursor: pointer
    user-select: none
    border-radius: 2px
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px
    overflow: hidden
    svg
      fill: #656565

  &.active
    @include positionAbsolute(0, 0)
    @include boxSize(100vw, 55px)
    @include font(20px, $default, 24px)
    @include displayFlex(center, center)
    text-transform: capitalize
    border-bottom: 1px solid #E0E2E2
    span
      display: block
    .map-mobile-fullscreen-btn, .top
      top: 0 !important
      background: transparent
      box-shadow: none
      margin: 8px
      svg
        fill: $default

.map-container.fullscreen-mobile
  position: fixed !important
  width: 100vw !important
  height: 100vh !important
  z-index: 99999 !important
  top: 0
  left: 0
  .map-element
    height: 100%