// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.anonymous-badge,
.crawled-badge,
.rented-out-badge
  position: absolute
  bottom: 0
  text-align: center
  width: 100%
  z-index: 11
  font-weight: bold

.crawled-badge
  color: $white
  &__top-line
    background-color: $bg-badge-parsed
  &__bottom-line
    background-color: $default
    font-weight: 300

.anonymous-badge
  background-color: $bg-badge-anonymous
  color: $white

.rented-out-badge
  background-color: $bg-badge-rented-out

.info-badges__new-special-wrapper
  position: absolute
  display: flex

.new-badge, .special-offer-badge
  @include displayFlex(center, center)
  border-radius: 4px
  color: $white

.new-badge
  background: $bg-badge-new
  margin-right: 8px

.special-offer-badge
  background-color: $bg-badge-special-offer

