// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.testimonials
  margin-top: 64px
  padding-bottom: 24px
  @media (min-width: $desktop-md)
    padding-bottom: 0
    margin-top: 96px

  .slick-slider
    display: flex
    flex-wrap: nowrap
    position: relative
    box-sizing: border-box
    user-select: none
    touch-action: pan-y
    -webkit-tap-highlight-color: transparent

  .slick-arrow
    padding: 0
    border: none
    box-shadow: none !important
    -webkit-appearance: none
    font-size: 0
    outline: none
    background: transparent
    display: flex !important
    align-items: center
    user-select: none
    svg
      cursor: pointer
    &:hover
      svg
        fill: $green-hover
    @media (max-width: 480px)
      position: absolute
      height: 100%

  .slick-prev.slick-arrow.testimonials__prev-btn
    @media (max-width: 480px)
      left: -16px

  .slick-next.slick-arrow.testimonials__next-btn
    @media (max-width: 480px)
      right: -16px

  .slick-list
    transform: translate3d(0, 0, 0)
    position: relative
    overflow: hidden
    display: block
    margin: 0
    padding: 0

  .slick-track
    position: relative
    left: 0
    top: 0
    display: block
    margin-left: auto
    margin-right: auto
    height: 100%

  .slick-slide
    padding: 0 11px
    display: block
    float: left
    height: 100%
    min-height: 1px
    position: relative
    & > div
      height: 100%
    @media (min-width: $desktop-md)
      width: 33.3% !important
      padding: 0 8px
      &:last-child
        right: -8px
      &:first-child
        left: -8px
    @media (max-width: 480px)
      padding: 0 20px

  .slick-dots
    list-style: none
    position: absolute
    width: 100%
    justify-content: center
    bottom: -15px
    display: flex !important
    padding: 0
    margin: 0
    height: 10px
    li
      margin: 0 2px
      button
        @include square(6px)
        border-radius: 50%
        background: $bg-badge-rented-out
        padding: 0
        width: 6px
        box-shadow: none !important
        -webkit-appearance: none
        font-size: 0
        outline: none
        border-style: none
      &.slick-active button
        @include square(8px)
        background-color: $default
      &:last-child:not(.slick-active) button
        @include square(4px)

  &__desktop
    display: none
    @media (min-width: $desktop-md)
      display: flex

  .slick-slider
    @media (min-width: $desktop-md)
      display: none
