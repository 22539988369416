@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

$mediumContainerIndentation: calc((100vw - 736px) / 2)


.facilities-filter
  &__dropdown
    @include displayFlex(center, space-between)
    background: $white
    cursor: pointer
    margin: 0 16px
    border-bottom: 1px solid $border-form-input
    min-height: 55px
    padding: 15.5px 0
    .facilities-filter__select-links
      display: none
    span
      @include fontSLH(16px, 24px)
      display: flex
      @media (min-width: $desktop-md)
        white-space: nowrap
      span
        display: none
        margin-right: 0
        margin-left: 5px
        @media (min-width: $desktop-md)
          display: block
    @media (min-width: $tablet)
      margin: 0 24px
    @media screen and (min-width: $from-tablet) and (max-width: $screen-lg-md-max)
      margin: 0 $mediumContainerIndentation
    @media (min-width: $desktop-md)
      border: none
      padding: 20px 24px 0 24px
      margin: 0
      background: transparent
      min-height: 49px
      cursor: default
      justify-content: flex-start
      position: relative
      svg
        display: none
      span
        font-size: 20px
        margin-right: 16px
        font-weight: 900
      .facilities-filter__select-links
        display: flex

    @media (max-width: $screen-lg-md-max)
      &.open
        border-bottom: none
        span
          font-family: MuseoSans, sans-serif
          font-weight: 900

  &__list
    position: relative
    top: 0
    background-color: $border-light-grey
    box-shadow: none
    width: 100%
    z-index: 199
    transition: max-height ease 0.3s
    max-height: 0
    overflow: hidden
    &.open
      max-height: 10000px
      @media (min-width: $desktop-md)
        display: block
    @media screen and (min-width: $from-tablet) and (max-width: $screen-lg-md-max)
      margin: 0 $mediumContainerIndentation
      width: auto
    @media (min-width: $desktop-md)
      padding: 24px
      background-color: white
      border-bottom: none
      display: none
      width: 100%
      margin: 0
      border-radius: 4px
      border-bottom: 1px solid $bg-badge-rented-out

  &__top-wrapper
    @include displayFlex(center, space-between)
    margin-bottom: 24px
    padding: 24px 16px 0
    @media (min-width: $tablet)
      padding: 24px 24px 0
    @media (min-width: $desktop-md)
      display: none

  &__select-links
    display: flex
    div
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif
      cursor: pointer
    &--bottom
      margin-top: 8px
      padding: 0 16px 24px
      @media (min-width: $tablet)
        padding: 0 24px 24px
      @media (min-width: $desktop-md)
        display: none
    @media (min-width: $desktop-md)
      margin-left: 60px
      position: absolute
      right: 24px
      top: 22px

  &__select-all-link
    margin-right: 24px
    &:hover
      color: $green

  &__deselect-all-link
    &:hover
      color: $red

  &__suitable-for-block, &__facilities-block
    padding: 0 16px
    @media (min-width: $tablet)
      padding: 0 24px
    @media (min-width: $desktop-md)
      padding: 0

  &__list-headline
    @include font(16px, $default, 24px, bold)
    margin-bottom: 16px
    &.facilities
      margin-top: 8px

  &__facilities-list,
  &__suitable-for-list
    display: flex
    flex-wrap: wrap

  &__list-item
    display: block
    width: 100%
    @media (min-width: $tablet)
      width: 50%
    @media (min-width: $desktop-md)
      width: 33.333%
    label
      @include font(16px, 24px)
      font-family: MuseoSans, sans-serif
      display: inline-flex
      align-items: center
      cursor: pointer
      margin-bottom: 16px
      position: relative
      &:before
        @include square(20px)
        @include displayFlex(center, center)
        content: ''
        color: $white
        font-size: 16px
        background: white
        border: 1px solid $border-form-input
        border-radius: 2px
        position: absolute
        left: 0
        top: 2px
      svg
        display: none
        fill: $white
        position: absolute
        left: 1px
        top: 2px
      &.active
        svg
          display: block
        &:before
          background: $green
          border-color: $green
      input
        @include square(10px)
        margin-right: 18px
        margin-left: 2px
        visibility: hidden

  &__done-button
    display: none
    cursor: pointer
    float: right
    margin-right: 18px
    span
      @include font(16px, $green, 24px)
      font-family: MuseoSans, sans-serif
      &:hover
        color: $green-hover
    @media (min-width: $desktop-md)
      display: inline-flex

  .dropdown-selector__separator
    @media (min-width: $from-tablet)
      margin: 0

  &__show-more-link
    @include font(16px, $green, 24px)
    margin-top: 8px
    cursor: pointer
    text-decoration: underline
    &:hover
      color: $green-hover
