// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.image-gallery-landscape
  &__button
    @include fontSLH(14px !important, 16px)
    width: 90% !important
    max-width: 330px
    margin: auto
    padding-left: unset !important
    margin-top: 20px
    text-transform: none !important
    display: flex !important
    justify-content: center
    align-items: center
    min-height: 40px !important
    &:before
      background: none !important
    @media (min-width: $tablet)
      @include fontSLH(20px !important, 24px)
      margin-top: 32px
    @media screen and (min-width: $tablet) and (max-width: $desktop-md)
      margin-top: 24px
    @media (max-width: $mobile)
      width: 60%

.image-gallery-landscape-wrapper
  @media screen and (min-width: $tablet) and (max-width: $desktop-md)
    height: 100%
.image-gallery-landscape-text-heading
  color: $white
  font-size: 32px

.image-gallery-landscape-background
  @include fullWidthSize(224px)
  @include displayFlex(center, center)
  min-height: 224px
  cursor: default !important
  background-size: cover
  background-position: center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  @media screen and (min-width: $tablet) and (max-width: $desktop-md)
    height: 334px
  @media (min-width: $desktop-md)
    height: 520px
    min-height: 400px

.image-gallery-landscape-text
  margin: auto
  text-align: center
  color: $white
  width: 100%
  @media screen and (min-width: $tablet) and (max-width: $desktop-md)
    height: 100%
  h3
    font-size: 28px
    font-weight: bold
    white-space: normal
    margin-bottom: 40px
    @media (min-width: $tablet)
      font-size: 30px
      line-height: 24px
    @media screen and (min-width: $tablet) and (max-width: $desktop-md)
      margin-top: 64px
      margin-bottom: 16px
    @media (min-width: $desktop)
      margin-top: 64px
    @media (min-width: $desktop-md)
      font-size: 48px

  @media (max-width: $mobile)
    p
      @include fontSLH(12px, 16px)
    h3
      font-size: 24px
      margin-bottom: 20px


.image-gallery-landscape-text-middle
  font-size: 20px
  white-space: normal
  width: 55%
  margin: 0 auto
  font-family: MuseoSans, Helvetica, Arial, sans-serif
  @media screen and (min-width: $tablet) and (max-width: $desktop-md)
    font-size: 18px
    line-height: 24px
    width: 300px
  &:hover
    cursor: pointer

.image-gallery-landscape-text-buttom
  padding-top: 16px
  font-size: 18px
  font-family: MuseoSans, Helvetica, Arial, sans-serif
  line-height: 24px
  margin: 0
