
.collapse:not(.show)
  display: none

.collapsing
  position: relative
  height: 0
  overflow: hidden
  -webkit-transition: height .35s ease
  transition: height .35s ease
