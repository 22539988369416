// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.call-modal
  &__wrapper
    padding: 24px 16px
    @media (min-width: $tablet)
      @include displayFlex(flex-start)
      padding: 32px 48px

  &__wrapper--success
    justify-content: space-between

  &__close-btn
    @include closeBtnRight(16px, 16px)
    @media (min-width: $tablet)
      top: 24px
      right: 24px

  &__img
    @media (min-width: $tablet)
      @include square(144px)
      margin-right: 24px
      background-image: url('../../../src/images/list_office/JakobDalhoff.jpg')
      background-size: 320px
      background-position: -79px -18px
      border-radius: 50%
      min-width: 144px
    @media (min-width: $desktop-md)
      margin-right: 32px

  &__form
    @media (min-width: $desktop)
      display: flex

  h2
    padding-right: 28px
    margin-bottom: 16px

  &__thank-close-button
    width: 100%
    height: 48px
    font-size: 16px
    background-color: $orange
    margin-top: 16px
    border-radius: 6px
    border: none
    position: relative
    font-weight: bold
    padding: 12px 24px
    display: inline-block
    color: white
    text-decoration: none
    @media (min-width: $tablet)
      margin-top: 24px
    &:hover
      background-color: $orange-hover

  &__icon-wrapper
    display: none
    @media (min-width: $tablet)
      display: block

  p
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    margin-bottom: 16px
    &.call-modal__thanks-content
      margin-bottom: 0

  input
    @include fullWidthSize(52px)
    @include font(1rem, $black, 21px)
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    padding: 24px 16px 10px 16px
    font-family: MuseoSans,Helvetica,Arial,sans-serif
    font-size: 14px
    &::placeholder
      color: $black
    &.call-modal__input-error
      border-color: $red
    &:focus, &.active, &.not-empty
      &~.call-modal__form-floating-label
        font-size: 12px
        transform: translate(0, -12px)
        font-family: MuseoSans, sans-serif
        font-weight: 300

  &__form-floating-label
    @include positionAbsolute(14px, $left: 16px)
    @include font(14px, $black, 24px)
    transition: all ease-in-out 0.3s
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    pointer-events: none

  &__form-label
    width: 100%
    margin: 0

  .form-group
    margin-bottom: 16px
    &.call-modal__form-group-error
      .call-modal__form-input
        border-color: $red !important
      .call-modal__form-input-tooltip-error
        display: block !important
      &:before
        color: $red
    @media (min-width: $desktop)
      &:first-child
        margin-bottom: 0
        margin-right: 16px
        width: 100%

  &__form-input-tooltip-error
    @include font(12px, $color: $red, $lineHeight: 19px, $fontWeight: 300)
    text-align: left

  &__servers-error
    margin-top: 10px
    color: $red

  &__form-btn-submit
    margin-bottom: 0 !important
    @media (min-width: $desktop)
      margin-right: 0
      width: 236px
      min-width: 236px
    button
      @include font(16px, $white, 24px, bold)
      @include boxSize(100%, 48px)
      text-transform: none
      margin-bottom: 0
      white-space: normal
      cursor: pointer
      padding: 12px 24px
      text-align: center
      background-color: $orange
      border-radius: 4px
      display: inline-block
      vertical-align: middle
      touch-action: manipulation
      background-image: none
      border: 1px solid transparent
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      @media (min-width: $tablet)
        height: 52px
      &:disabled
        background-color: $orange-hover
      div
        position: relative
        height: 100%
        width: 100%
        display: flex
        justify-content: center
        align-items: center

  input::-webkit-credentials-auto-fill-button,
  input::-webkit-contacts-auto-fill-button
    visibility: hidden
    pointer-events: none
    position: absolute
    right: 0

  input::-webkit-contacts-auto-fill-button
    display: none !important

.ReactModal__Content.call-modal
  width: calc(100% - 32px) !important
  @media (min-width: $tablet)
    min-width: 597px !important
  @media (min-width: $desktop-md)
    min-width: 635px !important
  @media (min-width: $desktop)
    min-width: 784px !important

.ReactModalPortal
  .ReactModal__Overlay
    @media (min-width: $desktop-md)
      top: 0 !important
