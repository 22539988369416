@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.circular-chart-wrapper
  @include square(300px)

.circular-chart
  display: block
  margin: 10px auto
  max-width: 80%
  max-height: 250px

.circle-bg
  fill: none
  stroke: #eee
  stroke-width: 3.8

.circle
  fill: none
  stroke-width: 2.8
  stroke-linecap: round
  animation: progress 1.5s ease-out forwards

@keyframes progress
  0%
    stroke-dasharray: 0 100

.circular-chart.green
  background: $white
  .circle
    stroke: $green

.percentage
  fill: #666
  font-family: sans-serif
  font-size: 0.5em
  text-anchor: middle
