// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.form-wrapper
  display: none
  @media (min-width: $tablet)
    display: block
    position: relative
  &--mobile
    display: block
    @media (min-width: $tablet)
      display: none

.order-form
  z-index: 1
  margin-right: 0
  position: static
  @media (min-width: $tablet)
    position: absolute

  #contactFormScrollTarget
    @media (min-width: $tablet)
      display: none

  & *
    box-sizing: border-box

  &__wrapper
    background-color: #EEF9DF
    border: 1px solid rgba(119, 188, 31, 0.30)
    box-shadow: 0px 12px 32px -16px rgba(70, 103, 27, 0.30)
    margin-right: 0
    border-radius: 4px
    padding: 32px
    margin-bottom: 32px
    @media (max-width: $screen-lg-md-max)
      padding: 24px 16px
    &.highlight
      animation: highlight ease 0.8s infinite
      border: 1px solid #77BC1F
      box-shadow: 0px 0px 12px 4px rgba(119, 188, 31, 0.16)
      animation-iteration-count: 4

  @keyframes highlight
    0%
      box-shadow: 0 1px 4px rgba(49, 60, 63, 0.25)
      border: 1px solid transparent

  &__section
    position: relative
    p
      margin-bottom: 0
      margin-top: 16px
      font-size: 16px
      line-height: 24px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
    .order-form__label
      margin-bottom: 24px

  &__section-wrapper
    position: static
    &.active
      position: relative
    &:last-child
      margin-bottom: -15px
    &:last-child
      margin-bottom: 0
    .form-section .form-group
      width: 100%
      display: inline-block
      margin-bottom: 0
      margin-top: 11px
      //&.jeudan-policy-checkbox
      //  margin-bottom: 20px
      //  margin-top: 10px
      //  @include font(10px, $default, 14px)

    .input-wrapper
      &> .form-group:first-child
        margin-top: 16px
    .close-section
      @include positionAbsolute(15px, 15px)
      cursor: pointer
      fill: #B3B7B8

  &__title
    @include font(21px, $default, 30px, bold)
    padding-bottom: 16px
    padding-right: 20px

  &__label
    font-size: 16px
    font-family: MuseoSans, Helvetica, Arial, sans-serif

  &__description-under-options
    margin-top: 16px
    //margin-bottom: 0 !important


  //.jeudan-policy-checkbox
  //  &__wrapper
  //    display: flex
  //    &:before
  //      visibility: visible
  //      @include beforeEmptySquare(17px, $white, 0, $left: 0)
  //      border: 2px solid black
  //      border-radius: 2px
  //      display: block
  //      left: 0
  //      pointer-events: none
  //      z-index: 1
  //      top: 2px
  //    &.checked:before
  //      background: $green
  //      border-color: $green
  //    &.error:before
  //      border-color: $light-red
  //
  //  input
  //    @include positionAbsolute(2px, $left: 0)
  //    font-size: 20px
  //    margin-right: 5px
  //    cursor: pointer
  //    border-color: transparent
  //    background-color: transparent
  //    opacity: 0
  //  &__label
  //    @include font(10px, $default, 14px)
  //    margin-left: 25px
  //    font-weight: 300
  //  &__label-read-more-link
  //    margin-left: 5px
  //    color: $green
  //    text-transform: lowercase
  //    cursor: pointer
  //    text-decoration: underline
    //.validation-message--jeudan
    //  margin-top: 8px
    //  line-height: 14px
    //  font-weight: 300

  .select-option-group
    position: relative
    padding-left: 32px
    .select-option
      @include font(16px, $bg-charcoal, 24px)
      cursor: pointer
      margin-bottom: 16px
      font-family: MuseoSans, Helvetica, Arial, sans-serif
    .option-checkmark
      @include positionAbsolute(2px, $left: 0)
      @include square(20px)
      background-color: $white
      border-radius: 50%
      border: 2px solid $gull-grey-text
      &:after
        @include beforeEmptySquare(12px, $bg-badge-new, 2px, $left: 2px)
        border-radius: 50%
        display: none
    input
      opacity: 0
      cursor: pointer
      display: none
      &:checked
        ~ .select-option
          .option-checkmark
            border: 2px solid $bg-badge-new
            &:after
              display: block
    &:hover
      .option-checkmark
        border: 2px solid $green
    &.checked
      .option-checkmark
        border: 2px solid $bg-badge-new
        &:after
          display: block

  .order-form__wrapper
    .select-option-group
      input
        &:checked
          ~ .select-option
              color: $default !important


  &__title--subtitle
    color: $default
    font-size: 16px


  &__collapsible-wrapper
    .form-collapsible
      overflow: hidden
    .form-collapsed
      opacity: 1
    .form-collapsing
      opacity: 1
      transition: all ease .5s
    .form-uncollapsing
      opacity: 1
      transition: all ease .5s
    .form-visible-overflow
      overflow: visible

  &__input
    border-radius: 0

  &__textarea
    border-radius: 0
    resize: none

  &__button-wrapper
    margin-top: 11px
    &.active
      background-color: $grey
      padding-bottom: 15px

  &__info-wrapper
    @include displayFlex(center)
    margin-top: 15px
    svg
      fill: $green
      margin-right: 10px
    span
      font-size: 14px
      font-weight: 300
      display: inline-block
      cursor: pointer
      color: $green
      text-decoration: underline
      &:hover
        text-decoration: underline
        color: $green-hover

.input-wrapper
  .order-form__input
    height: 52px
    padding-top: 12px
    background-image: none
  .order-form__textarea
    background-image: none
  .floating-label
    @include positionAbsolute(0, $left: 11px)
    @include font(14px, $black, 24px)
    font-family: MuseoSans,Helvetica,Arial,sans-serif
    transition: 0.2s ease all
    pointer-events: none
    display: flex
    top: 0
    align-items: center
    height: 52px
  textarea ~ .floating-label
    height: 56px
  .order-input-valid ~ .floating-label,
  .error ~ .floating-label,
  input:focus ~ .floating-label,
  textarea:focus ~ .floating-label,
  .not-empty ~ .floating-label
    @include positionAbsolute(2px, $left: 0.75rem)
    opacity: 1
    color: $default
    font-size: 12px
    line-height: 20px
    align-items: flex-start
  .order-input-valid ~ .disappear-label,
  .error ~ .disappear-label,
  input:focus ~ .disappear-label,
  textarea:focus ~ .disappear-label,
  .not-empty ~ .disappear-label
    display: none

label
  display: inline-block
  margin-bottom: .5rem


.order-form__button
  border-radius: 4px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  position: relative
  font-size: 16px
  line-height: 24px
  min-height: 51px
  width: 100%
  transition: background-color ease .3s
  background-color: $orange
  pointer-events: auto
  color: $white
  border: none
  outline: none
  font-weight: 900
  // for IE11
  &:after
    content: ''
    min-height: inherit
    font-size: 0
  svg
    fill: $white

  &.active
    pointer-events: none
    padding-left: 0
    background: $dark-orange
    &:before
      background-image: none

  &:focus, &:active, &:focus-within
    background-color: $dark-orange
    color: $white
    border-color: $dark-orange

  @media (min-width: 769px)
    &:hover
      background-color: $dark-orange
      color: $white
      border-color: $dark-orange


  @media only screen and (min-width : $tablet) and (max-width : $desktop-md)
    span
      margin-left: 15px
