// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.combined-leases
  padding-top: 64px
  padding-bottom: 32px
  @media (min-width: $tablet)
    padding-top: 32px
  @media (min-width: $desktop)
    padding-top: 40px

  &__title
    margin-bottom: 8px
    line-height: 19px

  &__row
    padding-top: 24px
    &:last-child
      padding-bottom: 0
      .combined-leases__border
        display: none

  &__col
    @include fontSLH(16px, 24px)
    font-weight: 900
    &:first-child
      padding-bottom: 16px
      font-weight: 500

  &__label
    font-weight: 300
    padding-top: 2px

  &__border
    padding-top: 24px
    border-bottom: 1px solid $bg-badge-rented-out
    @media (min-width: $tablet)
      padding-top: 16px
    @media (min-width: $desktop)
      width: 50%

  &__kind
    text-transform: capitalize

