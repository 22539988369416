// MOBILE_FIRST
@import '../../src/stylesheets/variables'


.order-form__button
  border-radius: 4px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  position: relative
  font-size: 16px
  line-height: 24px
  min-height: 51px
  width: 100%
  transition: background-color ease .3s
  background-color: $orange
  pointer-events: auto
  color: $white
  border: none
  outline: none
  font-weight: 900
  // for IE11
  &:after
    content: ''
    min-height: inherit
    font-size: 0
  svg
    fill: $white

  &.active
    pointer-events: none
    padding-left: 0
    background: $dark-orange
    &:before
      background-image: none

  &:focus, &:active, &:focus-within
    background-color: $dark-orange
    color: $white
    border-color: $dark-orange

  @media (min-width: 769px)
    &:hover
      background-color: $dark-orange
      color: $white
      border-color: $dark-orange


  @media only screen and (min-width : $tablet) and (max-width : $desktop-md)
    span
      margin-left: 15px
