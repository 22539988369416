// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.facts-and-facilities
  padding-bottom: 64px
  font-family: MuseoSans, san-serif

  h3
    @include fontSLH(16px, 26px)
    @media (min-width: $desktop-md)
      font-size: 18px

  &__column
    margin-bottom: 40px

  &__area-link
    display: flex
    align-items: center
    margin: 0 !important
    text-decoration: underline
    &:hover
      text-decoration: none
      span
        text-decoration: underline

  &__build-route-link
    display: flex
    align-items: center
    margin: 0 !important
    text-decoration: underline
    color: black
    &:hover
      text-decoration: none
      span
        text-decoration: underline

  &__address-line
    @include displayFlex(flex-start)
    p
      margin-bottom: 4px
    p, a
      @include fontSLH(16px, 24px)
    svg
      margin-right: 8px

  &__city-address
    font-weight: bold

  &__energy-rating-wrapper
    @include fontSLH(16px, 24px)
    @include displayFlex(center)
    padding-top: 16px
    svg, img
      margin-right: 8px

  &__build-route-wrapper
    @include fontSLH(16px, 24px)
    @include displayFlex(center)
    padding-top: 16px
    i, img
      margin-right: 8px

  &__energy-rating-icon
    display: inline-block

  &__build-route-icon
    @include square(20px)
    display: flex
    margin-right: 8px
    svg
      fill: #4285F4

  &__transport-icon
    @include square(20px)
    display: inline-block
    margin-right: 10px

  &__blank-title
    @include fontSLH(16px, 24px)
    margin-top: 16px
    @media (min-width: $desktop-md)
      margin-top: 0
    &.with-indentation
      padding-top: 24px
      margin-top: 0

  &__transport-list
    h3
      margin-bottom: 8px

  &__list-item, &__suitable-for-item
    @include fontSLH(14px, 24px)
    display: flex
    margin-top: 16px
    @media (min-width: $tablet)
      font-size: 16px
    img
      margin-right: 8px

  &__list-item
    align-items: flex-start

  &__suitable-for-item
    align-items: center

  &__transport-distance
    font-weight: bold
    white-space: nowrap
    font-family: MuseoSans, san-serif
    @media (min-width: $desktop)
      margin-right: 25%

  &__icon-wrapper
    min-width: 18px

  &__distance-item-type
    display: flex
    margin-right: 10px

  &__facilities-wrapper
    overflow: hidden
    transition: all .3s ease
    width: 100%
    &.distances
      margin-bottom: 40px
      .facts-and-facilities__list-item
        justify-content: space-between

  &__facilities-type-headline
    @include font(16px, $default, 24px, bold)
    font-family: MuseoSans, san-serif
    margin: 24px 0 -8px 0

  &__suitable-for-icon
    margin-right: 8px
    fill: #0FADA2

  &__list-item-icon
    min-width: 20px
    height: 100%
    margin-right: 10px
    background-repeat: no-repeat
    background-position-x: center
    background-position-y: 2px
    background-size: contain

  &__facilities-toggle-link-wrapper
    @include displayFlex(center)
    @include font(16px, $green, 24px)
    margin-top: 16px
    cursor: pointer
    width: fit-content
    span
      text-decoration: underline
    svg
      fill: $green
      margin-left: 5px
    &:hover
      svg
        fill: $green-hover
      span
        color: $green-hover

  &__headline
    margin-bottom: 0
    @media (min-width: $desktop-md)
      margin-bottom: 8px

  &__headline--facilities
    margin-bottom: -16px
    @media (min-width: $desktop-md)
      margin-bottom: 0

  &__suitable-for-label
    text-transform: capitalize

  &__parking-block
    margin-bottom: 40px
    .parking-link
      margin-top: 0

  &__parking-wrapper
    margin-bottom: 24px
    &:last-child
      margin-bottom: 16px

  &__parking-text-wrapper
    display: flex
    justify-content: space-between

  &__parking-links
    @include font(16px, $default, 24px)

  &__parking-distance
    font-weight: bold
    white-space: nowrap

  &__parking-icon
    height: 24px
    width: 24px
    margin-bottom: 8px

  &__energy-certificate-wrapper
    @include displayFlex(center)
    margin-bottom: 16px
    line-height: 24px
    @media (min-width: $tablet)
      font-size: 16px
    &:first-child
      margin-top: 16px
    &:last-child
      margin-bottom: 40px
    img
      margin-right: 8px

