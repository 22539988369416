// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.get-contact
  position: relative
  background: $white
  @media (min-width: 1025px)
    border-radius: 4px
    padding: 32px

  &__mobile-header
    @include fontSLH(16px, 24px)
    @media (min-width: $tablet)
      @include fontSLH(21px, 32px)
      margin-bottom: 8px

  &__header
    @include fontSLH(16px, 24px)
    margin-bottom: 8px
    @media (min-width: $tablet)
      @include fontSLH(21px, 32px)
    @media (min-width: $desktop-md)
      font-size: 24px
      margin-bottom: 16px
    h2
      @include fontSLH(24px, 32px)
      width: 90%
      margin-bottom: 0
    p
      font-size: 20px
      margin-bottom: 0

  &__close
    @include closeBtnRight(16px, 16px)
    display: none !important
    @media (min-width: $tablet)
      top: 24px
      right: 24px
    @media (min-width: $desktop-md)
      display: block !important
      right: 15px
      &--success
        right: 32px
    @media (min-width: $desktop)
      right: 24px
      &--success
        right: 24px

  &__title-section
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid #eee
    padding: 16px 20px
    .modal-close
      height: 24px

  &__title
    p
      margin-bottom: 0

  &__body
    padding: 16px
    @media (min-width: $tablet)
      padding: 24px
    @media (min-width: 1025px)
      padding: 0

  &__description
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    margin-bottom: 24px

  &__form
    display: flex
    flex-direction: column
    position: relative
    @media (min-width: $tablet)
      flex-direction: row
      justify-content: space-between
      & > div
        width: calc(50% - 8px)

  .floating-label
    @include positionAbsolute(15px, $left: 16px)
    font-size: 14px
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    line-height: 24px
    color: $black
    transition: all ease-in-out 0.3s
    pointer-events: none
    &.active
      font-size: 12px
      transform: translate(0, -12px)
      font-family: MuseoSans, sans-serif

  input
    @include fullWidthSize(52px)
    @include font(14px, $black, 16px)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 0 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    @media (min-width: $tablet)
      height: 54px
    @media (min-width: $desktop-md)
      height: 52px
    &:focus, &.filled
      padding-top: 15px
      & ~ .floating-label
        font-size: 12px
        transform: translate(0, -12px)
        font-family: MuseoSans, sans-serif
    &.error
      border-color: $light-red
      margin-bottom: 8px
      & ~ .error-tooltip
        display: block
    &::placeholder
      color: $gull-grey-text
    &.sign-up-form__input-error
      border-color: $light-red
    input::-webkit-credentials-auto-fill-button
      visibility: hidden
      pointer-events: none
      position: absolute
      right: 0

  .error-tooltip
    @include fontSLH(12px, 20px)
    font-weight: 300
    color: $light-red
    display: none

  .form-group
    margin-bottom: 16px
    display: flex
    flex-direction: column

  textarea
    @include font(14px, $black, 24px)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    min-height: 120px
    width: 100%
    resize: vertical
    @media (min-width: $tablet)
      min-height: 124px
    @media (min-width: $desktop-md)
      min-height: 120px
    &:focus, &.filled
      padding-top: 15px
      & ~ .floating-label
        display: none
    & ~ .floating-label
      min-width: 250px

  .form-submit-section
    .form-set-info-submit
      margin-bottom: 16px
      width: 100%
      font-size: 14px
      height: 48px
    p
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, Helvetica, Arial, sans-serif


.formsent
  .content-wrapper
    display: flex
    justify-content: space-between
    align-items: center
  .modal-close
    position: absolute
    right: 20px
    top: 20px
    cursor: pointer
  .main-header
    .main-title
      @include fontSLH(24px, 32px)
      font-weight: bold
      margin-bottom: 16px
    p
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, san-serif

  .icon-wrapper
    margin-right: 20px


.server-error-message
  margin-top: 38px
  margin-bottom: 24px
  background: rgba(255,90,90,0.09)
  padding: 24px
  display: flex
  align-items: center
  border-radius: 4px
  @media (max-width: $tablet)
    margin-top: 0
    padding: 18px
  @media (max-width: $mobile)
    margin-bottom: 16px
  img
    margin-right: 18px
  a
    text-decoration: underline
  p
    margin-bottom: 0
    @include fontSLH(16px, 24px)
.not-showing
  display: none


.ReactModalPortal
  .ReactModal__Overlay
    .ReactModal__Content
      width: 101%
      margin-bottom: 16px
      @media (min-width: $tablet)
        width: 100%
        min-width: 100%
      @media (min-width: 1025px)
        width: 635px
        min-width: 635px
      @media (min-width: $desktop)
        width: 784px
        min-width: 784px


.ReactModal__Content.thank-you-modal
  width: calc(100% - 32px) !important
  @media (min-width: $tablet)
    min-width: 597px !important
  @media (min-width: $desktop-md)
    min-width: 635px !important
  @media (min-width: $desktop)
    min-width: 784px !important
