@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.fullscreen-gallery
  position: absolute
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  background: $white
  z-index: 9999
  overflow: hidden

  &__close-btn
    @include displayFlex(center, center)
    @include square(40px)
    border-radius: 32px
    border: 1px solid rgba(0, 0, 0, 0.20)
    background: $white
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.10)
    position: fixed
    top: 32px
    right: 24px
    cursor: pointer
    @media (min-width: $tablet)
      right: 32px
      top: 48px
    @media (min-width: $desktop-md)
      right: calc(((100vw - 912px) / 2) + 16px)

  &__wrapper
    height: 100%
    overflow-y: scroll
    scroll-behavior: smooth

  &__container
    width: 100vw
    padding: 24px 16px 50px
    @media (min-width: $tablet)
      padding: 40px 24px 81px
    @media (min-width: $desktop-md)
      padding: 40px 0 81px
      margin: auto
      width: 912px

  &__img-wrapper
    width: 100%
    margin-bottom: 16px
    display: flex
    min-height: 200px
    &:last-child
      margin-bottom: 0

  img
    width: auto
    height: auto
    border-radius: 4px
    max-width: 100%
    max-height: calc(100vh - 32px)
    margin: auto

