@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.cookie-consent-policy
  width: 80%
  position: fixed
  top: 100px
  left: 10%
  background-color: $white
  font-family: sans-serif
  padding: 50px
  border-radius: 5px
  box-sizing: border-box
  box-shadow: 4px 4px 20px 0 rgba(50, 50, 50, 0.5)

.cookie-consent-dimmed-wrapper
  @include square(100%)
  position: fixed
  top: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 2147483647

.cookie-consent-content
  @include displayFlex(center, space-between)
  background-repeat: no-repeat
  background-size: 14px 14px
  background-position: left center

.flex-main
  flex-grow: 1

.cookie-consent-wrapper
  @include font(12px, $white, 14px, 300)
  width: 100%
  margin: 0
  padding: 10px
  box-sizing: border-box
  font-family: sans-serif
  z-index: 2147483646

  a
    color: $white
    text-decoration: underline

.cookie-consent-confirm-box
  color: white
  font-weight: 900
  display: inline-block
  margin-left: 10px

  &:hover
    cursor: pointer

.cookie-consent-wrapper

  &.dark
    background-color: $bg-charcoal

.top-menu
  background-color: $white
  box-shadow: 0 1px 4px $dark-quater-alpha
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 999
  margin: 0
  //.container
  //  @media screen and (max-width: $screen-xs)
  //    padding: 0
  //.row
  //  margin: 0
  //  @media screen and (min-width: $screen-xs) and (max-width: $screen-md)
  //    margin-left: -15px
  //    margin-right: -15px
