@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.calendly-button-section
  margin-top: 32px
  @media (min-width: $desktop-md)
    margin-top: 96px

  .calendly-button-wrapper
    background: $border-light-grey
    padding: 32px
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column-reverse
    border-radius: 4px
    @media (min-width: $tablet)
      flex-direction: row
    .calendly-button-block
      width: 100%
      @media (min-width: $tablet)
        width: auto
    .calendly-button
      display: block
      background-color: $green
      border-radius: 4px
      padding: 12px 42px
      font-size: 16px
      font-weight: bold
      color: #fff
      line-height: 24px
      text-decoration: none
      white-space: nowrap
      cursor: pointer
      text-align: center
      &:hover
        background-color: $green-hover

    .calendly-description-text
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      font-size: 16px
      line-height: 24px
      margin-top: 0
      margin-bottom: 16px
      margin-left: 0
      @media screen and (min-width: $mobile) and (max-width: $desktop-md)
        margin-left: 36px
        margin-bottom: 0
      @media (min-width: $desktop-md)
        margin-bottom: 0
        margin-left: 62px

// when we add second block with book button

//.calendly-button-section-second
//  margin-top: 64px
