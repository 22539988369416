// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.favorite-item
  margin-bottom: 32px

  .compare-icon__icon
    @include square(24px)

  &__link
    text-decoration: none
    display: block
    position: relative
    height: 100%
    &:hover
      text-decoration: none
      box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)
      -webkit-box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)

  &__check-box
    @include positionAbsolute(16px, 12px)
    @include square(24px)
    border-radius: 4px
    background-color: white
    z-index: 1
    color: $default
    padding: 0 3px
    border: 1px $default solid
    svg
      position: relative
      pointer-events: none

  p.favorite-item__date
    @include font(14px, $default, 24px)
    font-family: MuseoSans, sans-serif
    margin-bottom: 8px

  &__image-wrapper
    @include fullWidthSize(207px)
    @include displayFlex(center)
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    position: relative
    overflow: hidden
    @media (min-width: $tablet)
      height: 200px
    @media (min-width: $desktop-md)
      height: 163px

  &__image
    width: 100%
    height: auto
    min-height: 100%

  &__labels-wrapper
    @include positionAbsolute($right: 0, $bottom: 0)
    width: 100%

  &__label
    @include displayFlex(center, center)
    padding: 5px 16px
    span
      @include fontSLH(14px, 21px)
      display: block
    &--rented-out
      background: #E0E2E2
      span
        font-weight: 900
        color: $default
    &--anonymous
      background: #3130AF
      span
        font-weight: 900
        color: $white
    &--parsed-top
      background: #0C5887
      span
        font-weight: 900
        color: $white
    &--parsed-bottom
      background: $default
      span
        color: $white
        text-align: center

  &__badges-wrapper
    position: absolute
    top: 16px
    left: 12px
    display: flex
    flex-direction: column

  &__badge
    padding: 4px 12px
    border-radius: 4px
    display: inline-block
    width: fit-content
    span
      @include font(16px, $white, 24px, 900)
      display: block
    &--new
      background: #67A700
      margin-bottom: 6px
    &--special
      background: #7B3395

  &__info-block-wrapper
    padding: 8px 16px 16px 16px
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    border: 1px solid $bg-badge-rented-out
    border-top: none
    height: calc(100% - 207px)
    background: $white
    @media (min-width: $tablet)
      height: calc(100% - 200px)
    @media (min-width: $desktop-md)
      height: calc(100% - 163px)

  &__location-address
    margin-bottom: 12px
    p
      @include font(16px, $default, 26px, bold)
      @include truncate()
      margin: 0

  &__location-kinds
    display: flex
    margin-bottom: 5px
    span
      @include font(14px, $default, 24px)
      font-weight: 300
      &:before
        content: '·'
        margin: 0 8px
      &:first-child:before
        display: none

  &__info-wrapper
    margin-top: 8px

  &__info-row
    @include displayFlex($justifyContent: space-between)
    display: flex
    width: 100%
    p
      @include font(14px, $default, 24px)
      margin: 0
      max-width: 50%
      img
        margin-right: 10px
    &--price
      margin-bottom: 16px
      @media (min-width: $tablet)
        min-height: 48px

  &__info-value
    -ms-text-align-last: right
    text-align: right

  &__bottom
    height: 51px

  &__button-wrapper
    position: absolute
    width: calc(100% - 32px)
    bottom: 16px
    left: 16px

  .b-button
    @include fontSLH(14px, 21px)
    height: 51px
    border-radius: 5px
    width: 100%
    color: $default !important
    background: $white
    border: 2px solid $orange
    &:hover
      background: $white
      border-color: $orange-hover
    &.sent-info
      background-color: $orange-hover
      border-color: $orange-hover
      color: $white !important
      pointer-events: none
      svg
        margin-right: 10px
    font
      pointer-events: none

.not-showing
  display: none

