// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.advertisement-wrap
  .search-agent-column-no-padding-left
    padding-left: 0 !important
    border-top: 1px solid #E0E2E2
    border-bottom: 1px solid #E0E2E2
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
  .list-item__description-wrapper
    padding: 16px 16px 7px
    @media (min-width: $desktop-md)
      padding: 32px 16px 10px

  .list-item__description-title
    margin-bottom: 5px
    @media (min-width: $desktop-md)
      margin-bottom: 10px
    p
      white-space: normal

  .description-line
    margin: 0
    span
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif

  .adver-description-tablet
    @media (min-width: $desktop-md)
      display: none

  .adver-description-desktop
    display: none
    @media (min-width: $desktop-md)
      display: block

  .adver-title
    @include font(16px, $white, 24px)
    font-family: MuseoSans, sans-serif
    margin-bottom: 0
    margin-top: 0

  span.adver-title
    font-family: MuseoSans, sans-serif

  .link-for-mobile-version
    display: block
    div
      @include fontSLH(10px, 16px)
      text-decoration: underline
    @media (min-width: $tablet)
      display: none

  .list-item__get-info-button
    background: $green
    text-transform: none
    padding: 5px 16px
    line-height: 26px
    min-width: 173px
    margin: 0 auto
    display: inline-block
    min-height: 36px
    &:hover
      background: $green-hover

  .list-item__metrics-wrapper
    padding: 48px 16px 20px

  .list-item__bottom
    position: absolute
    bottom: 2.5px

  .advertisement
    display: none
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    @media (min-width: $tablet)
      height: 195px
      @include displayFlex(false, center, column)
      background: url('../../../../assets/images/design/add-icon.svg') no-repeat bottom left $green
      background-size: 90px 90px
      text-align: center
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
      .bottom-adver
        font-weight: bold

  .advert-images
    display: none
    @media (min-width: $desktop-md)
      display: block
    img
      max-width: 123px
      max-height: 44px
      height: 44px

  .adver-metrics-container
    text-align: center
    margin: 0 auto

  .wrap-advert-text
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    text-align: center
    width: 100%
    margin-bottom: 16px
    padding-left: 8px


  .search-agent
    background: url('../../../../assets/images/design/searchagent-create.svg') no-repeat bottom left $green
    background-size: 90px 90px
