// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.embla__controls
  position: absolute
  z-index: 1
  bottom: 16px
  width: 100%
  padding: 0 16px


.embla__button
  @include square(32px)
  border-radius: 4px
  background: rgba(0, 0, 0, 0.65)
  svg
    fill: $white

.embla-photos
  height: 235px

.embla__viewport,
.embla__container
  height: 100%
