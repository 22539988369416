@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.markers-list
  font-family: MuseoSans, Helvetica, Arial, sans-serif
  padding: 12px 16px 0
  position: relative
  width: 348px
  max-width: calc(100vw - 32px)
  background: $white
  border-radius: 16px
  box-shadow: 0 2px 7px 1px rgba(0,0,0,.3)

  &__close-btn
    position: absolute
    top: 16px
    right: 16px
    cursor: pointer

  &__top-wrapper
    margin-bottom: 12px

  &__address
    font-weight: 900
    font-size: 16px
    line-height: 24px
    margin-bottom: 12px
    color: $default

  &__header
    font-weight: 500
    font-size: 14px
    line-height: 21px
    color: #000000

  &__locations-list
    overflow-y: scroll !important
    padding-bottom: 24px
    max-height: 276px !important
    @media (min-width: 992px)
      max-height: 406px !important
    &::-webkit-scrollbar
      -webkit-appearance: none
    &::-webkit-scrollbar:vertical
      width: 10px
    &::-webkit-scrollbar:horizontal
      height: 10px
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      border: 2px solid white /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, .5)

  &__card
    margin-bottom: 8px
    padding: 8px
    width: 100%
    display: flex
    position: relative
    text-decoration: none
    border-radius: 4px
    background: #F6F6F6
    color: $default
    &:last-child
      margin-bottom: 0
    &:hover
      color: $default
      text-decoration: none
      .markers-list__card-read-more
        color: $green-hover
        svg
          fill: $green-hover

  &__card-img
    height: auto
    min-height: 90px
    width: 108px
    margin-right: 8px
    background-size: cover
    background-position: center
    border-radius: 4px

  &__card-info-wrapper
    height: 100%
    margin-bottom: 4px

  &__card-info-label
    font-weight: 100
    font-size: 14px
    line-height: 18px
    display: flex
    align-items: center
    svg
      fill: $default
      margin-right: 8px

  &__card-info-value
    font-weight: 500
    font-size: 14px
    line-height: 24px

  &__card-read-more
    font-weight: 500
    display: flex
    align-items: center
    text-decoration: underline
    color: $green
    font-size: 14px
    line-height: 18px
    cursor: pointer
    svg
      fill: $green
      margin-left: 4px
    &:hover
      color: $green-hover
      svg
        fill: $green-hover
